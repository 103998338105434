// ----Media query mixin-----------//
@mixin min-mq($min-screen-size) {
  @media (min-width: #{$min-screen-size +"px"}) {
    @content;
  }
}

@mixin max-mq($max-screen-size) {
  @media (max-width: #{$max-screen-size +"px"}) {
    @content;
  }
}

// ----------------------------For Orientation-----------------------//
@mixin landscape-orientation($max-screen-size, $orientation) {
  @media (max-width: #{$max-screen-size +"px"}) and (orientation: $orientation) {
    @content;
  }
}

@mixin btw-mq($min-screen-size, $max-screen-size, $pixel-ratio, $orientation) {
  @media only screen and (min-width: #{$min-screen-size +"px"}) and (max-width: #{$max-screen-size +"px"}) and (-webkit-min-device-pixel-ratio: $pixel-ratio) and (orientation: $orientation) {
    @content;
  }
}
@mixin btw-sz($min-screen-size, $max-screen-size) {
  @media only screen and (min-width: #{$min-screen-size +"px"}) and (max-width: #{$max-screen-size +"px"}) {
    @content;
  }
}
// .bred-crumb {
//     height: 150px;
//     background: #002733;
//     padding: 10px 0;
// }
// .bread-title {
//     margin-bottom: 10px;
//     font-family: Source Sans Pro;
//     font-style: normal;
//     font-weight: 600;
//     font-size: 36px;
//     line-height: 45px;
//     text-transform: capitalize;
//     color: #ffffff;
//     position: relative;
//     padding: 15px 0;
//     &::before {
//         content: "";
//         width: 50px;
//         bottom: 0;
//         left: 0;
//         height: 5px;
//         background-color: white;
//         position: absolute;
//     }
// }
// .bread-content {
//     .breads {
//         color: white;
//     }
// }
.right-box-22 {
  // background: #f8fbff;
  // box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  // border-radius: 22px;
  // margin: 20px 0px;
  margin-bottom: 20px;
  width: 100%;
  .title-2 {
    display: flex;
    justify-content: space-between;
    padding: 30px 0px;
    @include max-mq(768) {
      padding-bottom: 20px;
    }
    .search {
      width: 300px;
      height: 45px;
      background: #ffffff;
      // box-shadow: 0px 8px 15px rgba(208, 208, 208, 0.25);
      box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 25%);
      border-radius: 12px;
      font-size: 24px;
      line-height: 30px;
      color: rgba(3, 7, 30, 0.5);
      display: flex;
      justify-content: flex-start;
      align-items: center;

      @include max-mq(768) {
        width: 265px;
      }

      @include max-mq(470) {
        // margin-top: 20px;
        width: 100%;
      }
      .icon {
        margin: 10px;
        width: 25px !important;
      }
      input {
        border: none;
      }
      ::placeholder {
        font-size: 20px;
        line-height: 30px;
        color: rgba(3, 7, 30, 0.5);
      }
      input[type="text"] {
        border: none;
        outline: none;
        width: 100%;
        margin-right: 5px;
        font-size: 18px;
        line-height: 26px;
        color: rgba(3, 7, 30, 0.5);
      }
    }
  }

  .col-card {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    grid-auto-rows: auto !important;
    grid-gap: 20px;
    @include max-mq(768) {
      grid-gap: 15px;
    }
    // padding: 20px;
    @include btw-sz(200, 650) {
      grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    }
    .prod_card {
      position: relative;
      background: #ffffff;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
      border-radius: 7px;
      padding: 20px;
      height: 100%;
      @include max-mq(1300) {
        padding: 15px;
      }
      .img-box {
        text-align: center;
        height: 190px;
        width: inherit;
        @include max-mq(1300) {
          height: 160px;
        }
        @include btw-sz(200, 650) {
          height: 145px;
        }
        .prod-img {
          height: inherit;
          width: 100%;
          object-fit: cover;
          border-radius: 7px;
          object-position: top;
          cursor: pointer;
        }
      }
      .prod-details {
        margin-top: 10px;
        @include btw-sz(300, 550) {
          padding: 5px;
          padding-bottom: 10px 0 !important;
          margin-top: 0;
        }
        @include btw-sz(550, 900) {
          padding: 5px;
          padding-bottom: 10px 0 !important;
          margin-top: 0;
        }
        @include btw-sz(900, 1199) {
          padding: 15px 0;
          margin-top: 0;
        }
        @include btw-sz(1199, 1549) {
          padding: 15px 0 !important;
        }

        .prod_head {
          display: flex;
          justify-content: space-between;
          .title {
            font-weight: 600;
            font-size: 18px;
            line-height: 25px;
            color: #000000;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
          }
        }
      }
      .summery-det {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.02em;
        margin: 3px 0px;
        color: #777777;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .vendor-det {
        padding-top: 15px;
        font-weight: 600;
        font-size: 14px;
        line-height: 15px;
        letter-spacing: 0.02em;
        color: #777777;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        .p-date {
          font-size: 13px;
          font-weight: 500;
        }
      }
    }
  }
  .custom_grid {
    @include max-mq(650) {
      grid-template-columns: repeat(auto-fill, minmax(245px, 1fr)) !important;
    }
  }
}
