// ----Media query mixin-----------//
@mixin min-mq($min-screen-size) {
  @media (min-width: #{$min-screen-size +"px"}) {
    @content;
  }
}

@mixin max-mq($max-screen-size) {
  @media (max-width: #{$max-screen-size +"px"}) {
    @content;
  }
}

// ----------------------------For Orientation-----------------------//
@mixin landscape-orientation($max-screen-size, $orientation) {
  @media (max-width: #{$max-screen-size +"px"}) and (orientation: $orientation) {
    @content;
  }
}

@mixin btw-mq($min-screen-size, $max-screen-size, $pixel-ratio, $orientation) {
  @media only screen and (min-width: #{$min-screen-size +"px"}) and (max-width: #{$max-screen-size +"px"}) and (-webkit-min-device-pixel-ratio: $pixel-ratio) and (orientation: $orientation) {
    @content;
  }
}
@mixin btw-sz($min-screen-size, $max-screen-size) {
  @media only screen and (min-width: #{$min-screen-size +"px"}) and (max-width: #{$max-screen-size +"px"}) {
    @content;
  }
}

.faq-right-box {
  .title {
    display: flex;
    justify-content: space-between;
    @include max-mq(470) {
      display: block;
    }
    @include max-mq(768) {
      padding-top: 20px;
      padding-bottom: 0px;
    }
    .first {
      .r-b-title {
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
        text-transform: capitalize;
        color: #002733;
        @include max-mq(768) {
          font-size: 20px;
        line-height: 20px;
        }
      }
      .hr1 {
        width: 85px;
        height: 0px;
        opacity: 1;
        margin: 5px;
        margin-left: 0px;
        border-radius: 5px;
        border: 2px solid #0466c8;
      }
    }
    .search {
      width: 300px;
      height: 40px;
      background: #ffffff;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 12px;
      font-size: 24px;
      line-height: 30px;
      color: rgba(3, 7, 30, 0.5);
      display: flex;
      justify-content: flex-start;
      align-items: center;

      @include max-mq(768) {
        width: 200px;
      }

      @include max-mq(470) {
        margin-top: 20px;
        width: 100%;
      }
      .icon {
        margin: 10px;
        width: 25px !important;
      }
      input {
        border: none;
      }
      ::placeholder {
        font-size: 20px;
        line-height: 30px;
        color: rgba(3, 7, 30, 0.5);
      }
      input[type="text"] {
        border: none;
        outline: none;
        width: 100%;
        margin-right: 5px;
        font-size: 24px;
        line-height: 30px;
        color: rgba(3, 7, 30, 0.5);
        @include max-mq(768) {
          font-size: 18px;
        }
      }
    }
  }
}

// ----------------     Accodian     ---------------------------

.know-base-acc {
  margin-top: 25px;
  @include max-mq(768){
    margin-top: 20px;
  }
  .accordion-item {
    border: none;
    margin-bottom: 10px;
    border-radius: 12px;
    background-color: transparent;
    // border: 2px solid red;
  }

  .accordion-button {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.02em;
    color: #2c2d32;
    box-shadow: none;
    border: none;
    padding: 15px !important;
    // border-bottom: 1px solid rgba(44, 45, 50, 0.2);
    // border-radius: 12px;
    @include max-mq(1300) {
      font-size: 18px;
      line-height: 22px;
    }
  }
  .accordion-body {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #777777;
    background-color: #fff;
    // border-radius: 8px;
    padding-top: 22px;
    // box-shadow: 0px 8px 15px rgb(208 208 208 / 20%);
    @include max-mq(1300) {
      padding: 14px !important;
    }
  }
  .accordion-button:not(.collapsed) {
    // border-bottom: 1px solid rgba(44, 45, 50, 0.2);
    color: #002733;
    background-color: #fff;
    // border-radius: 12px;
  }

  .accordion-button:focus {
    z-index: 3;
    border-color: none;
    outline: 0;
    box-shadow: none;
  }

  .accordion-button:not(.collapsed)::after {
    background-image: url("../../../../assets/svg/arroww2.svg");
    transform: none;
    position: relative;
    top: 0px;
  }
  .accordion-item {
    overflow: hidden;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 27%);
    &:hover {
      box-shadow: rgb(0 0 0 / 20%) 0px 12px 28px 0px,
        rgb(0 0 0 / 10%) 0px 2px 4px 0px,
        rgb(255 255 255 / 5%) 0px 0px 0px 1px inset;
    }
  }

  .accordion-button::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url("../../../../assets/svg/arroww.svg");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform 0.2s ease-in-out;
    @include max-mq(1300) {
      background-size: 15px;
    }
  }

  button.accordion-button.collapsed {
    border-color: rgba(44, 45, 50, 0.2) !important;
    border-radius: 12px;
  }
}

// -------------------------------------------------------

@media screen and (max-width: 900px) {
  .know-base-acc {
    .accordion-button {
      font-size: 14px !important;
      line-height: 22px;
      padding-bottom: 10px !important;
    }
    .accordion-body {
      font-size: 14px;
      line-height: 22px;
      padding-top: 10px;
    }
  }
}
