// ----Media query mixin-----------//
@mixin min-mq($min-screen-size) {
  @media (min-width: #{$min-screen-size +"px"}) {
    @content;
  }
}

@mixin max-mq($max-screen-size) {
  @media (max-width: #{$max-screen-size +"px"}) {
    @content;
  }
}

// ----------------------------For Orientation-----------------------//
@mixin landscape-orientation($max-screen-size, $orientation) {
  @media (max-width: #{$max-screen-size +"px"}) and (orientation: $orientation) {
    @content;
  }
}

@mixin btw-mq($min-screen-size, $max-screen-size, $pixel-ratio, $orientation) {
  @media only screen and (min-width: #{$min-screen-size +"px"}) and (max-width: #{$max-screen-size +"px"}) and (-webkit-min-device-pixel-ratio: $pixel-ratio) and (orientation: $orientation) {
    @content;
  }
}
@mixin btw-sz($min-screen-size, $max-screen-size) {
  @media only screen and (min-width: #{$min-screen-size +"px"}) and (max-width: #{$max-screen-size +"px"}) {
    @content;
  }
}

.custom_alert_kyc {
  // background-color: #fff !important;
  box-shadow: 0 0 20px rgb(8 21 66 / 5%);
  // color: #1a295b !important;
  // color: #1a295b !important;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  @include max-mq(600) {
    font-size: 15px;
  }
  @include max-mq(400) {
    font-size: 12px;
  }
  .alert_txt {
    margin-right: 60px;
    // margin-right: 110px;
    width: 100%;
    @include max-mq(400) {
      margin-right: 40px;
    }
  }
  img {
    margin-right: 10px;
    height: 22px;
  }
  .btn-close {
    padding: 15px !important;
  }
  span {
    position: absolute;
    right: 10px;
    color: #1a295b;
  }
}
.userProfile-right-box {
  .title {
    display: flex;
    justify-content: space-between;
    @include max-mq(470) {
      display: block;
    }
    @include max-mq(768) {
      padding-top: 20px;
      padding-bottom: 0px;
    }
    .first {
      .r-b-title {
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
        text-transform: capitalize;
        color: #002733;
        @include max-mq(768) {
          font-size: 20px;
          line-height: 20px;
        }
      }
      .hr1 {
        width: 46px;
        height: 0px;
        opacity: 1;
        margin: 5px;
        margin-left: 0px;
        border-radius: 5px;
        border: 2px solid #0466c8;
      }
    }
    .search {
      width: 300px;
      height: 40px;
      background: #ffffff;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 12px;
      font-size: 24px;
      line-height: 30px;
      color: rgba(3, 7, 30, 0.5);
      display: flex;
      justify-content: flex-start;
      align-items: center;

      @include max-mq(768) {
        width: 200px;
      }

      @include max-mq(470) {
        margin-top: 20px;
        width: 100%;
      }
      .icon {
        margin: 10px;
        width: 25px !important;
      }
      input {
        border: none;
      }
      ::placeholder {
        font-size: 20px;
        line-height: 30px;
        color: rgba(3, 7, 30, 0.5);
      }
      input[type="text"] {
        border: none;
        outline: none;
        width: 100%;
        margin-right: 5px;
        font-size: 24px;
        line-height: 30px;
        color: rgba(3, 7, 30, 0.5);
        @include max-mq(768) {
          font-size: 18px;
        }
      }
    }
  }

  .profile-form {
    margin-top: 80px;
    .pro-img {
      z-index: 1;
      position: absolute;
      left: 50%;
      top: -70px;
      transform: translateX(-50%);
      display: grid;
      place-items: center;
      width: 100%;

      @include max-mq(768) {
        position: absolute;
        top: -55px;
      }
      img {
        border: 7px solid #fff;
        border-radius: 50%;
        height: 125px;
        width: 125px;
        object-fit: cover;
        object-position: top;
        // margin-left: 20px;
        @include max-mq(768) {
          height: 100px;
          width: 100px;
        }
      }
      .pro-name {
        color: #242934;
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 1px;

        @include max-mq(768) {
          font-size: 18px;
        }
      }
    }
    .pro-card {
      position: relative;
      .card {
        border: none;
        transition: all 0.3s ease;
        letter-spacing: 0.5px;
        border-radius: 15px;
        box-shadow: 0 0 20px #0815420d;
        .pro-info {
          display: flex;
          justify-content: space-between;
          margin-top: 100px;
          padding: 20px;
          font-size: 16px;
          color: #59667a;
          font-weight: 600;
          line-height: 1.2;
          @include max-mq(768) {
            display: block;
            margin: auto;
            margin-top: 100px;
            padding: 10px;
          }
          .inner-pro {
            width: 250px;
            text-align: center;

            @include max-mq(768) {
              margin-bottom: 15px;
            }
            .pro-first {
              text-align: left;

              @include max-mq(768) {
                text-align: center;
                margin-bottom: 5px;
              }
            }
            p {
              margin-bottom: 0.5rem;

              @include max-mq(768) {
                margin-bottom: 5px;
              }
            }
          }
        }
        .pro-edit {
          width: 115px;
          margin: auto;
          margin-bottom: 20px;
          height: 38px;
          background: #0466c8;
          border-radius: 6px;
          button {
            color: #fff;
            outline: none;
            border: none;
            background: transparent;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

.userProfileImg {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  margin-top: 15px;
  @include max-mq(768) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .imageView-2 {
    position: relative;
    label {
      display: block;
      margin-bottom: 10px;
    }
    img {
      object-fit: cover;
      height: 250px;
      width: 100%;
      border-radius: 8px;
      margin-bottom: 10px;
      @include max-mq(550) {
        height: 200px;
      }
    }
    input {
      height: 250px;
      width: 100%;
      position: absolute;
      border: 1px solid red;
      left: 0;
      opacity: 0;
    }
  }
}

.uniqueNumbers{
  word-wrap: break-word;
  letter-spacing: 0.5px;
  font-size: 14px;
  color: #59667a;
  font-weight: 600;
  line-height: 1.2;
  text-align: center;
  margin-bottom: 5px;
}