// ----Media query mixin-----------//
@mixin min-mq($min-screen-size) {
  @media (min-width: #{$min-screen-size +"px"}) {
    @content;
  }
}

@mixin max-mq($max-screen-size) {
  @media (max-width: #{$max-screen-size +"px"}) {
    @content;
  }
}

// ----------------------------For Orientation-----------------------//
@mixin landscape-orientation($max-screen-size, $orientation) {
  @media (max-width: #{$max-screen-size +"px"}) and (orientation: $orientation) {
    @content;
  }
}

@mixin btw-mq($min-screen-size, $max-screen-size, $pixel-ratio, $orientation) {
  @media only screen and (min-width: #{$min-screen-size +"px"}) and (max-width: #{$max-screen-size +"px"}) and (-webkit-min-device-pixel-ratio: $pixel-ratio) and (orientation: $orientation) {
    @content;
  }
}
@mixin btw-sz($min-screen-size, $max-screen-size) {
  @media only screen and (min-width: #{$min-screen-size +"px"}) and (max-width: #{$max-screen-size +"px"}) {
    @content;
  }
}

.custom-container {
  border-radius: 38px 0px 0px 38px;
  height: calc(100vh - 95px);
  overflow: auto;
  background-color: #f9f9f9;
  border: 1px solid #ececec;
  box-sizing: border-box;
  @include max-mq(1100) {
    border-radius: 38px 38px 0px 0px;
  }
  @include max-mq(1549) {
    padding: 40px 80px;
  }
  @include max-mq(550) {
    padding: 15px;
  }
  .test {
    display: flex;
    justify-content: space-between;
    // gap: 40px;
    gap: 30px;
    // flex-wrap: wrap;
    @include btw-sz(200, 1350) {
        flex-wrap: wrap;
    }
    .homeleft {
      width: 70%;
      @include max-mq(1350) {
        width: 100%;
      }
      .homeGreeting {
        margin-bottom: 20px;
        .gm {
          font-weight: 400;
          font-size: 14px;
          line-height: 23px;
          text-transform: capitalize;
          color: #adb5bd;
        }
        .greeting {
          font-weight: 600;
          font-size: 27px;
          line-height: 45px;
          text-transform: capitalize;
          color: #002733;
          @include max-mq(1300) {
            font-size: 27px;
            line-height: 30px;
          }
          @include max-mq(768) {
            font-size: 22px;
          }
        }
        .greeting-2 {
          font-weight: 600;
          font-size: 24px;
          line-height: 30px;
        }
      }
      .hometop {
        display: flex;
        align-items: center;
        gap: 30px;
        @include max-mq(825) {
          display: inline-block;
          width: 100%;
        }
        // justify-content: space-between;

        .poRight {
          width: 250px;
          min-width: 250px;
          height: 210px;
          background: #0466c8;
          box-shadow: 0px 8px 35px rgba(4, 102, 200, 0.25);
          border-radius: 31px;
          overflow: hidden;
          position: relative;
          @include max-mq(1300) {
            width: 250px;
            height: 210px;
          }
          // @include btw-sz(1385, 1450) {
          //     width: 300px;
          // }
          // @include btw-sz(200, 1385) {
          //     width: 333px;
          // }
          @include max-mq(825) {
            margin-bottom: 20px;
            min-width: 400px;
            height: 250px;
          }
          @include max-mq(550) {
            margin-bottom: 20px;
            min-width: 100%;
            width: 100%;
            height: 210px;
          }
          &::before {
            content: "";
            width: 336px;
            height: 277px;
            background: #000000;
            opacity: 0.1;
            position: absolute;
            left: -145px;
            top: 125px;
            border-radius: 50%;
          }
          &::after {
            content: "";
            width: 336px;
            height: 277px;
            background: #000000;
            opacity: 0.1;
            position: absolute;
            left: 152px;
            top: -160px;
            border-radius: 50%;
          }
          .poPrice {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
            .poLeftCard {
              width: 45px;
              height: 45px;
              background: #ffffff;
              border-radius: 9px;
              display: flex;
              justify-content: center;
              align-items: center;
              position: absolute;
              top: 20px;
              left: 20px;
              @include max-mq(1300) {
                position: absolute;
                top: 15px;
                left: 15px;
              }
            }
            .homeRightCard {
              background: #0466c8;
              filter: drop-shadow(0px 6px 13px rgba(0, 0, 0, 0.25));
            }
            .poRightCard {
              width: 45px;
              height: 45px;
              background: #ffffff;
              border-radius: 9px;
              display: flex;
              justify-content: center;
              align-items: center;
              position: absolute;
              bottom: 15px;
              right: 20px;
            }
            .poName {
              font-weight: normal;
              font-size: 20px;
              line-height: 150%;
              text-transform: capitalize;
              color: #ffffff;
            }
            .poprice {
              font-weight: 600;
              font-size: 24px;
              line-height: 30px;
              text-transform: capitalize;
              color: #ffffff;
            }
            .homeDisc {
              color: #002733;
            }
          }
        }
        .homeRight {
          background: #f8fbff;
          box-shadow: 0px 0px 4px rgba(0, 120, 239, 0.15),
            0px 15px 35px rgba(4, 102, 200, 0.08);
          &::after {
            background: rgba(0, 120, 239, 0.91);
            position: absolute;
            left: 95px;
            top: -140px;
          }
          &::before {
            content: none;
          }
        }
      }
      .left-box {
        margin-top: 30px;
        padding: 30px;
        width: 100%;
        // max-width: 850px;
        // height: 400px;
        background: #ffffff;
        box-shadow: 0px 8px 15px rgba(208, 208, 208, 0.25);
        border-radius: 31px;
        @include max-mq(768){
          padding: 15px;
        }
      }
    }
    .homeright {
      // width: 30%;
      // min-width: 345px;
      // max-width: 359px;

      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(279px, 1fr));
      margin-top: 74px;
      gap: 15px;
      width: 30%;
      min-width: 300px;
      @include max-mq(1350) {
        // margin-top: 45px;
        width: 100%;
        margin-top: 5px;
      }
      // @include max-mq(550) {
      //   margin-top: 20px;
      // }
      // @include btw-sz(768, 1385) {
      //   gap: 15px;
      // }
      // @include max-mq(550){
      //     display: grid;
      //     grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      // }
      .hrCard {
        background: #ffffff;
        box-shadow: 0px 8px 15px rgba(0, 120, 239, 0.25);
        border-radius: 31px;
        position: relative;
        margin-bottom: 25px;
        // min-width: 335px;
        // max-width: 359px;
        @include max-mq(1300) {
          margin-bottom: 0px;
        }
        .hrProfile {
          display: flex;
          padding: 20px;
          gap: 15px;
          @include max-mq(550){
            gap: 10px;
          }
          img {
            height: 45px;
            width: 45px;
            min-width: 45px;
            object-fit: cover;
            border-radius: 50%;
            @include max-mq(1300) {
              height: 45px;
              width: 45px;
            }
            @include max-mq(550) {
              height: 35px;
              width: 35px;
              min-width: 35px;
            }
          }
          .hrInfo {
            width: 100%;
            .hrName {
              font-weight: 400;
              font-size: 20px;
              text-transform: capitalize;
              color: #002733;
              height: 50px;
              line-height: 40px;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
              @include max-mq(550) {
                height: 36px;
                font-size: 18px;
                line-height: 30px;
              }
            }
            .hrEmail {
              display: flex;
              align-items: center;
              margin-bottom: 20px;
              gap: 10px;
              font-weight: 400;
              font-size: 16px;
              line-height: 23px;
              text-transform: lowercase;
              color: #002733;
              @include max-mq(550) {
                font-size: 14px;
                margin-bottom: 15px;
              }
              img {
                width: 23px !important;
                max-width: 23px !important;
                min-width: 23px !important;
                height: 100%;
                border-radius: 0px;
              }
              div {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 210px;
              }
            }
            .hrPhone {
              img {
                // width: 14px;
                width: 23px;
                height: 23px;
                object-fit: contain;
                border-radius: 0px;
              }
            }
          }
        }
        .homeWhatsApp {
          cursor: pointer;
          position: absolute;
          bottom: 15px;
          right: 20px;
        }
      }
      .dudhat {
        .hrCard2 {
          background: #ffffff;
          box-shadow: 0px 8px 15px rgba(0, 120, 239, 0.25);
          border-radius: 31px;
          position: relative;
          padding: 20px;
          // margin-bottom: 40px;
          // min-width: 335px;
          // max-width: 359px;
          .hrCard2Title {
            font-weight: 600;
            font-size: 18px;
            line-height: 23px;
            text-transform: capitalize;
            color: #002733;
            margin-bottom: 25px;
            display: -webkit-box;
            /* max-width: 200px; */
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;

            @include max-mq(550) {
              margin-bottom: 15px;
            }
          }
          .card2imgView {
            display: flex;
            align-items: center;
            .avatar-stack__item {
              .avatar {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                color: white;
                font-weight: bold;
                font-size: 24px;
                background-color: #fff;
                @include max-mq(1300) {
                  height: 45px;
                  width: 45px;
                }
                @include max-mq(550) {
                  height: 35px;
                  width: 35px;
                }
                img {
                  width: 50px;
                  height: 50px;
                  border-radius: 50%;
                  object-fit: cover;
                  @include max-mq(1300) {
                    height: 40px;
                    width: 40px;
                  }
                  @include max-mq(550) {
                    height: 35px;
                    width: 35px;
                  }
                }
              }
              &:not(:first-child) {
                margin-left: -18px;
                // background-color: #f8fbff;
                clip-path: url(#avatar-stack-clip-path);
                @include max-mq(550) {
                  margin-left: -12px;
                }
              }
            }
          }

          .hrCard2price {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 5px;
            @include max-mq(1300) {
              margin-top: -5px;
            }
            @include max-mq(550) {
              margin-top: 0px;
            }
            .card2price {
              font-weight: 600;
              font-size: 24px;
              line-height: 30px;
              text-transform: capitalize;
              color: #002733;
              width: 65%;
              margin-left: 10px;
              @include max-mq(1300) {
                font-size: 20px;
              }
              @include max-mq(550) {
                font-size: 18px;
                width: 60%;
              }
            }
            .card2btn {
              width: 38%;
              // padding: 10px;
              height: 38px;
              display: flex;
              justify-content: center;
              align-items: center;
              background: #0466c8;
              border-radius: 6px;
              font-weight: 400;
              font-size: 16px;
              line-height: 23px;
              text-transform: capitalize;
              color: #ffffff;
              max-width: 110px;
              @include max-mq(1300) {
                font-size: 16px;
                line-height: 22px;
                height: 35px;
              }
              @include max-mq(550) {
                height: 30px;
                width: 40%;
                font-size: 14px;
              }
            }
          }
        }
        .hrCard2_1 {
          background: #ffffff;
          box-shadow: 0px 8px 15px rgb(0 120 239 / 25%);
          border-radius: 31px;
          padding: 20px;
          margin: 10px auto;
          margin-top: -25px;
          margin-bottom: 25px;
          width: 80%;
          @include max-mq(1350){
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .container {
    max-width: 100%;
  }
}
// @media (max-width: 600px) {
//     .hometop {
//         display: block !important;
//     }
// }

.ChartClass {
  width: 100%;
  @include min-mq(1650) {
    width: 70%;
  }
}


canvas{
  @include max-mq(630){
    height: 425px !important;
  }
}