// ----Media query mixin-----------//
@mixin min-mq($min-screen-size) {
  @media (min-width: #{$min-screen-size +"px"}) {
    @content;
  }
}

@mixin max-mq($max-screen-size) {
  @media (max-width: #{$max-screen-size +"px"}) {
    @content;
  }
}

// ----------------------------For Orientation-----------------------//
@mixin landscape-orientation($max-screen-size, $orientation) {
  @media (max-width: #{$max-screen-size +"px"}) and (orientation: $orientation) {
    @content;
  }
}

@mixin btw-mq($min-screen-size, $max-screen-size, $pixel-ratio, $orientation) {
  @media only screen and (min-width: #{$min-screen-size +"px"}) and (max-width: #{$max-screen-size +"px"}) and (-webkit-min-device-pixel-ratio: $pixel-ratio) and (orientation: $orientation) {
    @content;
  }
}
@mixin btw-sz($min-screen-size, $max-screen-size) {
  @media only screen and (min-width: #{$min-screen-size +"px"}) and (max-width: #{$max-screen-size +"px"}) {
    @content;
  }
}

.WatchList-right-box {
  .title {
    display: flex;
    justify-content: space-between;
    //   padding: 20px;
    @include max-mq(768) {
      padding-top: 20px;
      padding-bottom: 0px;
    }
    .slide-filter button {
      width: 75px;
      height: 38px;
      background: #0466c8;
      border-radius: 6px;
      color: #fff;
      outline: none;
      border: none;
    }
    .first {
      .r-b-title {
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
        text-transform: capitalize;
        color: #002733;
        @include max-mq(768) {
          font-size: 20px;
          line-height: 20px;
        }
      }
      .hr1 {
        width: 46px;
        height: 0px;
        opacity: 1;
        margin: 5px;
        margin-left: 0px;
        border-radius: 5px;
        border: 2px solid #0466c8;
      }
    }
  }
  .title-2 {
    @include max-mq(470) {
      display: block;
    }
  }
  .fixHeight {
    height: 233px;
    overflow: hidden;
    overflow-x: auto;
    grid-auto-flow: column;
  }
  .copy_paste {
    display: grid;
    // grid-template-columns: repeat(auto-fill, minmax(249px, 1fr));
    grid-template-columns: repeat(auto-fill, minmax(194px, 1fr));
    // grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    grid-gap: 25px;
    padding: 20px 0px 50px;
    @include max-mq(1100) {
      padding-bottom: 30px;
      gap: 15px;
    }
    @include max-mq(750) {
      // grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
      gap: 10px;
    }
    @include max-mq(550) {
      // grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .card-view {
      background: #ffffff;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
      border-radius: 11px;
      padding: 20px;
      width: 100%;
      &:hover {
        box-shadow: rgb(0 0 0 / 20%) 0px 12px 28px 0px, rgb(0 0 0 / 10%) 0px 2px 4px 0px,
          rgb(255 255 255 / 5%) 0px 0px 0px 1px inset;
      }
      @include max-mq(1300) {
        padding: 15px;
      }
      cursor: pointer;
      .labal_change {
        display: flex;
        justify-content: right;
        margin-bottom: 8px;
        @include max-mq(1300) {
          margin-bottom: 5px;
        }
        .card-labal {
          width: 70px;
          height: 18px;
          border-radius: 19px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 10px;
          line-height: 15px;
          text-transform: capitalize;
        }
        .card-labal-1 {
          background: rgba(0, 128, 0, 0.1);
          color: #008000;
        }
        .card-labal-2 {
          background: rgba(208, 0, 0, 0.1);
          color: #d00000;
        }
        .card-labal-3 {
          background: rgba(255, 189, 0, 0.1);
          color: #b78700;
        }
      }
      img.card_img {
        margin-bottom: 10px;
        @include max-mq(1300) {
          margin-bottom: 10px;
        }
      }
      .card_img_content_view {
        text-align: center;
      }
      .card_img {
        // width: 136px;
        // height: 30px;
        // object-fit: cover;
        object-fit: contain;
        height: 100px;
        width: 100%;
        @include max-mq(1300) {
          height: 80px;
        }
        @include max-mq(550) {
          height: 70px;
        }
      }
      .card_details {
        display: flex;
        // justify-content: space-between;
        .card_price {
          font-size: 14px;
          line-height: 23px;
          text-transform: capitalize;
          color: #b1b3c2;
          width: 48px;
          position: relative;
          padding-bottom: 5px;
          // margin-bottom: 5px;
          @include max-mq(550) {
            font-size: 12px;
            line-height: 22px;
            padding-bottom: 0px;
          }
          &:before {
            position: absolute;
            content: "";
            height: 1px;
            width: 35px;
            background-color: #b1b3c2;
            bottom: 0;
            left: 0;
          }
          hr.class_hr {
            width: 20px;
            margin: 5px;
            margin-left: 0px;
          }
        }
        .pprice {
          font-size: 18px;
          line-height: 28px;
          @include max-mq(1300) {
            font-size: 18px;
            line-height: 28px;
          }
          @include max-mq(550) {
            font-size: 16px;
            line-height: 24px;
          }
          text-transform: capitalize;
          color: #002733;
          // white-space: nowrap;
          // overflow: hidden;
          margin-left: 5px;
          // text-overflow: ellipsis;
        }
        .pprice_gd {
          margin-left: 0;
        }
      }
      .card_details_gd {
        display: block !important;
      }
    }
    .card-view-2 {
      border-radius: 50%;
      height: 180px;
      width: 180px;
      display: flex;
      align-items: center;
      img {
        margin-bottom: 0px !important;
        margin-top: 0px;
      }
    }
  }
  // .copy_paste-2 {
  //     @include max-mq(768) {
  //         display: grid;
  //         grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  //         grid-gap: 15px;
  //         padding: 0px;
  //         padding-top: 10px;
  //     }
  // }
}
