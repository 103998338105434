// ----Media query mixin-----------//
@mixin min-mq($min-screen-size) {
  @media (min-width: #{$min-screen-size +"px"}) {
    @content;
  }
}

@mixin max-mq($max-screen-size) {
  @media (max-width: #{$max-screen-size +"px"}) {
    @content;
  }
}

// ----------------------------For Orientation-----------------------//
@mixin landscape-orientation($max-screen-size, $orientation) {
  @media (max-width: #{$max-screen-size +"px"}) and (orientation: $orientation) {
    @content;
  }
}

@mixin btw-mq($min-screen-size, $max-screen-size, $pixel-ratio, $orientation) {
  @media only screen and (min-width: #{$min-screen-size +"px"}) and (max-width: #{$max-screen-size +"px"}) and (-webkit-min-device-pixel-ratio: $pixel-ratio) and (orientation: $orientation) {
    @content;
  }
}
@mixin btw-sz($min-screen-size, $max-screen-size) {
  @media only screen and (min-width: #{$min-screen-size +"px"}) and (max-width: #{$max-screen-size +"px"}) {
    @content;
  }
}

.checkout-right-box {
  .title {
    display: flex;
    justify-content: space-between;
    @include max-mq(470) {
      display: block;
    }
    @include max-mq(768) {
      padding-bottom: 0px;
      padding-top: 20px;
    }
    .first {
      .r-b-title {
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
        text-transform: capitalize;
        color: #002733;
        @include max-mq(768) {
          font-size: 20px;
          line-height: 20px;
        }
      }
      .hr1 {
        width: 46px;
        height: 0px;
        opacity: 1;
        margin: 5px;
        margin-left: 0px;
        border-radius: 5px;
        border: 2px solid #0466c8;
      }
    }
    .search {
      width: 300px;
      height: 40px;
      background: #ffffff;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 12px;
      font-size: 24px;
      line-height: 30px;
      color: rgba(3, 7, 30, 0.5);
      display: flex;
      justify-content: flex-start;
      align-items: center;

      @include max-mq(768) {
        width: 200px;
      }

      @include max-mq(470) {
        margin-top: 20px;
        width: 100%;
      }
      .icon {
        margin: 10px;
        width: 25px !important;
      }
      input {
        border: none;
      }
      ::placeholder {
        font-size: 20px;
        line-height: 30px;
        color: rgba(3, 7, 30, 0.5);
      }
      input[type="text"] {
        border: none;
        outline: none;
        width: 100%;
        margin-right: 5px;
        font-size: 24px;
        line-height: 30px;
        color: rgba(3, 7, 30, 0.5);
        @include max-mq(768) {
          font-size: 18px;
        }
      }
    }
  }
  .myCustomCardd {
    position: relative;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 8px 15px rgba(208, 208, 208, 0.25);
    border-radius: 13px;
    margin-top: 15px;
    padding: 15px;
    padding-top: 20px;
    .tb_top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include max-mq(768) {
        display: block;
      }
      .tbt_img {
        display: flex;
        align-items: center;
        gap: 15px;
        height: 100%;
        @include max-mq(768) {
          gap: 15px;
        }
        img {
          // height: 75px;
          // width: 75px;
          height: 90px;
          width: 150px;
          object-fit: contain;
          border-radius: 8px;
          @include max-mq(768) {
            height: 55px;
            width: 55px;
          }
        }
        .tbt_name {
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          text-transform: capitalize;
          color: #002733;
          @include max-mq(768) {
            font-size: 20px;
            line-height: 24px;
          }
        }
      }
      .tbt_count {
        display: flex;
        gap: 70px;
        // width: 50%;
        // @include max-mq(768) {
        //     width: auto;
        // }
        .pps {
          // width: 100%;
          width: 280px;
          margin-right: 35px;
          @include max-mq(768) {
            margin-right: 0px;
            width: 100%;
          }
          .pps_name {
            @include max-mq(768) {
              .cisin {
                text-align: left !important;
              }
              // display: flex;
              // justify-content: space-between;
              // align-items: center;
              // margin-top: 10px;
            }
            .cisin {
              font-weight: 600;
              font-size: 18px;
              line-height: 30px;
              text-transform: capitalize;
              color: #002733;
              @include max-mq(550) {
                font-size: 16px;
              }
              span {
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
                @include max-mq(768) {
                  text-align: end;
                  font-size: 14px;
                  line-height: 18px;
                }
              }
              @include max-mq(768) {
                text-align: end;
                font-size: 16px;
                line-height: 18px;
              }
            }
            @include max-mq(768) {
              font-size: 16px;
              line-height: 20px;
            }
          }
          .pps_price {
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            text-transform: capitalize;
            color: #002733;

            @include max-mq(450) {
              font-weight: 600;
              font-size: 18px;
              line-height: 22px;
            }
          }
        }
      }
    }
    .checkout_field {
      @include max-mq(768) {
        margin-top: 10px;
      }
      .cfeild {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        @include max-mq(768) {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          margin-top: 0px;
        }
        .cfAmount {
          text-align: start;
          @include max-mq(768) {
            text-align: end;
          }
        }
        // .testAlign {
        //     @include max-mq(768) {
        //         text-align: left;
        //     }
        // }
        .cfDuty {
          text-align: center;
          @include max-mq(768) {
            text-align: right;
          }
        }
        .cfRate {
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
          text-transform: capitalize;
          color: #002733;
          // width: 100%;
          width: 315px;
          @include max-mq(768) {
            width: auto;
            font-size: 14px;
            line-height: 18px;
          }
          span {
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            @include max-mq(768) {
              // width: auto;
              font-size: 14px;
              line-height: 22px;
            }
          }

          input {
            margin-left: 10px;
            border: 1px solid #0466c8;
            border-radius: 5px;
            outline: none;
            font-weight: 400;
            font-size: 16px;
            line-height: 21px;
            color: #99a6b9;
            background: transparent;
            width: 105px;
            @include max-mq(550) {
              margin-left: 0px;
              width: 95px;
            }
            @include max-mq(450) {
              width: 65px;
            }
          }
          input::-webkit-input-placeholder {
            font-weight: 400;
            font-size: 14px;
            padding: 5px;
            @include max-mq(550) {
              padding: 0px;
            }
          }
        }
      }
    }
    .crossBtn {
      position: absolute;
      right: 15px;
      top: 10px;
      cursor: pointer;
      z-index: 1;
      img {
        height: 15px;
        width: 15px;
      }
    }
  }
}

.card3btn {
  width: 200px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0466c8;
  border-radius: 6px;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-transform: capitalize;
  color: #ffffff;
  margin: 20px 0px;
  margin-left: auto;
  outline: none;
  border: none;
}
.card3btn-disabled {
  opacity: 0.5;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.total-error {
  color: red;
  float: right;
  margin-top: 3px;
}
.media_checkout {
  @include max-mq(1400) {
    display: flex !important;
    flex-direction: column !important;
  }
}
.checkout_box_view {
  display: grid;
  grid-template-columns: calc(100% - 250px) 250px;
  gap: 15px;
  .checkout_card {
    top: 0;
    position: sticky;
    .form-check-input:checked {
      // border-color: #002733;
      // background-color: #002733;
      border-color: #0466c8;
      background-color: #0466c8;
    }
    .pay_box {
      background-color: #002733;
      border-radius: 11px;
      padding: 15px;
      margin-top: 15px;
      box-shadow: 0px 8px 15px rgb(208 208 208 / 25%);
      @include max-mq(650) {
        margin-top: 5px;
      }
      // box-shadow: 0px 5px rgb(0 0 0 / 27%);
      &:hover {
        box-shadow: rgb(0 0 0 / 20%) 0px 12px 28px 0px,
          rgb(0 0 0 / 10%) 0px 2px 4px 0px,
          rgb(255 255 255 / 5%) 0px 0px 0px 1px inset;
      }
      .title_check {
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        color: white;
      }
      .payment_opt {
        border-top: 1px solid #bcbcbc;
        .payment_grid {
          margin-top: 10px;
          display: flex;
          align-items: center;
          gap: 5px;
          .payment_meth {
            margin: 5px 0;
            font-size: 16px;
            font-weight: 600;
            color: white;
          }
        }
      }
      .total__count_box {
        margin-top: 5px;
        padding: 5px 0;
        border-top: 1px solid #bcbcbc;
        .view_box_count {
          .total_grid {
            display: grid;
            grid-template-columns: 50% 50%;
            // gap: 15px;
            margin-top: 15px;
            .txt_total {
              color: #bcbcbc;
              font-weight: 600;
              font-size: 16px;
            }
            .price_total {
              font-size: 16px;
              font-weight: 600;
              color: white;
            }
          }
          .net_total {
            margin-top: 15px;
            // margin-top: 5px;

            padding-top: 10px;
            border-top: 1px solid #bcbcbc;
          }
        }
      }
      .terms_grid {
        margin: 5px 0;
        display: grid;
        // justify-content: center;
        align-items: center;
        gap: 5px;
        grid-template-columns: 15px auto;
        .terms_link_click {
          color: white;
          cursor: pointer;
          .link_terms {
            cursor: pointer !important;
            color: orangered;
          }
        }
      }
      .cehck_out_btn {
        display: flex;
        justify-content: center;
        align-items: center;
        .btn_checkout {
          margin: 5px 0;
          border-radius: 11px;
          padding: 7px 15px;
          width: 100%;
          max-width: 200px;
          outline: none;
          border: none;
          background-color: #0466c8;
          color: white;
          &:disabled {
            background-color: #7fb0e1;
          }
        }
      }
    }
  }
  .checkout-no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 236px);
    img {
      height: 250px;
    }
  }
}

.desktop_checkout {
  @include max-mq(600) {
    display: none !important;
  }
}
.click_terms {
  cursor: pointer !important;
}
.scroll_cards_checkout {
  .card_view_by_comp {
    position: relative;

    background: white;
    margin-top: 30px;
    border-radius: 13px;
    padding: 15px;
    box-shadow: 0px 8px 15px rgb(208 208 208 / 25%);
    @include max-mq(650) {
      margin-top: 20px;
    }
    &:hover {
      box-shadow: rgb(0 0 0 / 20%) 0px 12px 28px 0px,
        rgb(0 0 0 / 10%) 0px 2px 4px 0px,
        rgb(255 255 255 / 5%) 0px 0px 0px 1px inset;
    }

    .grid-view {
      .header_row {
        color: #bcbcbc;
      }

      .row_grid_card {
        font-weight: 600;
        // display: grid;
        margin: 15px 0;
        display: flex;
        justify-content: space-between;
        // grid-template-columns: 1fr 5fr 3fr 3fr;
        gap: 15px;
        // grid-template-columns: 75px calc(100% - 440px) auto calc(100% - 655px);
        // grid-template-columns: 75px 300px auto 150px;
        @include btw-sz(1100, 1199) {
          grid-template-columns: 75px 200px auto 150px;
        }
        @include max-mq(600) {
          // grid-template-columns: auto;
          // align-items: center;

          // justify-content: center;
          // justify-items: center;
          display: flex;
          grid-template-columns: none;
          gap: 10px;
          flex-wrap: wrap;
          .item_row {
            width: auto;
            text-align: start;
          }
          .item_row2 {
            margin-right: auto;
          }
        }
      }
      .close_icon {
        height: 15px;
        width: 15px;
        position: absolute;
        right: 15px;
        top: 15px;
      }
      .input_group_count {
        border-radius: 0.25rem;
        min-width: 150px;
        // overflow: hidden;
      }
      .incre_decre_count {
        border: 1px solid #0466c8;
        border-radius: 5px;
        outline: none;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        color: #99a6b9;
        background: transparent;
        width: 15px;
        height: 25px;
        box-shadow: none !important;
        &::placeholder {
          font-size: 12px;
        }
      }
      .my_group_btn {
        height: 25px;
        background: #0466c8;
        color: white;
        font-weight: 600;
        cursor: pointer;
        border-radius: 0;
        // &:nth-child(1) {
        //   border-radius: 0.25rem 0 0 0.25rem;
        // }
        // &:nth-last-child(1) {
        //   border-radius: 0 0.25rem 0.25rem 0;
        // }
      }
      .stockeName {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        .isin_check {
          font-size: 14px;
          color: #bcbcbc;
        }
      }
      .mobile_header_block {
        border-bottom: 1px solid #bcbcbc;
        width: calc(100% - 30px);
        .header_block,
        .input_counter_mb {
          width: 100%;
          padding: 15px 0;
        }
        @include btw-sz(650, 850) {
          display: flex;
          gap: 15px;
        }
        .input_counter_mb {
          @include min-mq(850) {
            display: none;
          }
        }
        .input_counter_mb {
          margin-bottom: 15px;
          margin-top: 5px;
          width: 200px;
        }
      }
      .img_logo_box {
        @include max-mq(600) {
          justify-content: center;
        }
        .img_logo {
          height: 45px;
          width: 45px;
          border-radius: 50%;
          object-fit: contain !important;
        }
      }
      .header_block {
        display: flex;
        justify-content: space-between;
        @include max-mq(850) {
          flex-direction: column;
          gap: 7px;
          // border-bottom: 1px solid #bcbcbc;
        }
        .order_id {
          // width: 100%;
          display: flex;
          gap: 15px;
          .txt {
            font-weight: 600;
            color: #bcbcbc;
          }
          .id_block {
            font-weight: 600;
            color: #000;
          }
        }
      }
      .mobile_input {
        @include max-mq(850) {
          display: none !important;
        }
      }
      .total_block {
        // grid-column-start: 1;
        // grid-column-end: 7;
        // display: block;
        width: 350px;
        // width: calc(100% - 25px);
        margin-top: 15px;
        display: flex;
        margin-left: auto;
        gap: 15px;
        // justify-content: flex-end;
        @include max-mq(600) {
          display: block;
          justify-content: end;
          width: 100%;
          margin-left: auto;
        }
        .checkStamp {
          display: flex;
          gap: 10px;
          align-items: center;
          @include max-mq(600) {
            width: 100%;
            justify-content: space-between;
          }
          .stamp_duty_check {
            color: #bcbcbc;
            font-size: 14px;
          }
          .stamp_duty_number {
            font-size: 18px;
          }
        }
      }
      .total_txt {
        color: #bcbcbc;
      }
      .item_row {
        display: flex;
        // justify-content: center;
        align-items: center;
      }
    }
  }
}
