@mixin min-width($min-width) {
  @media only screen and (min-width: #{$min-width + "px"}) {
    @content;
  }
}
@mixin max-width($max-width) {
  @media only screen and (max-width: #{$max-width + "px"}) {
    @content;
  }
}
@mixin btw-sz($min-width, $max-width) {
  @media only screen and (min-width: #{$min-width + "px"}) and (max-width: #{$max-width + "px"}) {
    @content;
  }
}

.contact-header {
  height: 175px;
  width: 100%;
  background-color: #002733;
  padding-top: 35px;
  .contact-header-bread {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 30px;
    text-transform: capitalize;
    color: #ffffff;
    @include max-width(549) {
      font-size: 20px;
    }
  }
  .contact-header-title {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 45px;
    text-transform: capitalize;
    color: #ffffff;
    position: relative;
    padding: 10px 0px;
    @include max-width(549) {
      font-size: 30px;
    }
    &::before {
      content: "";
      width: 50px;
      bottom: 0;
      left: 0;
      height: 5px;
      background-color: white;
      position: absolute;
    }
  }
}
.content-header {
  margin-top: 50px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  text-transform: capitalize;
  color: #002733;
  position: relative;
  padding: 10px 0px;
  @include max-width(549) {
    font-size: 16px;
  }
  &::before {
    content: "";
    width: 80px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 5px;
    background-color: #0466c8;
    position: absolute;
  }
  @include max-width(1199) {
    margin-top: 30px;
  }
}
.content-header-bottom {
  margin-top: 34px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  text-transform: capitalize;
  color: #b1b3c2;
  margin-bottom: 60px;
  margin-top: 15px;
  @include max-width(549) {
    margin-top: 18px;
    font-size: 14px;
  }
}

//  Chhintan CSS

.my-containor {
  display: flex;
  @include max-width(1399) {
    flex-direction: column;
  }
  .my-form {
    padding: 0px 50px;
    width: 80%;
    margin: auto;
    @include max-width(1199) {
      width: 100%;
      padding: 0 30px;
      margin: auto;
    }
    .first-row {
      @include max-width(1199) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      @include max-width(549) {
        .mu_custom_div_w {
          width: 100%;
        }
      }
    }
    .mobile_input_select {
      width: 378px;
      @include max-width(549) {
        width: 100% !important;
      }
    }
    .first-row .custom_in_view,
    textarea {
      width: 378px;
      height: 45px;
      background: #f8fbff;
      border: 0.5px solid #0466c8;
      border-radius: 11px;
      font-size: 18px;
      line-height: 23px;
      // text-transform: capitalize;
      color: rgba(3, 7, 30, 0.5);
      padding: 10px;
      @include max-width(549) {
        width: 100%;
      }
      &:focus {
        outline: 1px solid #0466c8;
      }
      .my-btn {
        margin-right: 25px;
      }
    }
    .first-row {
      display: flex;
      justify-content: space-between;
      gap: 15px;
      @include max-width(549) {
        flex-direction: column;
      }
    }
    .mylab {
      padding: 10px 0;
    }
    .my-custom-btn {
      display: flex;
      gap: 30px;
      @include max-width(549) {
        display: flex;
        flex-direction: column;
        gap: 15px;
        justify-content: center;
        align-items: center;
      }
    }

    .mylable {
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;

      color: #002733;
      @include max-width(549) {
        font-size: 20px;
      }
    }
    .mybr {
      width: 22px;
      margin: 0;
      opacity: 1;
      border-bottom: 3px solid #0466c8;
    }
    .mybutton {
      width: 133px;
      height: 34px;
      border: 1px solid #0466c8;
      color: #0466c8;
      background: white;
      border-radius: 5px;
      text-transform: capitalize;
      font-size: 18px;
      line-height: 23px;
      @include max-width(549) {
        width: 100%;
      }
    }
    .active {
      color: white !important;
      background: #0466c8 !important;
    }
    .myra {
      display: flex;
      justify-content: space-between;
      gap: 5px;
      @include max-width(549) {
        flex-wrap: wrap;
        justify-content: center;
        width: 100% !important;
      }
    }
    .myfor {
      margin-right: 25px;
    }
    .mytextarea {
      width: 100%;
      height: 150px;
      resize: none;
    }
    .myword {
      height: 18px;
      text-align: right;
      width: 99%;

      font-family: Source Sans Pro;
      font-style: italic;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      /* identical to box height */

      text-transform: capitalize;

      color: #b1b3c2;
    }
    .mystn {
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: 600;
      // font-size: 24px;
      font-size: 18px;
      line-height: 30px;
      text-transform: capitalize;

      color: #ffffff;

      width: 147px;
      // height: 55px;
      height: 40px;

      background: #0466c8;
      border-radius: 6px;
      @include btw-sz(550, 1199) {
        font-size: 16px;
        width: 125px;
        height: 45px;
      }
      @include max-width(549) {
        font-size: 14px;
        width: 125px;
        height: 45px;
      }
      // @include btw-sz(1200, 1650) {
      //   font-size: 24px;
      // }
    }
  }

  .my-info {
    // padding: 0px 50px;
    background: #f8fbff;
    box-shadow: 0px 0px 4px rgba(0, 120, 239, 0.25);
    border-radius: 18px;
    padding: 20px;
    height: max-content;
    @include max-width(1399) {
      margin-bottom: 15px;
    }
    .my-first {
      display: flex;
      svg {
        width: 25px;
        height: 90%;
        margin-top: 10px;
        @include max-width(549) {
          flex-basis: 30px auto !important;
        }
      }

      .myaddress {
        margin-left: 10px;
        font-size: 18px;
        width: 100%;
        label {
          @include btw-sz(550, 1199) {
            font-size: 18px;
          }
          @include max-width(549) {
            font-size: 18px;
          }
          @include btw-sz(1200, 1650) {
            font-size: 18px;
          }
        }
        .mybr {
          width: 22px;
          margin: 0;
          opacity: 1;
          border-bottom: 3px solid #0466c8;
        }
        .addre {
          font-size: 18px;
          line-height: 150%;
          text-transform: capitalize;
          color: #6c757d;
          margin-top: 5px;
          @include btw-sz(550, 1199) {
            font-size: 18px;
          }
          @include max-width(549) {
            font-size: 18px;
          }
          @include btw-sz(1200, 1600) {
            font-size: 18px;
          }
        }

        .email {
          white-space: nowrap;
          // overflow: hidden;
          // text-overflow: ellipsis;
          // width: 100%;
        }
      }
      .my-email {
        @include max-width(549) {
          max-width: 83%;
        }
        .mylink {
          font-family: Source Sans Pro;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 150%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
          /* identical to box height, or 36px */

          text-transform: capitalize;

          /* Primary */

          color: #0466c8;
          @include max-width(549) {
            font-size: 18px;
          }
        }
      }
    }
  }
}

.verify-otp {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 15px;
  @include max-width(1199) {
    grid-template-columns: 100%;
    justify-items: center;
  }
}
.custom-btn-view {
  width: 133px;
  height: 40px;
  border: 1px solid #0466c8;

  // color: #0466c8;
  color: #fff;
  background: white;
  border-radius: 5px;
  text-transform: capitalize;
  font-size: 18px;
  line-height: 24px;
  background: #0466c8;
  &:focus {
    background: #0466c8;
    color: white;
    box-shadow: 0;
  }
  &:disabled {
    background: #4d96df;
    color: white;
  }
}
.otp-input {
  // background: #f8fbff;
  border: 0.5px solid #0466c8;
  border-top-left-radius: 11px;
  border-bottom-left-radius: 11px;
  font-size: 18px;
  // line-height: 23px;
  // text-transform: capitalize;
  // color: rgba(3, 7, 30, 0.5);
  // padding: 10px;
  &:focus {
    box-shadow: 0px;
  }
}
.custom-otp-group {
  width: 378px;
  @include max-width(549) {
    width: 100%;
  }
}
.sent-otp {
  width: min-content;
}
.Mob_dd1 {
  border: 0.5px solid #0466c8;
  border-radius: 11px;
  .mob_input {
    border: none !important;
    outline: none;
    // margin-right: 5px;
    &:focus {
      box-shadow: none;
    }
  }
  .myBtnnImg {
    height: 16px;
    margin-bottom: 5px;
    margin-right: 5px;
  }
}
.country_drop {
  li {
    padding: 0;
    margin: 0;
  }
}
.mycountry-group {
  flex-wrap: nowrap !important;
  // width: min-content !important;
  width: 378px !important;
  .input-group {
    width: min-content;

    input.form-control.mob_input {
      margin: 0;
    }
  }
  input.form-control.mob_input {
    width: 100% !important;
    margin-top: 1px !important;
  }
}
.mycountry-group {
  @include max-width(549) {
    width: 100% !important;
    .input-group {
      width: 102px !important;
    }
    input.form-control.mob_input {
      width: 100% !important;
    }
  }
}

.map_container {
  // padding: 80px 100px;
  margin-top: 80px;
  width: 100%;
  img {
    // height: 810px;
    object-fit: contain;
    width: 100%;
  }
}

.contact_submit {
  &:disabled {
    background: #7fb0e1 !important;
  }
}
