// ----Media query mixin-----------//
@mixin min-mq($min-screen-size) {
  @media (min-width: #{$min-screen-size +"px"}) {
    @content;
  }
}

@mixin max-mq($max-screen-size) {
  @media (max-width: #{$max-screen-size +"px"}) {
    @content;
  }
}

// ----------------------------For Orientation-----------------------//
@mixin landscape-orientation($max-screen-size, $orientation) {
  @media (max-width: #{$max-screen-size +"px"}) and (orientation: $orientation) {
    @content;
  }
}

@mixin btw-mq($min-screen-size, $max-screen-size, $pixel-ratio, $orientation) {
  @media only screen and (min-width: #{$min-screen-size +"px"}) and (max-width: #{$max-screen-size +"px"}) and (-webkit-min-device-pixel-ratio: $pixel-ratio) and (orientation: $orientation) {
    @content;
  }
}
@mixin btw-sz($min-screen-size, $max-screen-size) {
  @media only screen and (min-width: #{$min-screen-size +"px"}) and (max-width: #{$max-screen-size +"px"}) {
    @content;
  }
}

.sharelist_content {
  .sharelist_box {
    .sharelist_header.share_box {
      margin-bottom: 25px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include max-mq(470) {
        display: block;
      }
    }
    .card_content {
      margin-bottom: 20px;
      width: 100%;
      background: white;
      border-radius: 11px;
      box-shadow: 0px 0px 5px rgb(0 0 0 / 27%);
      padding: 15px;
      &:hover {
        box-shadow: rgb(0 0 0 / 20%) 0px 12px 28px 0px,
          rgb(0 0 0 / 10%) 0px 2px 4px 0px,
          rgb(255 255 255 / 5%) 0px 0px 0px 1px inset;
      }

      .grid-sharelist {
        display: flex;
        gap: 15px;
        @include max-mq(550) {
          flex-direction: column;
          justify-content: center;
        }
      }
      .img_box_sharelist {
        @include max-mq(550) {
          text-align: center;
        }
        img {
          height: 90px;
          width: 150px;
          border-radius: 8px;
          object-fit: contain;
        }
      }
      .sharelist_content_box {
        width: calc(100% - 170px);
        @include max-mq(550) {
          width: 100%;
        }
        .compny_name {
          display: inline-block;
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          text-transform: capitalize;
          color: #002733;
          @include max-mq(550) {
            font-size: 16px;
            line-height: 22px;
            margin-top: 15px;
          }
          @include btw-sz(551, 1199) {
            font-size: 18px;
            line-height: 27px;
          }
          // white-space: nowrap;
          // overflow: hidden;
          // text-overflow: ellipsis;
          // width: 100%;
        }
        .isin {
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          text-transform: capitalize;
          color: #adb5bd;
        }
        .price_block {
          display: flex;
          justify-content: space-between;
          @include max-mq(550) {
            margin-top: 15px;
          }
        }
        .broker_price {
          font-weight: 600;
          font-size: 16px;
          line-height: 150%;
          text-transform: capitalize;
          color: black;
        }
      }
    }
  }
}

.sharelist_table {
  border-radius: 22px 22px 0px 0px;
  overflow: hidden;
  .sharelist_table_head {
    font-size: 18px;
    background: #0466c8;
    border-top-right-radius: 22px;
    border-top-left-radius: 22px;
    color: #fff !important;
    th {
      color: #fff;
    }
  }
}

.grid_table_sharelist {
  display: flex;
  // justify-content: center;
  gap: 10px;
  align-content: center;
  img.comp {
    height: 35px;
    width: 35px;
    border-radius: 50%;

    object-fit: contain;
  }
  .title_sharelst {
    font-weight: 600;
    font-size: 16px;
    min-width: 490px;
    max-width: 490px;
    text-align: left;
  }
  .tt-left {
    text-align: left !important;
  }
}
