// ----Media query mixin-----------//
@mixin min-mq($min-screen-size) {
  @media (min-width: #{$min-screen-size +"px"}) {
    @content;
  }
}

@mixin max-mq($max-screen-size) {
  @media (max-width: #{$max-screen-size +"px"}) {
    @content;
  }
}

// ----------------------------For Orientation-----------------------//
@mixin landscape-orientation($max-screen-size, $orientation) {
  @media (max-width: #{$max-screen-size +"px"}) and (orientation: $orientation) {
    @content;
  }
}

@mixin btw-mq($min-screen-size, $max-screen-size, $pixel-ratio, $orientation) {
  @media only screen and (min-width: #{$min-screen-size +"px"}) and (max-width: #{$max-screen-size +"px"}) and (-webkit-min-device-pixel-ratio: $pixel-ratio) and (orientation: $orientation) {
    @content;
  }
}
@mixin btw-sz($min-screen-size, $max-screen-size) {
  @media only screen and (min-width: #{$min-screen-size +"px"}) and (max-width: #{$max-screen-size +"px"}) {
    @content;
  }
}

.main-news-title {
  text-align: center;
  margin-bottom: 30px;

  margin-top: 50px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  text-transform: capitalize;
  color: #002733;
  position: relative;
  padding: 10px 0px;
  &::before {
    content: "";
    width: 50px;
    height: 3px;
    background: #002733;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    position: absolute;
  }
}
.in-news-section {
  .in-news-grid {
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(235px, 1fr));
    gap: 15px;
    margin-bottom: 100px;
    @include max-mq(550){
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      gap: 10px;
      margin-top: 25px;
      margin-bottom: 40px;
    }
    .in-news-card {
      border-radius: 11px;
      box-shadow: 0px 0px 5px rgb(0 0 0 / 27%);
      &:hover {
        box-shadow: rgb(0 0 0 / 20%) 0px 12px 28px 0px,
          rgb(0 0 0 / 10%) 0px 2px 4px 0px,
          rgb(255 255 255 / 5%) 0px 0px 0px 1px inset;
      }
      padding: 15px;
      .img-box {
        height: 160px;
        width: 100%;
        img {
          height: 100%;
          width: 100%;

          object-fit: cover;
        }
      }
      .news_desc {
        .news_desc_title {
          margin-top: 15px;
          font-size: 16px;
          font-weight: 600;
          line-height: 21px;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
          @include max-mq(550){
            font-size: 14px;
          }
        }
        .desc_box {
          font-size: 14px;
          margin-top: 7px;
          line-height: 17px;
          color: #bcbcbc;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
          @include max-mq(550){
            margin-top: 0px;
            font-size: 12px;
          }
        }
      }
    }
    .desc_footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #f7f7f7;
      margin-top: 7px;
      padding-top: 7px;
      @include max-mq(550){
        padding-top: 0px;
      }
      .date {
        color: #bcbcbc;
        font-size: 12px;
      }
      .read_more {
        color: #002733;
        text-transform: capitalize;
        font-weight: 500;
        a {
          text-decoration: none;
          color: #002733;
          @include max-mq(550){
            font-size: 14px;
          }
        }
      }
    }
  }
}

img.no_data {
  max-height: 600px;
}
