// ----Media query mixin-----------//
@mixin min-mq($min-screen-size) {
  @media (min-width: #{$min-screen-size +"px"}) {
    @content;
  }
}

@mixin max-mq($max-screen-size) {
  @media (max-width: #{$max-screen-size +"px"}) {
    @content;
  }
}

// ----------------------------For Orientation-----------------------//
@mixin landscape-orientation($max-screen-size, $orientation) {
  @media (max-width: #{$max-screen-size +"px"}) and (orientation: $orientation) {
    @content;
  }
}

@mixin btw-mq($min-screen-size, $max-screen-size, $pixel-ratio, $orientation) {
  @media only screen and (min-width: #{$min-screen-size +"px"}) and (max-width: #{$max-screen-size +"px"}) and (-webkit-min-device-pixel-ratio: $pixel-ratio) and (orientation: $orientation) {
    @content;
  }
}
@mixin btw-sz($min-screen-size, $max-screen-size) {
  @media only screen and (min-width: #{$min-screen-size +"px"}) and (max-width: #{$max-screen-size +"px"}) {
    @content;
  }
}
// .bred-crumb {
//   height: 150px;
//   background: #002733;
//   padding: 10px 0;
// }
// .bread-title {
//   margin-bottom: 10px;
//   font-family: Source Sans Pro;
//   font-style: normal;
//   font-weight: 600;
//   font-size: 36px;
//   line-height: 45px;
//   text-transform: capitalize;
//   color: #ffffff;
//   position: relative;
//   padding: 15px 0;
//   &::before {
//     content: "";
//     width: 50px;
//     bottom: 0;
//     left: 0;
//     height: 5px;
//     background-color: white;
//     position: absolute;
//   }
//   @include max-mq(1199) {
//     font-size: 20px;
//   }
//   @include btw-sz(550, 1199) {
//     font-size: 26px;
//   }
// }
// .bread-content {
//   .breads {
//     color: white;
//   }
// }

.blogBack {
  display: inline;
  align-items: center;
  margin-top: 20px;
  color: #002733;
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include max-mq(600) {
    font-size: 14px;
  }

  img {
    height: 13px;
    margin-right: 10px;
    @include max-mq(750) {
      margin-top: 10px;
      height: 10px;
    }
  }
}
.card-img-holder {
  width: 100%;
  height: auto;
  position: relative;
  padding-top: 15px;
  .blogBackground {
    height: 450px;
    width: 100%;
    object-fit: cover;
    object-position: top;
    @include max-mq(750) {
      height: 320px;
    }
  }
  .inner_containtt {
    // display: flex;
    // justify-content: center;
    position: relative;
    top: -65px;
    .card222 {
      background: #fff;
      border-radius: 10px;
      padding: 1.5rem;
      position: relative;
      box-shadow: 0px 8px 15px rgba(208, 208, 208, 0.25);
      width: 80%;
      margin: auto;
      margin-bottom: 15px;
      @include max-mq(750) {
        padding: 15px;
        width: 95%;
      }

      .blog-title {
        color: #002733;
        // font-size: 34px;
        font-size: 23px;

        font-weight: 600;
        line-height: 1.192em;
        @include max-mq(750) {
          font-size: 18px;
          // line-height: 28px;
        }
      }
      .blog-time {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include max-mq(549) {
          flex-direction: column;
          align-items: flex-start;
          gap: 15px;
        }
        .icon_share_content {
          display: flex;
        }
      }
      .description {
        // color: #000;
        // font-size: 18px;
        // letter-spacing: 0.02em;
        // line-height: 24px;
        color: #555;
        font-size: 18px;
        line-height: 1.667em;
        margin: 0px;
        @include max-mq(549) {
          font-size: 14px;
          line-height: 20px;
        }
        @include btw-sz(550, 1149) {
          font-size: 16px;
        }
        p {
          @include max-mq(600) {
            margin-bottom: 5px;
          }
        }
      }
      .blog-time {
        // padding-top: 10px;
        // font-size: 1rem;
        // color: #000;
        // margin-left: 30px;
        padding-top: 8px;
        color: #002733;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 2px;
        text-transform: uppercase;
        @include max-mq(600) {
          padding-top: 3px;
        }
        .datee {
          font-weight: 600;
        }
      }
    }
    .card2222 {
      margin-bottom: -60px;
    }
  }
}

.card333 {
  background: #fff;
  border-radius: 10px;
  padding: 1.5rem;
  position: relative;
  box-shadow: 0px 8px 15px rgba(208, 208, 208, 0.25);
}

.logo_flex {
  display: flex;
  gap: 5px;
  @include max-mq(600) {
    gap: 3px;
  }
  svg {
    @include max-mq(600) {
      height: 18px;
      width: 18px;
    }
  }
}
img.icon_share_blog {
  background: #002733;
  border: 1px solid #002733;
  border-radius: 50%;
}
