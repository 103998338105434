.error {
  .error_container {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 100%;
      height: 100%;
      max-height: 600px;
    }
    // .error_icon {
    //   max-height: 100%;
    //   height: 100%;
    //   width: 100%;
    // }
  }
}
