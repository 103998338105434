// ----Media query mixin-----------//
@mixin min-mq($min-screen-size) {
  @media (min-width: #{$min-screen-size +"px"}) {
    @content;
  }
}

@mixin max-mq($max-screen-size) {
  @media (max-width: #{$max-screen-size +"px"}) {
    @content;
  }
}

// ----------------------------For Orientation-----------------------//
@mixin landscape-orientation($max-screen-size, $orientation) {
  @media (max-width: #{$max-screen-size +"px"}) and (orientation: $orientation) {
    @content;
  }
}

@mixin btw-mq($min-screen-size, $max-screen-size, $pixel-ratio, $orientation) {
  @media only screen and (min-width: #{$min-screen-size +"px"}) and (max-width: #{$max-screen-size +"px"}) and (-webkit-min-device-pixel-ratio: $pixel-ratio) and (orientation: $orientation) {
    @content;
  }
}
@mixin btw-sz($min-screen-size, $max-screen-size) {
  @media only screen and (min-width: #{$min-screen-size +"px"}) and (max-width: #{$max-screen-size +"px"}) {
    @content;
  }
}

.news-right-box {
  padding: 20px 0px 50px;
  @include max-mq(600){
    padding: 20px 0px 10px;
  }
  .title {
    display: flex;
    justify-content: space-between;
    @include max-mq(470) {
      display: block;
    }
    @include max-mq(768) {
      padding-bottom: 0px;
    }
    .first {
      .r-b-title {
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
        text-transform: capitalize;
        color: #002733;
        @include max-mq(768) {
          font-size: 20px;
        line-height: 20px;
        }
      }
      .hr1 {
        width: 26px;
        height: 0px;
        opacity: 1;
        margin: 5px;
        margin-left: 0px;
        border-radius: 5px;
        border: 2px solid #0466c8;
      }
    }
    .search {
      width: 300px;
      height: 45px;
      background: #ffffff;
      // box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      box-shadow: 0px 8px 15px rgba(208, 208, 208, 0.25);
      border-radius: 12px;
      font-size: 24px;
      line-height: 30px;
      color: rgba(3, 7, 30, 0.5);
      display: flex;
      justify-content: flex-start;
      align-items: center;

      @include max-mq(768) {
        width: 200px;
      }

      @include max-mq(470) {
        margin-top: 20px;
        width: 100%;
      }
      .icon {
        margin: 10px;
        width: 25px !important;
      }
      input {
        border: none;
      }
      ::placeholder {
        font-size: 20px;
        line-height: 30px;
        color: rgba(3, 7, 30, 0.5);
      }
      input[type="text"] {
        border: none;
        outline: none;
        width: 98%;
        margin-right: 5px;
        font-size: 18px;
        line-height: 26px;
        color: rgba(3, 7, 30, 0.5);
        @include max-mq(768) {
          font-size: 18px;
        }
        &::placeholder {
          font-size: 18px;
        }
      }
    }
  }
  .col-card {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    grid-auto-rows: auto !important;
    grid-gap: 20px;
    padding: 25px 0px 0;
    @include btw-sz(200, 650) {
      grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
      gap: 10px;
    }
    .prod_card {
      position: relative;
      background: #ffffff;
      border-radius: 7px;
      padding: 20px;
      height: 100%;
      @include max-mq(600) {
          padding: 10px;
      }
      box-shadow: 0px 0px 5px rgb(0 0 0 / 27%);
      &:hover {
        box-shadow: rgb(0 0 0 / 20%) 0px 12px 28px 0px,
          rgb(0 0 0 / 10%) 0px 2px 4px 0px,
          rgb(255 255 255 / 5%) 0px 0px 0px 1px inset;
      }
      .img-box {
        text-align: center;
        height: 190px;
        width: inherit;
        @include btw-sz(200, 650) {
          height: 145px;
        }
        .prod-img {
          height: inherit;
          width: 100%;
          object-fit: cover;
          border-radius: 7px;
          object-position: top;
          cursor: pointer;
        }
      }
      .prod-details {
        margin-top: 10px;
        @include btw-sz(300, 550) {
          padding: 5px;
          padding-bottom: 0px !important;
          margin-top: 0;
        }
        @include btw-sz(550, 900) {
          padding: 5px;
          padding-bottom: 10px !important;
          margin-top: 0;
        }
        @include btw-sz(900, 1199) {
          padding: 15px;
          margin-top: 0;
        }
        .prod_head {
          display: flex;
          justify-content: space-between;
          .title {
            cursor: pointer;
            font-weight: 600;
            font-size: 18px;
            line-height: 25px;
            color: #000000;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            @include max-mq(600){
              font-size: 14px;
              line-height: 22px;
            }
          }
        }
      }
      .summery-det {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.02em;
        margin: 3px 0px;
        color: #777777;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        @include max-mq(600){
          font-size: 12px;
          line-height: 18px;
        }
      }
      .vendor-det {
        font-weight: 600;
        font-size: 14px;
        line-height: 15px;
        letter-spacing: 0.02em;
        color: #777777;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        @include max-mq(600){
          font-size: 12px;
          line-height: 18px;
        }
        .p-date {
          font-size: 13px;
          font-weight: 500;
          @include max-mq(600){
            font-size: 12px;
            line-height: 18px;
          }
        }
      }
    }
  }
}
