// ----Media query mixin-----------//
@mixin min-mq($min-screen-size) {
  @media (min-width: #{$min-screen-size +"px"}) {
    @content;
  }
}

@mixin max-mq($max-screen-size) {
  @media (max-width: #{$max-screen-size +"px"}) {
    @content;
  }
}

// ----------------------------For Orientation-----------------------//
@mixin landscape-orientation($max-screen-size, $orientation) {
  @media (max-width: #{$max-screen-size +"px"}) and (orientation: $orientation) {
    @content;
  }
}

@mixin btw-mq($min-screen-size, $max-screen-size, $pixel-ratio, $orientation) {
  @media only screen and (min-width: #{$min-screen-size +"px"}) and (max-width: #{$max-screen-size +"px"}) and (-webkit-min-device-pixel-ratio: $pixel-ratio) and (orientation: $orientation) {
    @content;
  }
}
@mixin btw-sz($min-screen-size, $max-screen-size) {
  @media only screen and (min-width: #{$min-screen-size +"px"}) and (max-width: #{$max-screen-size +"px"}) {
    @content;
  }
}

.sharelist_content {
  border-radius: 38px 0px 0px 38px;
  height: calc(100vh - 80px);
  // height: calc(100vh - 95px);
  overflow: auto;
  background-color: #f9f9f9;
  border: 1px solid #ececec;
  box-sizing: border-box;
  padding: 40px 80px;
  @include max-mq(1100){
    border-radius: 38px 38px 0px 0px;
  }
  @include max-mq(600) {
    padding: 15px;
  }
  .sharelist_box {
    padding: 20px 0px 50px;
    @include max-mq(470){
      padding-bottom: 20px;
    }
    .sharelist_header {
      .title {
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
        text-transform: capitalize;
        color: #002733;
        padding: 15px 0;
        padding-top: 0px;
        position: relative;
        &::before {
          content: "";
          // height: 3px;
          // background-color: #002733;
          // width: 50px;
          width: 46px;
          height: 0px;
          opacity: 1;
          margin: 5px;
          margin-left: 0px;
          border-radius: 5px;
          border: 2px solid #0466c8;
          background-color: #0466c8;
          position: absolute;
          left: 0;
          bottom: 1px;
        }
      }
      .header_box {
        // margin-top: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include max-mq(600) {
          flex-direction: column;
          gap: 15px;
        }
        .left_box {
          @include max-mq(470){
            margin-top: 15px;
            width: 100%;
          }
          .search-box {
            padding: 7px 8px;
            background-color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 15px;
            box-shadow: 0px 8px 15px rgb(208 208 208 / 25%);
            border-radius: 12px;
            height: 45px;
            width: 300px;
            @include max-mq(470){
              width: 100%;
            }
            svg {
              height: 25px;
              width: 15px;
            }
            input {
              border: none;
              outline: none;
              width: 82%;
              margin-right: 5px;
              font-size: 18px;
              font-weight: 400;
              line-height: 26px;
              color: rgba(3, 7, 30, 0.5);
            }
          }
        }
        .right_box {
          .client_btn {
            background: #002733 !important;
            outline: none;
            border: none;
            box-shadow: none !important;
            width: 110px;
          }
        }
      }
      .header_box-2{
        margin-top: 15px;
        @include max-mq(600){
          flex-direction: row;
          gap: 5px;
        }
        .left_box{
          @include max-mq(600){
            margin-top: 0px;
          }
          .search-box{
            @include max-mq(600){
              height: 36px;
            }
          }
        }
      }
    }
  }
  //   .modal_view_content {
  //   }
}

.row_input.row_first {
  @include max-mq(550) {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.row_input {
  display: flex;
  //   justify-content: center;
  align-items: center;
  gap: 15px;
  margin-bottom: 30px;
  @include max-mq(550) {
  }
  .select_box {
    border: 1px solid #bcbcbc;
    box-shadow: none !important;
  }
  .select_content {
    // width: 185px !important;
    .select_box {
      border: 1px solid #bcbcbc;
      box-shadow: none !important;
    }
  }
  .input_box {
    width: 100%;
    input { 
      box-shadow: none !important;
      border: 1px solid #bcbcbc;
    }
  }
}
.content_box_sharelist {
  // margin-top: 45px;
  padding-top: 30px;
  border-radius: 11px !important;
  // overflow: hidden;
  @include max-mq(1100){
    padding-top: 10px;
  }
  @include max-mq(600){
    padding-top: 0px;
  }
  .card_view {
    background-color: white;
    margin-bottom: 15px;
    padding: 15px;
    // margin: 0 15px 15px;
    border-radius: 11px;
    box-shadow: 0px 8px 15px rgb(208 208 208 / 25%);
    display: grid;
    grid-template-columns: 70px 100%;
    // &:hover {
    //   box-shadow: rgb(0 0 0 / 20%) 0px 12px 28px 0px,
    //     rgb(0 0 0 / 10%) 0px 2px 4px 0px,
    //     rgb(255 255 255 / 5%) 0px 0px 0px 1px inset;
    // }
    .name_title {
      color: #bcbcbc;
    }
  }
  .table_client {
    .more_icon {
      height: 15px;
      width: 15px;
      object-fit: cover;
    }
    .table_client_head {
      background-color: #0466c8;
      color: white !important;
    }
  }
}
.modal_header_bg {
  background-color: #002733;
  color: white;
  .btn-close {
    color: white;
    display: none;
    svg {
      fill: white;
    }
  }
}
.btn_modal {
  background: #002733;
  min-width: 300px;
  outline: none;
  border: none;
  box-shadow: none !important;
  @include max-mq(550) {
    min-width: 150px;
  }
  &:hover {
    background: #002733;
  }
}

.icon_btn {
  cursor: pointer;
}
.client_card_view {
  background: white;
  padding: 15px;
  margin-top: 25px;
  border-radius: 11px;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 27%);
  @include max-mq(600){
    padding: 10px;
    margin-top: 20px;
  }
  &:hover {
    box-shadow: rgb(0 0 0 / 20%) 0px 12px 28px 0px,
      rgb(0 0 0 / 10%) 0px 2px 4px 0px,
      rgb(255 255 255 / 5%) 0px 0px 0px 1px inset;
  }

  .header_title_client {
    font-size: 23px;
    font-weight: 600;
    border-bottom: 1px solid #bcbcbc;
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include max-mq(1100){
      padding-top: 0px;
    }
    div{
      @include max-mq(600){
        font-size: 20px;
      }
    }
    .info-svg {
      height: 15px;
      width: 15px;
      fill: gray;
    }
  }
  .client_card_content {
    // display: flex;
    // gap: 15px;
    .card_content_dev {
      width: 100%;
      display: flex;
      gap: 15px;
      @include max-mq(600) {
        display: block;
      }
      .left-block-content,
      .right-block-content {
        width: 100%;
      }
      .left_block {
        margin: 15px 0;
        display: flex;
        align-items: center;
        gap: 15px;
        font-weight: 600;
        @include max-mq(600){
          margin: 10px 0px;
        }
        .icon_box {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .phone_icon {
          transform: rotate(180deg);
        }
      }
    }
  }
}
.my_counry_gruop_code {
  border: 1px solid #bcbcbc;
  border-radius: 0.25rem;
  .country_code_img {
    width: 25px;
  }
}

.icon_box22{
  img{
    height: 13px;
    width: 13px;
  }
}

.tableWidthFix{
  min-width: 50px !important;
}

.tt-left{
  @include max-mq(1350){
    padding-left: 15px !important;
  }
}

input:disabled{
  box-shadow: none !important;
  border: 1px solid #bcbcbc !important;
  background-color: rgb(216, 219, 224) !important;
  border: none !important;
}

.countryVert:disabled{
  box-shadow: none !important;
  border: 1px solid #bcbcbc !important;
  background-color: rgb(216, 219, 224) !important;
  border: none !important;
}