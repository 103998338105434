.ad-cp-name{
    min-width: 180px;
}
.ad-cp-email{
    min-width: 130px;
}
.ad-cp-mobile{
    min-width: 120px;
}
.ad-cp-id{
    min-width: 110px;
}
.ad-client-name{
    min-width: 150px;
}
.ad-client-email{
    min-width: 150px;
}
.ad-client-mobile{
    min-width: 150px;
}

.ad-cp-stampD{
    min-width: 100px;
}
.ad-cp-total{
    min-width: 150px;
}

.ad-company-name{
    min-width: 200px;
}
.ad-sector{
    min-width: 100px;
}
.ad-isincode{
    min-width: 100px;
}
.ad-dp-name{
    min-width: 100px;
}
.ad-dp-type{
    min-width: 100px;
}
.ad-account-num{
    min-width: 100px;
}
.ad-payment-date{
    min-width: 100px;
}
.ad-payment-mode{
    min-width: 100px;
}
.ad-ref-id{
    min-width: 200px;
}
.ad-client-cml{
    min-width: 100px;
}
.ad-client-pan{
    min-width: 100px;
}
.ad-client-pan{
    min-width: 100px;
}
.ad-client-cheque{
    min-width: 100px;
}
.ad-gst{
    min-width: 100px;
}
.ad-client-gst{
    min-width: 100px;
}
.ad-other-file{
    min-width: 80px;
}
.ad-broker-cheque{
    min-width: 100px;
}
.ad-broker-percentage{
    min-width: 100px;
}
.ad-date{
    min-width: 150px;
}


.ad-client-pan{
    min-width: 130px;
}


.ad-client-cheque{
    min-width: 180px;
}
.ad-gst{
    min-width: 180px;
}
.ad-client-gst{
    min-width: 180px;
}
.ad-client-cheque{
    min-width: 180px;
}
.ad-other-file{
    min-width: 180px;
}