@mixin min-width($min-width) {
  @media only screen and (min-width: #{$min-width + "px"}) {
    @content;
  }
}
@mixin max-width($max-width) {
  @media only screen and (max-width: #{$max-width + "px"}) {
    @content;
  }
}
@mixin btw-sz($min-width, $max-width) {
  @media only screen and (min-width: #{$min-width + "px"}) and (max-width: #{$max-width + "px"}) {
    @content;
  }
}

.disable_input {
  background: transparent;
  border: none;
  outline: none;
}
.sell-content {
  .header-bg {
    height: 200px;
    overflow: hidden;
    background: url(https://tradeunlisted.com/media/hd-wide-1.jpg);
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    // filter: brightness(0.5);
    .view-txt {
      z-index: 9;
      color: white;
      font-size: 24px;
      text-align: center;
      @include max-width(549) {
        font-size: 18px;
      }
    }
  }
  .form-content {
    .button-form {
      width: 133px;
      height: 34px;
      left: 262px;
      top: 953px;
      outline: none;
      border: none;
      color: white;
      background: #0466c8;
      border-radius: 5px;
      font-family: "Source Sans Pro";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 23px;
      /* identical to box height */

      text-transform: capitalize;

      color: #ffffff;
      &:disabled {
        background: #509be7;
      }
    }
    .form-title {
      font-size: 27px;
      font-weight: 600;
      color: #004767;
      text-align: center;
      word-spacing: 2px;
      letter-spacing: 0.4px;
      position: relative;
      padding: 30px;
      @include max-width(549) {
        font-size: 20px;
      }
      &::before {
        content: "";
        width: 70px;
        height: 4px;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        background-color: #004767;
      }
    }
    .form-view-content {
      margin: 30px;
      .input_div {
        max-width: 500px;
        margin: auto;
        margin-bottom: 30px;
        input.input-design {
          width: 100%;
          height: 45px;
          background: #f8fbff;
          border: 0.5px solid #0466c8;
          border-radius: 11px;
          font-size: 18px;
          line-height: 23px;
          // text-transform: capitalize;
          color: rgba(3, 7, 30, 0.5);
          padding: 10px;
          &:focus {
            outline: 1px solid #0466c8;
          }
        }
        .info-warning {
          color: rgb(64, 77, 96);
        }
      }
      .mycountry-group {
        border: 0.5px solid #0466c8;
        border-radius: 11px;
        width: 100% !important;
        .input-design {
          border: none !important;
          &:focus {
            box-shadow: 0px !important;
          }
        }
      }
      .myBtnnImg {
        height: 16px;
        margin-bottom: 5px;
        margin-right: 5px;
      }
      .button-form {
        width: 133px;
        height: 34px;
        left: 262px;
        top: 953px;
        outline: none;
        border: none;
        color: white;
        background: #0466c8;
        border-radius: 5px;
        font-family: "Source Sans Pro";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 23px;
        /* identical to box height */

        text-transform: capitalize;

        color: #ffffff;
        @include max-width(549) {
          font-size: 14px;
        }
        &:disabled {
          background: #7fb0e1;
        }
      }
      .terms_condition {
        margin: 15px 0;
        #terms {
          padding-top: 15px;
        }
        .terms-check {
          display: flex;
          justify-content: center;
          // align-items: center;
          gap: 5px;
          label {
            @include max-width(549) {
              font-size: 14px;
            }
          }
        }
      }
      .generate_otp {
        margin: 15px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 15px;
        .generate_otp_txt {
          color: rgb(64, 77, 96);
        }
        .otp_btns {
          display: flex;
          gap: 15px;
        }
      }
      .otpsell-label {
        text-align: center;
        margin: 15px 0;
      }
      .otp-verify {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 15px;
        @include max-width(549) {
          flex-direction: column;
        }
        .input-design {
          max-width: 345px;
          height: 45px;
          background: #f8fbff;
          border: 0.5px solid #0466c8;
          border-radius: 11px;
          font-size: 18px;
          line-height: 23px;
          text-transform: capitalize;
          color: rgba(3, 7, 30, 0.5);
          padding: 10px;
        }
        .verify-btn {
          position: relative;
          padding: 15px;
          // &::before {
          //   position: absolute;
          //   content: "";
          //   width: 150px;
          //   height: 2px;
          //   background-color: #0466c8;
          //   bottom: 0;
          //   left: 50%;
          //   transform: translateX(-50%);
          // }
        }
        .custom-select-view {
          border: 1px solid #0466c8;
        }
      }
      .button-form-content {
        width: max-content;
        height: 34px;
        left: 262px;
        top: 953px;
        outline: none;
        border: none;
        color: white;
        background: #0466c8;
        border-radius: 5px;
        font-family: "Source Sans Pro";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 23px;
        text-transform: capitalize;
        color: #ffffff;
        @include max-width(549) {
          font-size: 14px;
          height: auto;
        }
        @include btw-sz(550, 1149) {
          font-size: 16px;
          height: auto;
        }
      }
      .add-price-qty {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
.sell_table {
  max-width: 500px;
  overflow-y: auto;
}
.name_td {
  max-width: 500px !important;
  width: 50% !important;
  min-width: 300px;
}
.selling_price {
  min-width: 200px;
}
.responsive-footer {
  // overflow-x: auto;
  .footer-modal-view {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    @include max-width(549) {
      flex-direction: column;
    }
  }
  .warning_txt {
    flex-grow: 1;
  }
}
.modal_input {
  border: 1px solid #bcbcbc;
  &:focus {
    outline: 1px solid #0466c8;
  }
}
.main-modal-footer {
  display: block;
}
.footer-modal-view {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mob_dropdown {
  background-color: transparent !important;
  color: black !important;
  outline: none !important;
  border: none !important;
}
select.input-design {
  width: 100%;
  //   height: 45px;
  border: 0.5px solid #0466c8;
  padding: 6px;
  border-radius: 11px;

  //   option {
  // background-color: gray;
  // &:checked {
  //   background-color: red !important;
  // }
  //   }
}

.array_add {
  position: absolute;
  bottom: -55px;
  right: 142px;
}
.button_custom_focus {
  outline: none;
  border: none;
  box-shadow: none;
}
.button_custom_focus.filled {
  background: #004767;
  color: white;
}

.card_sell_bottom_row {
  margin-bottom: 45px;
  .section_sell_col {
    .card_sell_content {
      height: 100%;
      // border: 1px solid #bcbcbc;
      border-radius: 11px;
      padding: 15px;
      box-shadow: 0px 0px 5px rgb(0 0 0 / 27%);
      &:hover {
        box-shadow: rgb(0 0 0 / 20%) 0px 12px 28px 0px,
          rgb(0 0 0 / 10%) 0px 2px 4px 0px,
          rgb(255 255 255 / 5%) 0px 0px 0px 1px inset;
      }
      .card_sell_content_header {
        .icon_sell {
          text-align: center;
          svg {
            height: 45px;
            width: 45px;
            fill: #004767;
          }
          margin-bottom: 15px;
        }
        .title {
          font-size: 20px;
          margin-top: 0;
          margin-bottom: 15px;
          font-weight: 600;
          text-align: center;
          color: #004767;
        }
        .desc_sell {
          text-align: center;
        }
      }
    }
  }
}
.sell_header_title_section {
  .form-title {
    margin-bottom: 15px;
  }
  .header_desc {
    margin-bottom: 30px;
  }
}
.submit_before {
  position: relative;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  &::before {
    position: absolute;
    content: "";
    width: 150px;
    height: 2px;
    background-color: #0466c8;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
select.custom_selelct.form-control {
  outline: 1px solid #0466c8;
  border-radius: 11px !important;
}
.min_qty_talble {
  min-width: 150px;
}
