@mixin min-width($min-width) {
  @media only screen and (min-width: #{$min-width + "px"}) {
    @content;
  }
}
@mixin max-width($max-width) {
  @media only screen and (max-width: #{$max-width + "px"}) {
    @content;
  }
}
@mixin btw-sz($min-width, $max-width) {
  @media only screen and (min-width: #{$min-width + "px"}) and (max-width: #{$max-width + "px"}) {
    @content;
  }
}

.disclosure_main {
  p {
    text-align: justify;
  }
  ul {
    list-style-type: disc !important;
    // @include max-width(992){
    //   margin: 0px -10px;
    // }
    li{
      @include max-width(992){
        font-size: 14px;
      }
    }
  }
  .main_dis_title {
    margin-top: 50px;

    text-align: center;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    text-transform: capitalize;
    color: #002733;
    position: relative;
    padding: 10px 0px;
    @include max-width(992){
      margin-top: 30px;
      font-size: 20px;
    }
    @include max-width(550){
      margin-top: 15px;
      font-size: 18px;
      line-height: 24px;
    }
    &::before {
      position: absolute;
      content: "";
      width: 50px;
      height: 3px;
      bottom: 0;
      background: #002733;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .main_dis_content {
    margin: 50px 0;
    @include max-width(992){
      margin-top: 30px;
      margin-bottom: 30px;
      font-size: 20px;
    }
    @include max-width(550){
      margin-top: 15px;
      margin-top: 15px;
      font-size: 18px;
      line-height: 24px;
    }
    p.para_content {
      padding-top: 15px;
      @include max-width(992){
        padding-top: 10px;
        margin-bottom: 10px;
        font-size: 14px;
      }
      @include max-width(550){
        font-size: 14px;
        padding-top: 5px;
        margin-bottom: 5px;
      }
      b{
        @include max-width(992){
          font-size: 14px;
        }
        @include max-width(550){
          font-size: 12px;
        }
      }
    }
  }
}
.terms-and-condition.sub-content {
  p {
    padding-top: 15px;
    @include max-width(992){
      padding-top: 10px;
      margin-bottom: 10px;
      font-size: 14px;
    }
    @include max-width(550){
      font-size: 14px;
      padding-top: 5px;
      margin-bottom: 5px;
    }
    b{
      @include max-width(992){
        font-size: 14px;
      }
      @include max-width(550){
        font-size: 12px;
      }
    }
  }
  ul{
    // @include max-width(550){
    //   margin: 0px -15px;
    // }
    li{
      @include max-width(550){
        font-size: 14px;
      }
      b{
        @include max-width(992){
          font-size: 14px;
        }
        @include max-width(550){
          font-size: 12px;
        }
      }
    }
  }
}
.disclosure_main {
  margin-bottom: 100px !important;
  @include max-width(992){
    margin-bottom: 50px !important;
  }
  @include max-width(550){
    margin-bottom: 25px !important;
  }
}

.pay-policy p{
  @include max-width(992){
    padding-top: 10px;
    margin-bottom: 10px;
    font-size: 14px;
  }
  @include max-width(550){
    font-size: 14px;
    padding-top: 5px;
    margin-bottom: 5px;
  }
  b{
    @include max-width(992){
      font-size: 14px;
    }
    // @include max-width(550){
    //   font-size: 14px;
    // }
  }
}