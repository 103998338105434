// ----Media query mixin-----------//
@mixin min-mq($min-screen-size) {
  @media (min-width: #{$min-screen-size +"px"}) {
    @content;
  }
}

@mixin max-mq($max-screen-size) {
  @media (max-width: #{$max-screen-size +"px"}) {
    @content;
  }
}

// ----------------------------For Orientation-----------------------//
@mixin landscape-orientation($max-screen-size, $orientation) {
  @media (max-width: #{$max-screen-size +"px"}) and (orientation: $orientation) {
    @content;
  }
}

@mixin btw-mq($min-screen-size, $max-screen-size, $pixel-ratio, $orientation) {
  @media only screen and (min-width: #{$min-screen-size +"px"}) and (max-width: #{$max-screen-size +"px"}) and (-webkit-min-device-pixel-ratio: $pixel-ratio) and (orientation: $orientation) {
    @content;
  }
}
@mixin btw-sz($min-screen-size, $max-screen-size) {
  @media only screen and (min-width: #{$min-screen-size +"px"}) and (max-width: #{$max-screen-size +"px"}) {
    @content;
  }
}
.upcoming_ipos {
  .table_pad {
    margin: 30px 0;
  }
  .upcoming_table {
    border-radius: 22px 22px 0px 0px;
    overflow: hidden;
    th {
      padding: 15px 5px;
    }
  }
  .upcoming_ipos_head {
    color: #fff !important;
    font-weight: 700;
    background: #0466c8;
    border-top-right-radius: 22px;
    border-top-left-radius: 22px;
    text-align: left;
    th {
      color: #fff;
    }
  }
  .upcoming_ipos_body {
    background-color: #fffafa;
  }
  .compony_content {
    display: flex;
    align-items: center;
    gap: 15px;
    img.comp_img {
      height: 45px;
      width: 45px;
      object-fit: contain;
      border-radius: 50%;
      // background: #bcbcbc;
    }
  }
  .bid_date {
    min-width: 170px;
  }
  .table_price {
    min-width: 140px;
  }
  .compony_table_name {
    min-width: 300px;
  }
  .link_click {
    color: #0466c8;
    font-weight: 700;
    a {
      color: #0466c8;
    }
    svg {
      margin-right: 5px;
      fill: #0466c8;
      path,
      polygon {
        fill: #0466c8;
      }
    }
  }
  .search_content {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 30px 0;

    .mainsearch {
      width: 300px;
      height: 40px;
      background: #ffffff;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 12px;
      font-size: 24px;
      line-height: 30px;
      color: rgba(3, 7, 30, 0.5);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @include max-mq(549) {
        width: 250px;
        height: 32px;
      }
    }

    @include max-mq(768) {
      width: 275px;
    }

    @include max-mq(470) {
      margin-top: 20px;
      width: 100%;
    }
    .icon {
      margin: 10px;
      width: 25px !important;
    }
    input {
      border: none;
    }
    ::placeholder {
      font-size: 20px;
      line-height: 30px;
      color: rgba(3, 7, 30, 0.5);
      @include max-mq(549) {
        font-size: 14px;
      }
      @include btw-sz(550, 1149) {
        font-size: 16px;
      }
    }
    input[type="text"] {
      border: none;
      outline: none;
      width: 100%;
      margin-right: 5px;
      font-size: 20px;
      line-height: 30px;
      color: rgba(3, 7, 30, 0.5);
      @include max-mq(549) {
        font-size: 14px;
      }
      @include btw-sz(550, 1149) {
        font-size: 16px;
      }
    }

    // .search_box {
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   border: 1px solid #bcbcbc;
    //   border-radius: 11px;
    //   min-width: 350px;
    //   overflow: hidden;
    //   .search_input_custom {
    //     width: 100%;
    //     padding: 15px;
    //     font-size: 18px;
    //     border: none;
    //     &:focus {
    //       outline: 1px solid #0466c8;
    //     }
    //   }
    //   .icon_view {
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     padding: 15px;
    //     background: #0466c8;
    //     height: 100%;
    //   }
    //   .search_icon {
    //     height: 100%;
    //     font-size: 18px;
    //     height: 25px;
    //     width: 25px;
    //     fill: white;
    //     path {
    //       fill: white;
    //     }
    //   }
    // }
  }
}
.table_link_view {
  min-width: 150px;
}
