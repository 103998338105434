// ----Media query mixin-----------//
@mixin min-mq($min-screen-size) {
  @media (min-width: #{$min-screen-size +"px"}) {
    @content;
  }
}

@mixin max-mq($max-screen-size) {
  @media (max-width: #{$max-screen-size +"px"}) {
    @content;
  }
}

// ----------------------------For Orientation-----------------------//
@mixin landscape-orientation($max-screen-size, $orientation) {
  @media (max-width: #{$max-screen-size +"px"}) and (orientation: $orientation) {
    @content;
  }
}

@mixin btw-mq($min-screen-size, $max-screen-size, $pixel-ratio, $orientation) {
  @media only screen and (min-width: #{$min-screen-size +"px"}) and (max-width: #{$max-screen-size +"px"}) and (-webkit-min-device-pixel-ratio: $pixel-ratio) and (orientation: $orientation) {
    @content;
  }
}
@mixin btw-sz($min-screen-size, $max-screen-size) {
  @media only screen and (min-width: #{$min-screen-size +"px"}) and (max-width: #{$max-screen-size +"px"}) {
    @content;
  }
}
.custom-container-2 {
  @include max-mq(650) {
    padding: 15px;
  }
}
.transaction-right-box {
  padding: 20px 0px 50px;
  .title {
    // display: flex;
    // justify-content: space-between;
    // @include max-mq(470) {
    //     display: block;
    // }
    // @include max-mq(768) {
    //     padding-bottom: 0px;
    // }
    .first {
      .r-b-title {
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
        text-transform: capitalize;
        color: #002733;
        @include max-mq(768) {
          font-size: 20px;
        line-height: 20px;
        }
      }
      .hr1 {
        width: 46px;
        height: 0px;
        opacity: 1;
        margin: 5px;
        margin-left: 0px;
        border-radius: 5px;
        border: 2px solid #0466c8;
      }
    }
    .secound_tra {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
      margin-top: 25px;
      @include max-mq(1300) {
        margin: 25px 0px;
      }
      @include max-mq(768) {
        display: block;
        margin: 15px 0px;
      }
      .search {
        width: 300px;
        height: 45px;
        background: #ffffff;
        box-shadow: 0px 8px 15px rgba(208, 208, 208, 0.25);
        border-radius: 12px;
        font-size: 24px;
        line-height: 30px;
        color: rgba(3, 7, 30, 0.5);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        // margin-top: 35px;
        @include max-mq(768) {
          width: calc(50% - 32px);
        }

        @include max-mq(550) {
          margin: 10px 0px;
          margin-top: 0px;
          width: 100%;
          height: 40px;
          font-size: 18px;
          line-height: 20px;
        }
        .icon {
          margin: 10px;
          width: 25px !important;
        }
        input {
          border: none;
        }
        ::placeholder {
          font-size: 20px;
          line-height: 30px;
          color: #adb5bd;
          @include max-mq(550){
            font-size: 16px;
            line-height: 18px;
          }
        }
        input[type="text"] {
          border: none;
          outline: none;
          width: 82%;
          margin-right: 5px;
          font-size: 18px;
          font-weight: 400;
          line-height: 26px;
          color: #adb5bd;
          @include max-mq(768) {
            font-size: 18px;
          }
        }
      }
      .right_tra {
        display: flex;
        align-items: center;
        gap: 10px;
        @include max-mq(768) {
          width: 100%;
          margin-top: 15px;
        }
        .date-pick {
          width: 150px;
          @include max-mq(768) {
            width: 45%;
          }
        }
        input {
          background: #ffffff;
          box-shadow: 0px 8px 15px rgba(208, 208, 208, 0.25);
          border-radius: 8px;
          border: none;
          outline: none;
          width: 150px;
          height: 40px;
          padding: 10px;
          font-weight: 400;
          font-size: 16px;
          line-height: 23px;
          text-transform: uppercase;
          color: rgba(3, 7, 30, 0.5);
          position: relative;
          @include max-mq(768) {
            width: 100%;
          }
        }
        input[type="date"]::-webkit-calendar-picker-indicator {
          z-index: 1;
          background-image: url("../../../../assets/transaction/datePicker.svg");
          // background-image: url(http://icons.iconarchive.com/icons/dakirby309/simply-styled/256/Calendar-icon.png);
          margin-top: 12px;
          // position: absolute;
          // right: 10px;
          // top: 14px;
          background-size: 13px;
          margin-left: -15px;
          cursor: pointer;
        }
        span {
          font-weight: 400;
          font-size: 20px;
          line-height: 150%;
          color: #adb5bd;
        }
      }
    }
    .tra_bottom {
      // width: 1304px;
      // height: 267px;
      background: #ffffff;
      box-shadow: 0px 8px 15px rgba(208, 208, 208, 0.25);
      border-radius: 30px;
      padding: 0px 25px;
      padding-top: 15px;

      margin-top: 30px;

      @include max-mq(1350) {
        height: 335px;
        // margin-top: 0px;
      }
      @include max-mq(830) {
        margin-top: 20px;
        height: auto;
      }
      @include max-mq(768) {
        // height: 385px;
        padding: 0px 15px;
      }
      @include max-mq(550) {
        margin-top: 15px;
      }

      .tb_top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // height: 115px;
        padding-bottom: 5px;
        border-bottom: 1px solid rgba(173, 181, 189, 0.5);
        @include max-mq(1350) {
          display: block;
          height: max-content;
        }
        .tbt_img {
          display: flex;
          align-items: center;
          gap: 35px;
          height: 100%;
          @include max-mq(1300) {
            gap: 20px;
          }
          @include max-mq(830) {
            gap: 15px;
          }
          @include max-mq(550) {
            gap: 10px;
          }
          img {
            width: 150px;
            height: 90px;
            border-radius: 8px;
            object-fit: contain;
            @include max-mq(1300) {
              width: 150px;
              height: 90px;
            }
            @include max-mq(550) {
              width: 130px;
              height: 75px;
            }
            // object-fit: cover;
            // object-position: top;
          }
          .tbt_name {
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            text-transform: capitalize;
            color: #002733;
            @include max-mq(1300) {
              line-height: 20px;
              font-size: 22px;
            }
            @include max-mq(830) {
              line-height: 20px;
              font-size: 18px;
            }
            @include max-mq(830) {
              line-height: 18px;
              font-size: 16px;
            }
          }
        }
        .tbt_count {
          display: flex;
          // gap: 70px;
          width: 50%;
          justify-content: space-around;
          @include max-mq(1350) {
            margin-bottom: 10px;
            width: 100%;
            justify-content: space-between;
          }
          @include max-mq(1350) {
            display: flex;
            gap: 10px;
          }
          @include max-mq(450) {
            display: block;
          }

          .pps {
            @include max-mq(450) {
              display: flex;
              justify-content: space-between;
            }
            .pps_name {
              font-weight: 400;
              font-size: 14px;
              line-height: 23px;
              text-transform: capitalize;
              color: #adb5bd;
              @include max-mq(768) {
                font-size: 14px;
                line-height: 20px;
              }
              @include max-mq(550) {
                font-size: 12px;
                line-height: 16px;
              }
            }
            .pps_price {
              font-weight: 600;
              font-size: 23px;
              line-height: 30px;
              text-transform: capitalize;
              color: #002733;
              @include max-mq(1350) {
                font-size: 23px;
                line-height: 26px;
              }
              @include max-mq(1350) {
                font-size: 20px;
                line-height: 22px;
              }
              @include max-mq(550) {
                font-weight: 600;
                font-size: 14px;
                line-height: 18px;
              }
            }
          }
        }
      }
      .tb_bottom {
        // padding: 30px 0px;
        padding: 50px 0px;
        display: flex;
        justify-content: space-between;
        @include max-mq(1350) {
          display: block;
          padding-top: 15px;
        }
        @include max-mq(830) {
          padding-bottom: 65px;
        }
        @include max-mq(550) {
          padding-top: 10px;
        }

        .tbbleft {
          display: grid;
          gap: 8px 5px;
          @include max-mq(1350) {
            display: flex;
            justify-content: space-between;
          }
          @include max-mq(768) {
            display: block;
          }
          .tbbl {
            display: flex;
            align-items: center;
            @include max-mq(768) {
              display: flex;
              justify-content: space-between;
            }
            .tbbl_name {
              font-weight: 400;
              font-size: 18px;
              line-height: 15px;
              // text-transform: lowercase;
              color: #adb5bd;
              width: 120px;
              @include max-mq(1350) {
                width: 100%;
                margin-right: 10px;
                font-size: 18px;
                line-height: 15px;
              }
              @include max-mq(768) {
                width: 120px;
                margin-right: 0px;
              }
              @include max-mq(550) {
                font-size: 14px;
                line-height: 18px;
              }
            }
            .tbbl_id {
              font-weight: 400;
              font-size: 18px;
              line-height: 150%;
              // text-transform: capitalize;
              color: #002733;
              @include max-mq(1350) {
                font-size: 18px;
                line-height: 22px;
              }
              @include max-mq(768) {
                font-size: 18px;
                line-height: 22px;
              }
              @include max-mq(550) {
                font-size: 14px;
                line-height: 18px;
              }
            }
          }
        }

        .tbbright {
          width: 50%;
          @include max-mq(1350) {
            margin-top: 20px;
            width: 100%;
            padding-right: 15px;
          }
          @include max-mq(768) {
            padding: 0px;
          }
          @include max-mq(768) {
            margin-top: 15px;
          }
          .stepper-wrapper {
            margin: auto 50px;
            // width: 500px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            @include max-mq(450) {
              margin: auto 30px;
            }
          }

          .stepper-wrapper::before {
            content: "";
            width: 100%;
            height: 3px;
            background-color: lightgray;
            position: absolute;
            z-index: 0;
          }

          .progress {
            content: "";
            width: 0%;
            height: 3px;
            background-color: #008000;
            position: absolute;
            z-index: 1;
            // transition: width 1s;
          }
          .progress1 {
            content: "";
            width: 50%;
            height: 3px;
            background-color: #008000;
            position: absolute;
            z-index: 1;
            // transition: width 1s;
          }
          .progress2 {
            content: "";
            width: 100%;
            height: 3px;
            background-color: #008000;
            position: absolute;
            z-index: 1;
            // transition: width 1s;
          }

          .screen-indicator {
            border-radius: 50%;
            border: 3px solid lightgrey;
            background-color: white;
            height: 30px;
            width: 30px;
            color: gray;
            transition: border-color color;
            // transition-duration: 0.7s;
            text-align: center;
            z-index: 10;
            span {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;
              img {
                height: 18px;
                width: 18px;
              }
            }
          }

          .completed {
            border-color: #008000;
            span {
              display: none;
            }
          }

          .completed::before {
            content: url("../../../../assets/transaction/rightArrow.svg");
          }

          .Failed {
            border-color: #df0009;
            span {
              display: none;
            }
          }

          .Failed::before {
            // content: url("../../../../assets/transaction/rightArrow.svg");
            content: url("../../../../assets/transaction/fail_Position.svg");
          }

          .Canceled {
            border-color: #ffbd00;
          }

          .Canceled span {
            color: #ffbd00;
            height: 14px;
            width: 14px;
            background-color: #ffbd00;
            border-radius: 50%;
            margin-left: 5px;
            margin-top: 5px;
            margin-bottom: 20px;
            display: flex;
            font-size: 0px;
            img {
              display: none;
            }
          }

          .control-btn {
            background-color: rgb(238, 238, 238);
            padding: 5px 10px;
            border: 1px solid gray;
            border-radius: 5px;
            cursor: pointer;
            margin-top: 15px;
          }

          .control-btn:disabled {
            cursor: not-allowed;
          }

          .control-btn:not(:disabled):hover {
            background-color: lightgray;
          }

          .innerContain {
            width: 140px;
            position: relative;
            left: -55px;
            margin-top: 10px;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            text-transform: capitalize;
            color: #adb5bd;
            @include max-mq(768) {
              font-size: 14px;
              width: 115px;
            }
            @include max-mq(768) {
              font-size: 12px;
              width: 115px;
            }
            @include max-mq(768) {
              line-height: 12px;
              margin-top: 15px;
            }
            .text-danger {
              font-size: 14px !important;
            }
          }

          .innerContain2 {
            width: 140px;
            position: relative;
            left: -55px;
            font-weight: 400;
            font-size: 14px;
            // line-height: 22px;
            text-transform: capitalize;
            color: #002733;
            @include max-mq(768) {
              font-size: 14px;
              width: 115px;
            }
          }

          // .transition {
          //     transition-delay: 0.6s;
          // }
        }
      }
    }
  }
}

.my_warn {
  font-size: 14px !important;
}
.bottom-layer {
  margin-top: 15px;
}
#stdate::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
#endate::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
