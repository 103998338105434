// ----Media query mixin-----------//
@mixin min-mq($min-screen-size) {
  @media (min-width: #{$min-screen-size +"px"}) {
    @content;
  }
}

@mixin max-mq($max-screen-size) {
  @media (max-width: #{$max-screen-size +"px"}) {
    @content;
  }
}

// ----------------------------For Orientation-----------------------//
@mixin landscape-orientation($max-screen-size, $orientation) {
  @media (max-width: #{$max-screen-size +"px"}) and (orientation: $orientation) {
    @content;
  }
}

@mixin btw-mq($min-screen-size, $max-screen-size, $pixel-ratio, $orientation) {
  @media only screen and (min-width: #{$min-screen-size +"px"}) and (max-width: #{$max-screen-size +"px"}) and (-webkit-min-device-pixel-ratio: $pixel-ratio) and (orientation: $orientation) {
    @content;
  }
}
@mixin btw-sz($min-screen-size, $max-screen-size) {
  @media only screen and (min-width: #{$min-screen-size +"px"}) and (max-width: #{$max-screen-size +"px"}) {
    @content;
  }
}

.bred-crumb {
  height: 100px;
  background: #002733;
  padding: 10px 0;
}
.custom_bread_title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  // width: calc(100% - 95px);
  width: calc(100% - 118px);
}
.company_bread_title {
  width: calc(100% - 250px) !important;
}
.bread-title {
  margin-bottom: 10px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 27px;
  line-height: 45px;
  text-transform: capitalize;
  color: #ffffff;
  position: relative;
  padding: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @include max-mq(549) {
    font-size: 20px;
  }
  @include btw-sz(1150, 1549) {
    font-size: 27px;
  }
  &::before {
    content: "";
    width: 50px;
    bottom: 0;
    left: 0px;
    height: 5px;
    background-color: white;
    position: absolute;
    @include max-mq(549) {
      height: 3px;
    }
  }
}

.indian-page {
  background: #f4f5f7;
  // .bred-crumb {
  //   height: 150px;
  //   background: #002733;
  //   padding: 10px 0;
  // }
  // .bread-title {
  //   margin-bottom: 10px;
  //   font-family: Source Sans Pro;
  //   font-style: normal;
  //   font-weight: 600;
  //   font-size: 36px;
  //   line-height: 45px;
  //   text-transform: capitalize;
  //   color: #ffffff;
  //   position: relative;
  //   padding: 15px 0;
  //   &::before {
  //     content: "";
  //     width: 50px;
  //     bottom: 0;
  //     left: 0;
  //     height: 5px;
  //     background-color: white;
  //     position: absolute;
  //   }
  // }
  // .bread-content {
  //   .breads {
  //     color: white;
  //   }
  // }

  .trending-block {
    .trending-title {
      margin-bottom: 10px;
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 45px;
      text-transform: capitalize;
      color: #002733;
      position: relative;
      padding: 15px 0;
      &::before {
        content: "";
        width: 50px;
        bottom: 0;
        left: 0;
        height: 5px;
        background-color: #0466c8;
        position: absolute;
      }
    }
  }
  .label {
    padding: 2.5px 15px;
    top: 10px;
    right: 10px;
    position: absolute;
    background: rgba(0, 128, 0, 0.1);
    border-radius: 19px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    text-transform: capitalize;
    color: #008000;
  }

  .slide-content-box {
    height: 200px;
    width: 200px;
    background: #ffffff;
    border: 3px solid #002733;
    box-sizing: border-box;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05);
    border-radius: 11px;
    padding: 15px;
    text-align: center;
    position: relative;
    img.slider_img {
      max-width: 135px;
      max-height: 60px;
      object-fit: cover;
      margin-top: 20px;
      margin-bottom: 10px;
    }
    .desc-content {
      .grid-content {
        display: grid;
        grid-template-columns: auto auto;
        justify-content: space-around;
        margin-top: 10px;
      }
      .price,
      .sector {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 23px;
        text-align: left;
        text-transform: capitalize;
        color: #b1b3c2;
        position: relative;
        &::before {
          content: "";
          height: 1px;
          width: 20px;
          background-color: #b1b3c2;
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }
      .price-content,
      .sector-content {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 25px;
        text-transform: capitalize;
        color: #002733;
      }
    }
  }

  .trending-block {
    background-color: #f8fbff;
  }
  .indian-companies {
    background-color: #f8fbff;
    width: 1000px;
    .company-title {
      margin-bottom: 10px;
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 45px;
      text-transform: capitalize;
      color: #002733;
      position: relative;
      padding: 15px 0;
      &::before {
        content: "";
        width: 50px;
        bottom: 0;
        left: 0;
        height: 5px;
        background-color: #0466c8;
        position: absolute;
      }
    }
  }
}

.filter {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  width: 280px;
  // width: 314px;
  height: auto;
  left: 150px;
  top: 361px;
  padding: 27px 20px;
  // background: #f8fbff;
  background: #ffffff;
  border-radius: 22px;
  .f_title {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 45px;
    text-transform: capitalize;
    // @include max-mq(1549) {
    //   font-size: 27px;
    //   line-height: 28px;
    // }

    color: #002733;
    // border-bottom: 5px solid #0466c8;
  }
  .filterHr1 {
    width: 80px;
    height: 0px;
    border-radius: 5px;
    border: 2px solid #0466c8;
    opacity: 1;
    margin-top: 5px;
  }
  .f-sector {
    .f-s-title {
      font-weight: 600;
      font-size: 20px;
      text-transform: capitalize;
      color: #002733;
      border-bottom: 2px solid #0466c8;
      padding-bottom: 5px;
      margin-bottom: 16px;
      line-height: 24px;
      margin-top: 32px;
    }
    // .filterHr2 {
    //   width: 100%;
    //   height: 0px;
    //   border-radius: 5px;
    //   border: 1px solid #0466c8;
    //   opacity: 1;
    //   margin-top: 5px;
    // }
    .filterrr {
      display: flex;
      label {
        cursor: pointer;
        font-size: 18px;
      }
    }
    .sector-con {
      font-weight: normal;
      font-size: 24px;
      line-height: 34px;
      text-transform: capitalize;
      color: #002733;
      @include max-mq(1300) {
        font-size: 20px;
        line-height: 24px;
      }
      input[type="checkbox"] {
        margin: 10px;
        @include max-mq(1300) {
          margin: 10px;
          margin-top: 7px;
        }
        // width: 33px;
      }
      // label{
      //   border: 1px solid red;
      // }
    }
  }
  .r-section {
    margin-top: 25px;
    @include max-mq(1300) {
      margin-top: 10px;
    }
    .r-title {
      font-weight: 600;
      font-size: 20px;
      // line-height: 30px;
      text-transform: capitalize;
      color: #002733;
      margin-top: 32px;
      border-bottom: 2px solid #0466c8;
      padding-bottom: 5px;
      margin-bottom: 16px;
      line-height: 24px;
      // @include max-mq(1549) {
      //   font-size: 22px;
      // }
      // border-bottom: 2px solid #0466c8;
    }
    // .filterHr3 {
    //   width: 100%;
    //   height: 0px;
    //   border-radius: 5px;
    //   border: 1px solid #0466c8;
    //   opacity: 1;
    //   margin-top: 5px;
    //   @include max-mq(1300) {
    //     margin: 5px;
    //   }
    // }
    .r-radio {
      font-size: 24px;
      // line-height: 200%;
      text-transform: capitalize;
      color: #002733;
      @include max-mq(1300) {
        font-size: 20px;
        line-height: 24px;
      }
      input[type="radio"] {
        margin: 10px;
        // @include max-mq(1300){
        //   margin: 9px;
        // }
      }
      label {
        line-height: 24px;
        font-size: 18px;
        cursor: pointer;
      }
    }
  }
  .price {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-transform: capitalize;
    margin-top: 20px;
    .p-title {
      border-bottom: 2px solid #0466c8;
      padding-bottom: 5px;
      margin-bottom: 16px;
    }
    // border-bottom: 2px solid #0466c8;
    .price_bugs {
      margin: 10px 15px;
      margin-top: 30px;
      width: 82%;
    }
    color: #002733;
    // .filterHr4 {
    //   width: 100%;
    //   height: 0px;
    //   border-radius: 5px;
    //   border: 1px solid #0466c8;
    //   opacity: 1;
    //   margin-top: 5px;
    //   margin-bottom: 20px;
    // }
  }
  .css-eg0mwd-MuiSlider-thumb::after {
    width: 25px;
    height: 25px;
    background: #ffffff;
    box-shadow: 0px 1px 3px rgba(0, 198, 255, 0.3);
  }
  .mybtn {
    display: flex;
    gap: 15px;
    // justify-content: space-between;
    margin: 20px 10px;
    .update {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 2px 20px;
      width: 103px;
      height: 38px;
      background: #0466c8;
      border-radius: 6px;
      color: #fff;
      border: none;
    }
    .reset {
      width: 103px;
      height: 38px;
      border: 2px solid #0466c8;
      background: #f8fbff;
      border-radius: 6px;
    }
  }
}

.mob-filter {
  width: 100%;
  // height: calc(100vh - 140px);
  height: calc(100vh - 0px);
  left: 150px;
  top: 361px;
  padding: 15px;
  background: #f8fbff;
  overflow-y: scroll;
  overflow-x: hidden;
  // border-radius: 22px;
  .f_title {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 45px;
    text-transform: capitalize;

    color: #002733;
    // border-bottom: 5px solid #0466c8;
    @include max-mq(1300) {
      font-size: 24px;
      line-height: 30px;
    }
  }
  .filterHrM1 {
    width: 80px;
    height: 0px;
    border-radius: 5px;
    border: 2px solid #0466c8;
    opacity: 1;
    margin-top: 5px;
  }
  .f-sector {
    .f-s-title {
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      text-transform: capitalize;
      color: #002733;
      // border-bottom: 2px solid #0466c8;
      margin-top: 10px;
      @include max-mq(1100) {
        margin-top: 0px;
        font-size: 18px;
        line-height: 22px;
      }
    }
    .filterHrM2 {
      width: 100%;
      height: 0px;
      border-radius: 5px;
      border: 1px solid #0466c8;
      opacity: 1;
      margin-top: 5px;
      margin-bottom: 10px;
    }
    .filterrr {
      display: flex;
      align-items: center;
      label {
        cursor: pointer;
      }
    }
    .sector-con {
      font-weight: normal;
      font-size: 24px;
      line-height: 200%;
      text-transform: capitalize;
      color: #002733;
      @include max-mq(1100) {
        font-size: 16px;
        line-height: 100%;
      }
      input[type="checkbox"] {
        margin: 10px;
        width: 13px;
        @include max-mq(1300) {
          margin: 7px;
        }
      }
    }
  }
  .r-section {
    margin-top: 5px;
    .r-title {
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      text-transform: capitalize;
      color: #002733;
      // border-bottom: 2px solid #0466c8;
      @include max-mq(1100) {
        font-size: 18px;
      }
    }
    .filterHrM3 {
      width: 100%;
      height: 0px;
      border-radius: 5px;
      border: 1px solid #0466c8;
      opacity: 1;
      margin-top: 5px;
      margin-bottom: 10px;
    }
    .r-radio {
      font-size: 24px;
      // line-height: 200%;
      text-transform: capitalize;
      color: #002733;
      @include max-mq(1100) {
        font-size: 16px;
        // line-height: 100%;
      }
      input[type="radio"] {
        margin: 10px;
        @include max-mq(1300) {
          margin: 9px;
        }
      }
    }
  }
  // .price {
  //   font-weight: 600;
  //   font-size: 24px;
  //   line-height: 30px;
  //   text-transform: capitalize;
  //   border-bottom: 2px solid #0466c8;
  //   color: #002733;
  //   @include max-mq(1100) {
  //     font-size: 18px;
  //   }
  // }
  .price {
    font-weight: 600;
    line-height: 30px;
    text-transform: capitalize;
    // border-bottom: 2px solid #0466c8;
    color: #002733;
    font-size: 22px;
    margin-top: 32px;
    @include max-mq(1549) {
      font-size: 18px;
    }
    .filterHrM4 {
      width: 100%;
      height: 0px;
      border-radius: 5px;
      border: 1px solid #0466c8;
      opacity: 1;
      margin-top: 5px;
      margin-bottom: 20px;
    }
  }
  .css-187mznn-MuiSlider-root {
    width: 97%;
    @include max-mq(549) {
      width: 92%;
    }
  }
  .css-eg0mwd-MuiSlider-thumb::after {
    width: 25px;
    height: 25px;
    background: #ffffff;
    box-shadow: 0px 1px 3px rgba(0, 198, 255, 0.3);
  }
  .mybtn {
    display: flex;
    // justify-content: space-between;
    gap: 15px;
    margin: 10px 0px;
    .update {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 2px 20px;
      width: 103px;
      height: 38px;
      background: #0466c8;
      border-radius: 6px;
      color: #fff;
      border: none;
    }
    .reset {
      width: 103px;
      height: 38px;
      border: 2px solid #0466c8;
      background: #f8fbff;
      border-radius: 6px;
    }
  }
}

.my-contain {
  display: flex;
  gap: 46px;
  padding-top: 50px;
  @include max-mq(1300) {
    padding: 0px;
    padding-top: 40px;
  }
  @include max-mq(1100) {
    gap: 0px;
  }
  .right {
    width: 100%;
    height: 100%;
    @include btw-sz(1100, 1450) {
      width: calc(100% - 280px);
      height: 100%;
    }
  }
}
.right-box {
  // background: #f8fbff;
  background: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 22px;
  margin-bottom: 20px;
  .title {
    display: flex;
    justify-content: space-between;
    padding: 27px 20px;
    // @include max-mq(1300) {
    //   padding: 10px;
    // }
    @include max-mq(768) {
      padding-bottom: 0px;
    }
    .slide-filter button {
      width: 75px;
      height: 38px;
      background: #0466c8;
      border-radius: 6px;
      color: #fff;
      outline: none;
      border: none;
    }
    .first {
      .r-b-title {
        font-weight: 600;
        font-size: 27px;
        line-height: 45px;
        text-transform: capitalize;
        color: #002733;
        @include btw-sz(550, 1149) {
          font-size: 23px;
          line-height: 28px;
        }
        @include btw-sz(1150, 1500) {
          font-size: 27px;
          line-height: 28px;
        }
        @include max-mq(768) {
          font-size: 20px;
          line-height: 24px;
        }
      }
      .hr1 {
        color: #0466c8;
        height: 3px;
        opacity: 1;
        width: 70px;
        margin: 10px 0px;
        margin-bottom: 10px;
      }
    }
    .search {
      display: flex;
      gap: 10px;
      .mainsearch {
        width: 300px;
        height: 40px;
        background: #ffffff;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        border-radius: 12px;
        font-size: 24px;
        line-height: 30px;
        color: rgba(3, 7, 30, 0.5);
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      @include max-mq(600) {
        width: 100% !important;
      }
      @include max-mq(768) {
        width: 300px;
      }

      @include max-mq(470) {
        margin-top: 20px;
        width: 100%;
      }
      .icon {
        margin: 10px;
        width: 25px !important;
      }
      input {
        border: none;
      }
      ::placeholder {
        font-size: 20px;
        line-height: 30px;
        color: rgba(3, 7, 30, 0.5);
      }
      input[type="text"] {
        border: none;
        outline: none;
        width: 100%;
        margin-right: 5px;
        font-size: 18px;
        line-height: 26px;
        color: rgba(3, 7, 30, 0.5);
      }
    }
  }
  .title-2 {
    @include max-mq(600) {
      display: block;
    }
  }
  .fixHeight {
    // height: 233px;
    overflow: hidden;
    overflow-x: auto;
    grid-auto-flow: column;
    padding-bottom: 36px !important;
  }
  .copy_paste {
    display: flex;
    grid-gap: 35px;
    padding: 20px !important;
    @include max-mq(1300) {
      padding: 10px;
      grid-gap: 25px;
    }
    @include max-mq(549) {
      padding: 20px !important;
      gap: 20px;
    }
    .card-view {
      // width: 201px;
      cursor: pointer;
      background: #ffffff;
      // box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 0px 5px rgb(0 0 0 / 27%);
      cursor: pointer;
      border-radius: 11px;
      padding: 20px;
      @include max-mq(1300) {
        padding: 10px;
      }
      &:hover {
        box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
          rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
          rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
      }
      .labal_change {
        display: flex;
        justify-content: right;
        margin-bottom: 8px;
        @include max-mq(1300) {
          margin-bottom: 8px;
        }

        .card-labal {
          width: 70px;
          height: 18px;
          border-radius: 19px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 10px;
          line-height: 15px;
          text-transform: capitalize;
        }
        .card-labal-1 {
          background: rgba(0, 128, 0, 0.1);
          color: #008000;
        }
        .card-labal-2 {
          background: rgba(208, 0, 0, 0.1);
          color: #d00000;
        }
        .card-labal-3 {
          background: rgba(255, 189, 0, 0.1);
          color: #b78700;
        }
      }
      img.card_img {
        width: 150px;
        margin-bottom: 10px;
        @include max-mq(1300) {
          margin-bottom: 10px;
        }
      }
      .img_card_content {
        text-align: center;
      }
      .card_img {
        // width: 136px;
        // height: 30px;
        // object-fit: cover;
        object-fit: contain;
        width: 164px;
        height: 100px;
        @include max-mq(1300) {
          height: 80px;
        }
      }

      .card_details {
        display: flex;
        // justify-content: space-between;
        .card_price {
          font-size: 18px;
          line-height: 23px;
          text-transform: capitalize;
          color: #b1b3c2;
          width: 48px;
          @include max-mq(549) {
            font-size: 16px;
          }
          @include max-mq(1149) {
            line-height: 18px;
          }
          hr.class_hr {
            width: 20px;
            margin: 5px;
            margin-left: 0px;
          }
        }
        .pprice {
          font-size: 18px;
          line-height: 23px;
          text-transform: capitalize;
          color: #002733;
          // white-space: nowrap;
          // overflow: hidden;
          // margin-left: 5px;
          // text-overflow: ellipsis;
        }
      }
      .card_details_sector {
        display: block !important;
      }
      // .card_details {
      //   display: flex;
      //   justify-content: space-between;
      //   .card_price {
      //     font-size: 18px;
      //     line-height: 23px;
      //     text-transform: capitalize;
      //     color: #b1b3c2;
      //     hr.class_hr {
      //       width: 20px;
      //       margin: 5px;
      //       margin-left: 0px;
      //     }
      //   }
      //   .pprice {
      //     font-size: 20px;
      //     line-height: 25px;
      //     text-transform: capitalize;
      //     color: #002733;
      //   }
      // }
    }
    .card-view-2 {
      border-radius: 50%;
      // height: 140px;
      // width: 140px;
      height: 110px;
      width: 110px;
      display: flex;
      align-items: center;
      justify-content: center;
      // .labal_change {
      //   display: flex;
      //   justify-content: center;
      @include max-mq(549) {
        border-radius: 50%;
        height: 115px;
        width: 115px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      // }
      img {
        margin-bottom: 0px !important;
        margin-top: 0px;
      }
    }
  }
  .copy_paste-2 {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(249px, 1fr));
    @include max-mq(768) {
      // display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      grid-gap: 15px;
      padding: 10px;
    }

    @include btw-sz(1150, 1549) {
      grid-template-columns: repeat(auto-fill, minmax(188px, 1fr));
    }
  }
}
.my-right-boxx {
  background-color: transparent;
  box-shadow: none;
}
.right-box-222 {
  @include max-mq(550) {
    margin: 0px 15px;
    margin-top: -20px;
  }
}
.notes {
  display: flex;
  margin-top: 10px;
  padding-bottom: 85px;
  @include max-mq(1300) {
    padding-bottom: 35px;
  }
  @include max-mq(768) {
    display: block;
    padding-bottom: 35px;
  }
  h1 {
    font-weight: 600;
    font-size: 30px;
    line-height: 45px;
    text-transform: capitalize;
    color: #002733;
    margin-right: 15px;
    @include btw-sz(1150, 1549) {
      font-size: 27px;
    }
    @include max-mq(549) {
      font-size: 20px;
      line-height: 32px;
    }
    @include btw-sz(550, 1149) {
      font-size: 23px;
    }
  }
  p {
    font-size: 14px;
    line-height: 23px;
    text-transform: capitalize;
    color: #002733;
    text-align: justify;
    @include max-mq(768) {
      font-size: 14px;
      line-height: 18px;
    }
  }
}

@media only screen and (max-width: 1100px) and (min-width: 200px) {
  .filter {
    display: none;
  }
}

@media only screen and (max-width: 3000px) and (min-width: 1101px) {
  .slide-filter {
    display: none;
  }
}

// @media only screen and (max-width: 768px) and (min-width: 110px) {
//   .r-b-title{
//     font-size: 24px;
//     line-height: 28px;
//   }
// }
.card_img_2 {
  // width: 136px;
  padding: 3px;
  width: 100px;
  height: 60px;
  object-fit: contain;
  // object-fit: contain;
  // width: 164px;
  // height: 100px;
}

::-webkit-scrollbar {
  height: 5px;
}
.fixHeight::-webkit-scrollbar-track {
  height: 1px;
  border-radius: 46px;
  background: rgba(
    173,
    181,
    189,
    0.5
  ) !important; /* color of the tracking area */
}
.fixHeight::-webkit-scrollbar-thumb {
  background-color: #0466c8 !important; /* color of the scroll thumb */
  border-radius: 46px !important; /* roundness of the scroll thumb */
}
