@mixin min-mq($min) {
  @media only screen and (min-width: #{ $min + 'px'}) {
    @content;
  }
}
@mixin max-mq($max-width) {
  @media only screen and (max-width: #{ $max-width + 'px'}) {
    @content;
  }
}
@mixin btw-sz($min-width, $max-width) {
  @media only screen and (min-width: #{ $min-width + 'px'}) and (max-width: #{$max-width + "px" }) {
    @content;
  }
}
// .resp-img {
//   @include max-mq(1199) {
//     display: none;
//   }
// }

// .center-form {
//   min-width: 800px;
// }

// .form-fields {
//   min-width: 300px;
// }

// input::-webkit-outer-spin-button,
// input::-webkit-inner-spin-button {
//   -webkit-appearance: none;
//   margin: 0;
// }

// input[type="number"] {
//   -moz-appearance: textfield;
// }

.auth_div {
  .inner-content {
    // overflow: hidden;
    @include max-mq(1199) {
      overflow-y: auto;
    }
    .left_content {
      height: 100vh;
      background: #ebedef;
      overflow-y: auto;
      @include max-mq(1199) {
        min-height: 700px;
        height: 100vh;
      }
      .or_form {
        height: 100%;
      }
      .form-content {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .form_body {
          background: white;
          height: min-content;
          min-height: min-content;
          max-height: min-content;
          max-width: 500px;
          width: 100%;
          border-radius: 7px;
          padding: 30px;
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

          @include max-mq(549) {
            min-height: 550px;

            width: 100%;
            margin: 30px;
            max-width: 100%;
          }
          @include btw-sz(550, 750) {
            width: 100%;
            margin: 30px;
          }
          .form_header {
            font-size: 23px;
            // padding: 15px;
            font-weight: 700;
            margin: 30px 0;
            @include max-mq(549) {
              margin: 15px 0;
            }
          }
          .input_div {
            display: flex;
            flex-direction: column;
            gap: 15px;
            margin-bottom: 15px;
            label {
              font-size: 24px;
              font-weight: 500;
              color: #808080c7;
              @include max-mq(549) {
                font-size: 18px;
                font-weight: 600;
              }
              @include btw-sz(550, 750) {
                font-size: 20px;
              }
            }
            input.custom_control {
              width: 100%;
              border: 0.5px solid #e5e5e5;
              padding: 10px;
              border-radius: 8px;
              font-size: 14px;
              letter-spacing: 1.5px;
              font-weight: 500;
              background: #e7edf6;
              @include btw-sz(550, 750) {
                padding: 7px 12px;
              }
              @include max-mq(549) {
                font-size: 14px;
                padding: 7px 15px;
              }
              &::placeholder {
                font-weight: 600;
                color: gray;
              }
              &:focus {
                outline: 1px solid #6a96f0;
              }
            }
          }
          .register_here {
            color: rgb(86, 138, 251);
          }
          .login_btn {
            width: 100%;
            padding: 7px 15px;
            font-size: 18px;
            font-weight: 600;
            outline: none;
            border: none;
            border-radius: 7px;
            color: white;
            background-color: #3b76ef;
            @include btw-sz(550, 750) {
              padding: 7px 15px;
              font-size: 18px;
            }
            @include max-mq(549) {
              padding: 7px 15px;
              font-size: 14px;
            }
            &:disabled {
              background-color: #6a96f0;
            }
          }
        }
      }
    }
    .right_content {
      @include max-mq(1200) {
        display: none;
      }
      .view_right_content {
        height: 100vh;
        background-color: #bcbcbc;
        position: relative;
        overflow: hidden;
        // img.login_img {
        //   position: absolute;
        //   width: 100%;
        //   height: 100%;
        //   object-fit: cover;
        //   filter: brightness(0.3);
        //   z-index: 9;
        //   &::before {
        //     content: "";
        //     background: rgba(88, 84, 84, 0.2);
        //     width: 100%;
        //     height: 100%;
        //     z-index: 99;
        //   }
        // }
        .txt-right-content {
          color: white;
          z-index: 9999;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 45px;
          font-weight: 700;
          text-transform: capitalize;
          font-style: italic;
          max-width: 560px;
          width: 100%;
        }
      }
    }
    .go_back {
      display: flex;
      align-items: center;
      gap: 15px;
      svg {
        transform: rotate(90deg);
      }
    }
  }
}

img.login_img {
  width: 100%;
  animation: animation-movetop infinite 30s linear;
}
@keyframes animation-movetop {
  0% {
    transform: translateY(0);
  }
  100% {
    // transform: translateY(-1000px);
    transform: translateY(-1020px);
  }
}

.link_text {
  color: #568afb;
  text-decoration: underline !important;
}
.drop_group {
  display: flex;
  width: 100%;
  background: #e7edf6;
  border: 0.5px solid #e5e5e5;
  border-radius: 8px;
  .country_flag {
    width: 25px;
    object-fit: contain;
  }
}
.mobile_country_group {
  display: flex;
}
.country_drop_view {
  width: min-content;
  .drop_country_btn {
    color: black !important;
    box-shadow: none !important;
    background-color: transparent !important;
    outline: none !important;
    border: none !important;
    background: transparent;
    width: 100px;
    &::after {
      right: 20px;
      position: absolute;
      top: 50%;
      content: "";
      margin-top: -2px;
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
    }
    // color: black;
    input {
      outline: none !important;
      border: none !important;
    }
  }
}
.country-list {
  display: flex;
  gap: 5px;
}
.register_row {
  height: 100vh;
  overflow: hidden;
  .left_content {
    // min-height: 1000px !important;
    .or_form {
      min-height: 1000px;
    }
  }
}
