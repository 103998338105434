@mixin min-mq($min) {
  @media only screen and (min-width: #{ $min + 'px'}) {
   @content;
  }
}
@mixin max-mq($max-width) {
  @media only screen and (max-width: #{ $max-width + 'px'}) {
   @content;
  }
}
@mixin btw-sz($min-width, $max-width) {
  @media only screen and (min-width: #{ $min-width + 'px'}) and (max-width: #{$max-width + "px" }) {
   @content;
  }
}
.resp-img{
  @include max-mq(1199){
    display: none;
  }
}

.center-form {
  min-width: 800px;
  @include max-mq(800){
    min-width: 100% !important;
  }
}

.form-fields{
  min-width: 300px;
}
