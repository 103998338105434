@mixin min-width($min-width) {
  @media only screen and (min-width: #{$min-width + "px"}) {
    @content;
  }
}
@mixin max-width($max-width) {
  @media only screen and (max-width: #{$max-width + "px"}) {
    @content;
  }
}
@mixin btw-sz($min-width, $max-width) {
  @media only screen and (min-width: #{$min-width + "px"}) and (max-width: #{$max-width + "px"}) {
    @content;
  }
}

.f-img {
  width: 185px;
  height: 35px;
  object-fit: cover;
}

.footer {
  background: #002733 !important;
  padding: 15px;
  .main-content {
    margin-top: 50px;
    .grid-view {
      color: white;
      .logo {
        margin: 15px 0;
      }
      .grid-content {
        display: grid;
        gap: 125px;
        // grid-template-columns: 490px 260px 170px 340px;
        grid-template-columns: auto auto auto auto;
        margin-bottom: 60px;
        @include max-width(550) {
          grid-template-columns: repeat(1, 100%);
          gap: 15px;
        }
        @include btw-sz(550, 1199) {
          grid-template-columns: repeat(1, 100%);
          gap: 30px;
        }
        @include btw-sz(1200, 1550) {
          gap: 60px;
        }
        .txt-content {
          padding-top: 40px;
          font-family: "Source Sans Pro";
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 23px;
          text-align: justify;
          text-transform: capitalize;
          color: #ffffff;
          @include max-width(549) {
            padding-top: 10px;
          }
          @include btw-sz(1150, 1549) {
            font-size: 14px;
          }
          @include max-width(1149) {
            font-size: 14px;
          }
        }
        .title {
          font-family: Source Sans Pro;
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 30px;
          @include btw-sz(1150, 1549) {
            font-size: 20px;
          }
          @include max-width(1149) {
            font-size: 16px;
          }
        }
        .contact-content {
          .view-content {
            .grid-links {
              margin: 15px 0;
              display: grid;
              grid-template-columns: 20px auto;
              gap: 15px;
              align-items: start;
              .txt {
                width: 200px;
                font-family: "Source Sans Pro";
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 23px;

                color: #ffffff;
                a {
                  color: white;
                  text-decoration: none;
                }
                @include btw-sz(1150, 1549) {
                  font-size: 14px;
                }
                @include max-width(1149) {
                  font-size: 14px;
                }
              }
            }
          }
        }
        .quick-content {
          .view-links {
            list-style: none;
            margin: 0 !important;
            padding: 10px 0 !important;
            @include btw-sz(1150, 1549) {
              padding: 5px 0 !important;
            }
            li {
              padding: 5px 0;
              font-family: "Source Sans Pro";
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 23px;
              color: #ffffff;
              @include btw-sz(1150, 1549) {
                // font-size: 16px;
                font-size: 14px;
              }
              @include max-width(549) {
                font-size: 14px;
              }
              @include btw-sz(550, 1199) {
                font-size: 14px;
              }
            }
          }
        }
        .newsletter-content {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          gap: 15px;
          max-width: 322px;
          @include max-width(549) {
            width: 100%;
            gap: 15px;
          }
          @include btw-sz(550, 1149) {
            gap: 30px;
            width: 100%;
          }
          .email-input {
            outline: none;
            border: none;
            border-bottom: 1px solid #ffffff;
            background: transparent;
            color: white;
            &::placeholder {
              font-family: Open Sans;
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 172.7%;
              text-align: justify;
              color: #ffffff;
              @include max-width(549) {
                font-size: 14px;
              }
              @include btw-sz(550, 1149) {
                font-size: 16px;
              }
            }
          }
          .subscribe {
            background: #ffffff;
            border-radius: 97px;
            padding: 8px;
            font-family: Open Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 25px;
            color: #002733;
            border: none;
            outline: none;
            @include max-width(549) {
              font-size: 14px;
            }
            @include btw-sz(550, 1149) {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
.img-bottom-content {
  gap: 15px;
}

.bottom-footer {
  // font-family: Open Sans;
  // font-style: normal;
  // font-weight: 600;
  // font-size: 20px;
  // line-height: 27px;
  // color: #adb5bd;
  padding: 15px 0;
  .bottom-link {
    position: relative;
    padding: 5px 15px;
    margin: 15px 0;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: #adb5bd;

    a {
      text-decoration: none;
      color: #adb5bd;
    }
    @include btw-sz(1150, 1549) {
      font-size: 16px;
    }
    @include max-width(1149) {
      line-height: 22px;
    }

    @include max-width(549) {
      padding: 5px;
      font-size: 12px;
      margin: 5px 0;
      text-align: center;
    }
    &::after {
      content: "";
      position: absolute;
      width: 2px;
      height: 100%;
      right: 0;
      top: 0;
      background: rgba(196, 196, 196, 0.7);
      @include max-width(549) {
        display: none;
      }
    }
    .copy-links {
      @include btw-sz(1150, 1549) {
        margin: 0 !important;
      }

      @include max-width(549) {
        // flex-direction: column;
        // gap: 5px;
        text-align: center;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: start;
      }
    }

    &:nth-last-child(1)::after {
      display: none;
    }
  }
}
.copy-links {
  margin: 15px;
  @include max-width(1149) {
    margin: 7px 15px;
  }
  .bottom-link {
    @include btw-sz(550, 1199) {
      font-size: 16px;
    }
    // @include max-width(549) {
    // }
  }
}
.custom_footer_view {
  padding: 15px;
}
.bottom-flex {
  @include max-width(549) {
    flex-direction: column;
    gap: 15px;
  }
  .copy-txt {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: #adb5bd;
    @include btw-sz(550, 1199) {
      font-size: 16px;
    }
    @include max-width(549) {
      font-size: 12px;
    }
    @include btw-sz(1150, 1549) {
      font-size: 16px;
    }
  }
}
.svg_icons {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-top: 30px;
  @include btw-sz(1150, 1549) {
    gap: 10px;
  }
  @include max-width(549) {
    gap: 15px;
  }
  img {
    width: 130px;
    height: 40px;
    cursor: pointer;
    @include btw-sz(1150, 1549) {
      width: 130px;
      height: 40px;
    }
    @include btw-sz(1550, 1900) {
      width: 130px;
      height: 40px;
    }
  }
}
.copy-links {
  @include btw-sz(1150, 1549) {
    margin: 0 !important;
  }
}

.email-content {
  @include btw-sz(1150, 1549) {
    font-size: 14px;
  }
}

.link_style {
  text-decoration: none;
  color: white;
  &:active {
    color: white;
  }
  &:visited {
    color: white;
  }
}
