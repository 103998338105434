.docGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  margin-top: 15px;

  .docImage {
    position: relative;
    margin-top: 20px;
    label {
      display: block;
      margin-bottom: 10px;
    }
    img {
      object-fit: cover;
      height: 250px;
      width: 250px;
      border-radius: 8px;
      margin-bottom: 10px;
      // @include max-mq(550) {
      //     height: 200px;
      // }
    }
    // input {
    //     height: 250px;
    //     width: 100%;
    //     position: absolute;
    //     border: 1px solid red;
    //     left: 0;
    //     opacity: 0;
    // }
  }
}
