.sidebar-view-2 {
  width: 300px;
  height: 100vh;
  position: fixed;
  background-color: #fff;
  .logo {
    margin: 25px;
    padding-bottom: 50px;
    img {
      width: 240px;
      height: 36px;
      object-fit: cover;
      cursor: pointer;
    }
  }
  .sidebar-nav-view {
    .sidebar-nav {
      display: flex;
      justify-content: center;
      margin: 0px auto;
      padding: 5px 0px;
      width: 80%;
    }
    .sidebar-item {
      padding: 5px 15px;
      color: #002733;
      font-size: 16px;
      line-height: 23px;
      font-weight: 400;
      text-transform: capitalize;
      text-decoration: none;

      svg {
        margin-right: 15px;
      }
    }
    .active {
      background: rgba(4, 102, 200, 0.08);
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      color: #0466c8;
      // padding-bottom: 12px;
      svg path {
        fill: #0466c8;
      }
      .testsvg {
        path {
          stroke: #0466c8;
          fill: none;
        }
        circle {
          stroke: #0466c8;
        }
      }
      &:focus {
        border-left: 11px solid #0466c8;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
      &:active {
        border-left: 11px solid #0466c8;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
      &.active {
        border-left: 11px solid #0466c8;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
    }
  }

  .sidebar-social {
    position: fixed;
    bottom: 40px;
    left: 60px;
    display: flex;
    gap: 15px;
    img {
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 1100px) and (min-width: 200px) {
  .sidebar-view-2 {
    display: none;
    width: 0px;
  }
}
