// ----Media query mixin-----------//
@mixin min-mq($min-screen-size) {
  @media (min-width: #{$min-screen-size +"px"}) {
    @content;
  }
}

@mixin max-mq($max-screen-size) {
  @media (max-width: #{$max-screen-size +"px"}) {
    @content;
  }
}

// ----------------------------For Orientation-----------------------//
@mixin landscape-orientation($max-screen-size, $orientation) {
  @media (max-width: #{$max-screen-size +"px"}) and (orientation: $orientation) {
    @content;
  }
}

@mixin btw-mq($min-screen-size, $max-screen-size, $pixel-ratio, $orientation) {
  @media only screen and (min-width: #{$min-screen-size +"px"}) and (max-width: #{$max-screen-size +"px"}) and (-webkit-min-device-pixel-ratio: $pixel-ratio) and (orientation: $orientation) {
    @content;
  }
}
@mixin btw-sz($min-screen-size, $max-screen-size) {
  @media only screen and (min-width: #{$min-screen-size +"px"}) and (max-width: #{$max-screen-size +"px"}) {
    @content;
  }
}

.custom-container {
  padding: 40px 80px;
  // margin-top: 15px;
  // padding: 25px;
  overflow-y: auto;
  height: calc(100vh - 95px);
  // background: #f9f9f9;
  background: #f4f5f7;

  border: 1px solid #ececec;
  box-sizing: border-box;
  border-radius: 38px 0px 0px 38px;
  @include max-mq(1100) {
    border-radius: 38px 38px 0px 0px;
  }
  @include max-mq(1549) {
    padding: 40px 80px;
  }
  @include max-mq(550) {
    padding: 15px;
  }

  .poTop {
    // display: flex;
    // justify-content: space-between;
    // margin-top: 15px;
    margin-bottom: 35px;
    @include max-mq(1300) {
      margin-bottom: 20px;
    }
    @include max-mq(600) {
      display: block;
      margin-bottom: 20px;
      padding-top: 20px;
    }
    .poLeft {
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      text-transform: capitalize;
      color: #002733;
      margin-bottom: 25px;
      @include max-mq(768){
        font-size: 20px;
        line-height: 20px;
        margin-bottom: 15px;
      }
      .poHr {
        width: 46px;
        height: 0px;
        opacity: 1;
        margin: 5px;
        margin-bottom: 20px;
        margin-left: 0px;
        border-radius: 5px;
        border: 2px solid #0466c8;
      }
    }
    .poRight {
      width: 100%;
      max-width: 250px;
      height: 226px;
      background: #0466c8;
      box-shadow: 0px 8px 35px rgba(4, 102, 200, 0.25);
      border-radius: 31px;
      overflow: hidden;
      position: relative;
      @include max-mq(1300) {
        width: 333px;
        height: 205px;
      }
      @include max-mq(550) {
        margin-top: 20px;
        width: 100%;
        height: 175px;
      }
      // @include max-mq(350){
      //     width: 100%;
      // }
      &::before {
        content: "";
        width: 336px;
        height: 277px;
        background: #000000;
        opacity: 0.1;
        position: absolute;
        left: -145px;
        top: 125px;
        border-radius: 50%;
        @include max-mq(550) {
          position: absolute;
          top: 110px;
        }
      }
      &::after {
        content: "";
        width: 336px;
        height: 277px;
        background: #000000;
        opacity: 0.1;
        position: absolute;
        left: 152px;
        top: -160px;
        border-radius: 50%;
        @include max-mq(550) {
          position: absolute;
          left: 115px;
          top: -177px;
        }
      }
      .poPrice {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        .poLeftCard {
          width: 45px;
          height: 45px;
          @include max-mq(1300) {
            width: 45px;
            height: 45px;
          }
          background: #ffffff;
          border-radius: 9px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 15px;
          left: 15px;
        }
        .poRightCard {
          width: 46px;
          height: 46px;
          background: #ffffff;
          border-radius: 9px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          bottom: 20px;
          right: 15px;
        }
        .poName {
          font-weight: normal;
          font-size: 20px;
          line-height: 150%;
          text-transform: capitalize;
          color: #ffffff;
          @include max-mq(550) {
            font-size: 16px;
          }
        }
        .poprice {
          font-weight: 600;
          font-size: 24px;
          line-height: 30px;
          text-transform: capitalize;
          color: #ffffff;
          @include max-mq(550) {
            font-size: 20px;
            line-height: 24px;
          }
        }
      }
    }
  }
  .poBottom {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(325px, 1fr));
    grid-auto-rows: auto !important;
    grid-gap: 20px;
    padding: 20px 0px 50px;
    @include max-mq(1100) {
      padding-bottom: 30px;
      gap: 15px;
    }
    @include max-mq(750) {
      grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
      gap: 10px;
    }
    @include max-mq(550) {
      grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .pob_card {
      background: #ffffff;
      border-radius: 20px;
      // width: 396px;
      box-shadow: 0px 0px 5px rgb(0 0 0 / 27%);
      &:hover {
        box-shadow: rgb(0 0 0 / 20%) 0px 12px 28px 0px, rgb(0 0 0 / 10%) 0px 2px 4px 0px,
          rgb(255 255 255 / 5%) 0px 0px 0px 1px inset;
      }
      .pob_top {
        display: flex;
        align-items: center;
        gap: 20px;
        padding: 25px;
        border-bottom: 1px solid #ebebeb;
        @include max-mq(1100) {
          padding: 15px;
          gap: 15px;
        }
        @include max-mq(550) {
          padding: 10px;
          gap: 10px;
        }
        .pobt_img {
          height: 100px;
          width: 100px;
          @include max-mq(550) {
            height: 100px;
            width: 100px;
          }
          img {
            border-radius: 5px;
            height: 100px;
            width: 100px;
            object-fit: contain;
            @include max-mq(550) {
              height: 100px;
              width: 100px;
            }
          }
        }
        .pobt_name {
          font-size: 20px;
          line-height: 30px;
          text-transform: capitalize;
          @include max-mq(1300) {
            font-size: 18px;
            line-height: 22px;
          }
          @include max-mq(550) {
            font-size: 16px;
            line-height: 20px;
          }
          .pobtn1 {
            font-weight: 600;
            color: #002733;
            margin: 0px;
          }
          .pobtn2 {
            font-weight: 400;
            color: #adb5bd;
            margin: 0px;
            font-size: 16px;
          }
        }
      }
      .pob_bottom {
        padding: 25px;
        @include max-mq(1300) {
          padding: 15px;
        }
        @include max-mq(550) {
          padding: 10px;
        }
        .pobb_num {
          display: flex;
          justify-content: space-between;
          .pps {
            font-weight: 400;
            font-size: 14px;
            line-height: 26px;
            text-transform: capitalize;
            color: #6c757d;
            display: flex;
            align-items: center;
            img {
              height: 20px;
              width: 23px;
            }
            span {
              margin-left: 10px;
            }
          }
          .nq {
            font-weight: 600;
            font-size: 18px;
            line-height: 32px;
            text-transform: capitalize;
            color: #002733;
            @include max-mq(550) {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
