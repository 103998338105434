@mixin min-width($min-width) {
  @media only screen and (min-width: #{$min-width + "px"}) {
    @content;
  }
}
@mixin max-width($max-width) {
  @media only screen and (max-width: #{$max-width + "px"}) {
    @content;
  }
}
@mixin btw-sz($min-width, $max-width) {
  @media only screen and (min-width: #{$min-width + "px"}) and (max-width: #{$max-width + "px"}) {
    @content;
  }
}

.splide_outer {
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 500px !important;
}
.splide__splide.is-active.is-visable {
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 500px !important;
}
.avatar-container {
  display: flex;
  // justify-content: center;
  align-items: center;
  .avatar_slider {
    height: auto;
    width: 70px;
    height: 70px;
    object-fit: cover;
    object-position: top;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
}

.careers_container {
  display: flex;
  justify-content: space-between;
  // border: 1px solid red;
  margin: 30px auto;
  @include max-width(991) {
    display: block;
  }
  .careers_left {
    // margin: 0px auto;
    width: 50%;
    padding: 15px;
    @include max-width(991) {
      width: 100%;
      padding-top: 0px;
    }
    @include max-width(550){
     padding: 0px; 
    }
    .cl_title {
      font-weight: 600;
      font-size: 27px;
      line-height: 45px;
      text-transform: capitalize;
      color: #002733;
      text-align: center;
      @include max-width(991){
        font-size: 24px;
        line-height: 30px;
      }
      @include max-width(550){
        font-size: 20px;
        line-height: 24px;
      }
    }
    .cl_hr1 {
      height: 8px;
      color: #0466c8;
      opacity: 1;
      margin-top: 0px;
      width: 100px;
      margin: 0px auto;
      @include max-width(991){
        height: 4px;
        border-radius: 8px;
        margin-top: 5px;
      }
      @include max-width(550){
        margin-bottom: 15px;
      }
    }
    .dd1 {
      .dd1_label {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        text-transform: capitalize;
        color: #6c757d;
        @include max-width(550){
          font-size: 14px;
        }
      }
      .attach_label {
        margin-top: 10px;
      }
      .testt_fileUpload {
        // border: 0.5px solid #0466c8;
        // border-radius: 11px;
        // margin: 10px 0px;
        // height: 38px;
        position: relative;
        span {
          position: absolute;
          display: flex;
          align-items: center;
          height: 100%;
          margin-left: 10px;
          font-size: 1rem;
          font-weight: 400;
          color: rgba(44, 56, 74, 0.95);
        }
        input[type="file"]::file-selector-button {
          opacity: 0;
          height: 38px;
          width: 0px;
          margin: 0px;
          padding: 0px;
        }
      }
      .upload_notes {
        margin-bottom: 10px;
        @include max-width(550){
          font-size: 14px;
        }
      }
      .select_company {
        // background: #f8fbff;
        width: 100%;
        border: 0.5px solid #0466c8;
        border-radius: 11px;
        margin: 10px 0px;
        height: 38px;
        padding-left: 10px;
        &:focus {
          box-shadow: none;
        }
        @include max-width(550){
          margin: 5px 0px;
        }
      }
      input.select_company {
        font-size: 14px;
        &:focus-visible {
          outline: #0466c8;
        }
      }
    }
    .mob_dropdown {
      background-color: rgba(0, 0, 0, 0.05);
      .input-group {
        display: flex;
        align-items: center;
      }
      img {
        height: 16px;
        margin-bottom: 5px;
        margin-right: 5px;
      }
      .mob_code {
        font-size: 15px;
        line-height: 25px;
        color: rgb(64, 77, 96);
        font-weight: 500;
        padding: 3px;
      }
    }
    .country_dropdown {
      max-height: 187px;
      overflow-y: auto;
      width: 100%;
      li {
        padding: 5px 15px;
      }
      a {
        padding: 0px;
        &:active {
          background-color: rgba(0, 0, 0, 0.05);
          color: #4f5d73;
        }
      }
      img {
        height: 16px;
        margin-bottom: 5px;
        padding-right: 10px;
      }
      .mob_code {
        font-size: 15px;
        line-height: 25px;
        color: rgb(64, 77, 96);
        font-weight: 500;
        padding: 3px;
      }
      .country_name {
        padding-right: 15px;
      }
    }
    .Mob_dd1 {
      border: 0.5px solid #0466c8;
      border-radius: 11px;
      margin-top: 10px;
      .mob_input {
        border: none;
        outline: none;
        margin-right: 5px;
        &:focus {
          box-shadow: none;
        }
      }
    }
    .send_otp {
      margin-top: 10px;
      gap: 10px;
      input {
        border: 0.5px solid #0466c8;
        border-radius: 11px;
        width: 100%;
        height: 38px;
        &:focus-visible {
          outline: #0466c8;
        }
      }
      .myBtnn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 110px;
        height: 38px;
        color: #fff;
        background: #0466c8;
        border-radius: 5px;
        font-size: 18px;
        font-weight: 500;
        @include max-width(991){
          font-size: 14px;
        }
      }
      .verify_otp {
        gap: 10px;
        margin-top: 10px;
        display: flex;
        @include max-width(550){
          gap: 5px;
        }
      }
    }
    .terms_policy {
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      input {
        width: 30px;
      }
      .chnage_policy {
        cursor: pointer;
        margin-left: 5px;
        @include max-width(550){
          font-size: 14px;
        }
      }
    }
    .sub_btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 110px;
      height: 38px;
      color: #fff;
      background: #0466c8;
      border-radius: 5px;
      font-size: 18px;
      font-weight: 500;
      outline: none;
      border: none;
      margin: 0px auto;
      &:disabled {
        background: #3f93e7;
      }
    }
  }
  .careers_right {
    // background-color: #004767;
    width: 50%;
    color: aliceblue;
    padding: 15px;
    @include max-width(991) {
      width: 100%;
    }
    @include max-width(991) {
      // padding: 0;
    }
    .cr_title {
      font-weight: 500;
      font-size: 24px;
      line-height: 45px;
      text-transform: capitalize;
      color: #004767;
      // color: #fff;
      margin-top: 100px;
      text-align: center;
      @include max-width(991){
        margin-top: 0px;
        font-size: 16px;
      }
    }
    .cr_slide {
      font-size: 16px !important;
      height: auto;
      box-shadow: 0 4px 5px #004767;
      // box-shadow: 0 4px 5px rgb(210 240 251);
      transition: all 0.5s;
      // background-color: rgb(255, 255, 255);
      background-color: #004767;
      text-align: left;
      border-radius: 3px;
      padding: 25px;
      margin-bottom: 15px;
      // color: rgb(64, 77, 96);
      color: #fff;
      @include max-width(991){
        font-size: 14px !important;
      }
      .cr1_title {
        position: absolute;
        bottom: 10px;
      }
    }
  }
}
p.cr1_title {
  color: #004767;
  position: relative;
  &::after {
    content: ".";
    display: block;
    position: absolute;
    top: -42px;
    // height: 15px;
    // width: 15px;

    border-right-color: #004767;
    // border-right-color: #fff;
    border-width: 18px;
    border-style: solid;
    border-top-width: 2px;
    border-top-color: rgba(0, 0, 0, 0);
    border-bottom-color: rgba(0, 0, 0, 0);
    border-left-color: rgba(0, 0, 0, 0);
  }
}

.splide__pagination {
  display: none;
}

.right_sliderr {
  height: 100%;
}
.test_sliderr {
  height: 90%;
  padding-bottom: 50px;
  @include max-width(550){
    padding-bottom: 0px;
  }
}
