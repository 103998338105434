// ----Media query mixin-----------//
@mixin min-mq($min-screen-size) {
  @media (min-width: #{$min-screen-size +"px"}) {
    @content;
  }
}

@mixin max-mq($max-screen-size) {
  @media (max-width: #{$max-screen-size +"px"}) {
    @content;
  }
}

// ----------------------------For Orientation-----------------------//
@mixin landscape-orientation($max-screen-size, $orientation) {
  @media (max-width: #{$max-screen-size +"px"}) and (orientation: $orientation) {
    @content;
  }
}

@mixin btw-mq($min-screen-size, $max-screen-size, $pixel-ratio, $orientation) {
  @media only screen and (min-width: #{$min-screen-size +"px"}) and (max-width: #{$max-screen-size +"px"}) and (-webkit-min-device-pixel-ratio: $pixel-ratio) and (orientation: $orientation) {
    @content;
  }
}
@mixin btw-sz($min-screen-size, $max-screen-size) {
  @media only screen and (min-width: #{$min-screen-size +"px"}) and (max-width: #{$max-screen-size +"px"}) {
    @content;
  }
}

.thankyou {
  padding: 50px;
  @include max-mq(550) {
    padding: 15px;
  }
  //   .your_od_complet {
  //     @include max-mq(549) {
  //       font-size: 18px;
  //     }
  //   }
  .ty-top {
    text-align: center;
    img {
      height: 75px;
      width: 75px;
      background-color: #fff;
      margin-bottom: 10px;
      @include max-mq(549) {
        height: 35px;
        width: 35px;
      }
      @include btw-sz(550, 1149) {
        height: 45px;
        width: 45px;
      }
    }
    h3 {
      font-size: 28px;
      font-weight: 700;
      @include max-mq(549) {
        font-size: 18px;
      }
      @include btw-sz(549, 1149) {
        font-size: 23px;
      }
    }
    p {
      color: #717171;
      margin: 10px;
      @include max-mq(549) {
        font-size: 12px;
      }
      @include btw-sz(549, 1149) {
        font-size: 14px;
      }
    }
  }
  .thankyou_logo {
    height: 45px;
    width: 45px;
    object-fit: contain;
    border-radius: 50%;
    border: 1px solid #bcbcbc;
    margin-right: 5px;
  }
  .thank_you_id_content {
    // color: #bcbcbc;
    text-align: left;
    // position: absolute;
    // top: 15px;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    color: #002733;
    @include max-mq(549) {
      font-size: 14px;
    }
    @include btw-sz(550, 1149) {
      font-size: 16px;
    }
  }
  .ty-content {
    max-width: 800px;
    margin: 40px auto;
    background-color: #f7f7f7;
    padding: 25px;
    position: relative;
    // padding-top: 45px;
    @include max-mq(550) {
      padding: 10px;
      margin: 20px auto;
    }
    border-radius: 8px;

    background: yellow;
    text-align: center;
    background: linear-gradient(
        to right,
        #00c6ff 50%,
        rgba(255, 255, 255, 0) 0%
      ),
      linear-gradient(#00c6ff 50%, rgba(255, 255, 255, 0) 0%),
      linear-gradient(to right, #00c6ff 50%, rgba(255, 255, 255, 0) 0%),
      linear-gradient(#00c6ff 50%, rgba(255, 255, 255, 0) 0%);
    background-position: top, right, bottom, left;
    background-repeat: repeat-x, repeat-y;
    background-size: 15px 1px, 1px 15px;

    .ty-content-grid {
      //   display: flex;
      //   justify-content: space-between;
      //   align-items: center;
      //   flex-wrap: wrap;
      display: grid;
      grid-template-columns: 4fr 1fr 1fr 2fr;
      padding-top: 20px;
      gap: 15px;
      @include max-mq(700) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        // grid-template-columns: repeat(1, 1fr);
      }
      @include btw-sz(700, 900) {
        grid-template-columns: repeat(2, 1fr);
      }
      @include max-mq(350) {
        gap: 0;
      }
    }
    .tym {
      padding: 5px;
      text-transform: capitalize;
      text-align: start;
      min-width: 150px;
      .ty-dpid {
        color: #adb5bd;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
      }
      .ty-dpcount {
        font-weight: 600;
        font-size: 18px;
        line-height: 30px;
        color: #002733;
        margin-top: 5px;
        @include max-mq(549) {
          font-size: 14px;
        }
        @include btw-sz(550, 1149) {
          font-size: 16px;
        }
      }
    }
  }
  .thank_company {
    display: flex;
    gap: 15px;
    align-items: center;
  }
  .company_name_thank {
    display: -webkit-box;
    // max-width: 200px;
    width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    @include max-mq(549) {
      line-height: 20px;
    }
  }
  .ty-form {
    // height: 500px;
    max-width: 800px;
    margin: auto;
    background-color: #f7f7f7;
    border-radius: 8px;
    padding: 50px;
    @include max-mq(550) {
      padding: 15px;
    }
    input {
      width: 100%;
      height: 45px;
      background: #f8fbff;
      border: 1px solid #bcbcbc52;
      border-radius: 8px;
      font-size: 16px;
      line-height: 23px;
      text-transform: capitalize;
      color: rgba(3, 7, 30, 0.5);
      padding: 10px;
      @include max-mq(550) {
        height: 35px;
        margin-bottom: 10px;
        border-radius: 3px;
        font-size: 14px;
      }
      margin-bottom: 15px;
      &:focus {
        outline: none;
      }
      @include max-mq(768) {
        width: 100%;
      }
    }
    .ty-btn {
      display: flex;
      justify-content: center;
      button {
        border: none;
        outline: none;
        background-color: #00c6ff;
        color: #fff;
        border-radius: 8px;
        height: 35px;
        padding: 0px 15px;
      }
    }
  }
}
