// ----Media query mixin-----------//
@mixin min-mq($min-screen-size) {
  @media (min-width: #{$min-screen-size +"px"}) {
    @content;
  }
}

@mixin max-mq($max-screen-size) {
  @media (max-width: #{$max-screen-size +"px"}) {
    @content;
  }
}

// ----------------------------For Orientation-----------------------//
@mixin landscape-orientation($max-screen-size, $orientation) {
  @media (max-width: #{$max-screen-size +"px"}) and (orientation: $orientation) {
    @content;
  }
}

@mixin btw-mq($min-screen-size, $max-screen-size, $pixel-ratio, $orientation) {
  @media only screen and (min-width: #{$min-screen-size +"px"}) and (max-width: #{$max-screen-size +"px"}) and (-webkit-min-device-pixel-ratio: $pixel-ratio) and (orientation: $orientation) {
    @content;
  }
}
@mixin btw-sz($min-screen-size, $max-screen-size) {
  @media only screen and (min-width: #{$min-screen-size +"px"}) and (max-width: #{$max-screen-size +"px"}) {
    @content;
  }
}

.cp_assetes {
  .cpa-right-box {
    padding: 20px 0px 50px;
    .title {
      display: flex;
      justify-content: space-between;
      @include max-mq(470) {
        display: block;
      }
      @include max-mq(768) {
        padding-bottom: 0px;
      }
      .first {
        .r-b-title {
          font-weight: 600;
          font-size: 24px;
          line-height: 30px;
          text-transform: capitalize;
          color: #002733;
          @include max-mq(768) {
            font-size: 24px;
            line-height: 28px;
          }
        }
        .hr1 {
          width: 110px;
          height: 0px;
          opacity: 1;
          margin: 5px;
          margin-left: 0px;
          border-radius: 5px;
          border: 2px solid #0466c8;
        }
      }
      .search {
        width: 300px;
        height: 45px;
        background: #ffffff;
        // box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        box-shadow: 0px 8px 15px rgba(208, 208, 208, 0.25);
        border-radius: 12px;
        font-size: 24px;
        line-height: 30px;
        color: rgba(3, 7, 30, 0.5);
        display: flex;
        justify-content: flex-start;
        align-items: center;

        @include max-mq(768) {
          width: 200px;
        }

        @include max-mq(470) {
          margin-top: 20px;
          width: 100%;
          height: 35px;
        }
        .icon {
          margin: 10px;
          width: 25px !important;
        }
        input {
          border: none;
        }
        ::placeholder {
          font-size: 20px;
          line-height: 30px;
          color: rgba(3, 7, 30, 0.5);
        }
        input[type="text"] {
          border: none;
          outline: none;
          width: 98%;
          margin-right: 5px;
          font-size: 18px;
          line-height: 26px;
          color: rgba(3, 7, 30, 0.5);
          @include max-mq(768) {
            font-size: 18px;
          }
          &::placeholder {
            font-size: 18px;
          }
        }
      }
    }
  }
  .myFormm {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 15px;
    gap: 15px;
    @include max-mq(992) {
      grid-template-columns: repeat(1, 1fr);
    }
    label {
      font-weight: 600;
      color: #161619;
      font-size: 1.125rem;
      line-height: 30px;
      margin: 8px 0px;
    }
    input {
      width: 100%;
      height: 45px;
      background: #f8fbff;
      border: 1px solid #bcbcbc52;
      border-radius: 11px;
      font-size: 16px;
      line-height: 23px;
      color: rgba(3, 7, 30, 0.5);
      padding: 10px;
      text-transform: capitalize;
      &:focus {
        outline: none;
      }
      @include max-mq(768) {
        width: 100%;
      }
    }
    input[type="date"]::-webkit-calendar-picker-indicator {
      z-index: 1;
      background-image: url("../../../../User/assets/transaction/datePicker.svg");
      margin-top: 12px;
      background-size: 13px;
      margin-left: -15px;
      cursor: pointer;
      text-transform: uppercase;
    }
    .Cp_Mob_dd1 {
      border-radius: 11px;
      border: 0.5px solid rgba(188, 188, 188, 0.3215686275);
      .mob_input {
        // margin-right: 5px;
        border: none;
        outline: none;
        &:focus {
          box-shadow: none;
        }
      }
      .input-group {
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        align-items: stretch;
        width: 100%;
      }
      .cpmob_dropdown {
        // background-color: rgba(0, 0, 0, 0.05);
        .input-group {
          display: flex;
          align-items: center;
        }
        img {
          height: 16px;
          margin-bottom: 5px;
          margin-right: 5px;
        }
        .mob_code {
          font-size: 15px;
          line-height: 25px;
          color: rgb(64, 77, 96);
          font-weight: 500;
          padding: 3px;
        }
      }
      .country_dropdown {
        max-height: 187px;
        overflow-y: auto;
        width: 100%;
        li {
          padding: 5px 15px;
        }
        a {
          padding: 0px;
          &:active {
            background-color: rgba(0, 0, 0, 0.05);
            color: #4f5d73;
          }
        }
        img {
          height: 16px;
          margin-bottom: 5px;
          padding-right: 10px;
        }
        .mob_code {
          font-size: 15px;
          line-height: 25px;
          color: rgb(64, 77, 96);
          font-weight: 500;
          padding: 3px;
        }
        .country_name {
          padding-right: 15px;
        }
      }
    }
    #transactionDate {
      text-transform: uppercase;
    }
    .basic-single-2 {
      input {
        height: 28px;
      }
      .select__control {
        width: 100%;
        height: 45px;
        background: #f8fbff;
        border: 1px solid rgba(188, 188, 188, 0.3215686275);
        border-radius: 11px;
        font-size: 16px;
        line-height: 23px;
        color: rgba(3, 7, 30, 0.5);
        text-transform: capitalize;
      }
      &:focus {
        box-shadow: none !important;
      }
    }
  }
  .myFormm2 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: 15px;
    gap: 10px;
    @include max-mq(768) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .myFormm3 {
    label {
      background: #0466c8;
      border-radius: 6px;
      font-weight: 600;
      font-size: 13px;
      line-height: 23px;
      text-transform: capitalize;
      color: #ffffff;
      height: 45px;
      width: 100%;
      padding: 0 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    input[type="file"] {
      &::-webkit-file-upload-button {
        display: none;
      }
    }
  }
}

.add_assetes {
  background: #002733 !important;
  outline: none;
  border: none;
  box-shadow: none !important;
  width: 124px;
  color: white;
  margin-bottom: 15px;
}

.assets_content {
  .card_assets.card {
    border: none;
    margin-bottom: 15px;
    border-radius: 11px !important;
    overflow: hidden;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 27%);
    &:hover {
      box-shadow: rgb(0 0 0 / 20%) 0px 12px 28px 0px,
        rgb(0 0 0 / 10%) 0px 2px 4px 0px,
        rgb(255 255 255 / 5%) 0px 0px 0px 1px inset;
    }
    .card-header.asset_header {
      padding: 15px;
      background: #fff;
      display: flex;
      justify-content: space-between;
      @include max-mq(549) {
        flex-direction: column;
      }
    }
    .card-body {
      .asset_card_body {
        display: flex;
        justify-content: space-between;
        // @include max-mq(549) {
        //   flex-direction: column;
        // }
        .assete_content_left,
        .assete_content_right {
          .asset_show_box {
            display: flex;
            align-items: center;
            // grid-template-columns: auto auto;
            gap: 15px;
          }
        }
        .assete_content_left {
          display: flex;
          gap: 5px;
        }
        .assete_content_right {
          .asset_show_box {
            justify-content: flex-end;
            @include max-mq(549) {
              justify-content: flex-start;
            }
          }
        }
        .svgInfo {
          height: 15px;
          cursor: pointer;
        }
      }
    }
    .card-footer.card_footer_asset {
      display: flex;
      justify-content: space-between;
      background: #002733;
      @include max-mq(549) {
        flex-direction: column;
      }
      .asset_footer_left,
      .asset_footer_right {
        .asset_show_box {
          display: flex;
          gap: 15px;
          align-items: center;
        }
      }
    }
    .asset_show_box {
      margin-bottom: 5px;
      .lable_txt {
        font-size: 14px;
        font-weight: 600;
        @include max-mq(549) {
          font-size: 12px;
        }
      }
      .lable_content {
        font-size: 18px;
        font-weight: 500;
        color: #002733;
        // color: #bcbcbc;
        @include max-mq(549) {
          font-size: 14px;
        }
        // color: black;
      }
      .lable_txt2 {
        color: #fff;
      }
    }
  }
}
.Share-Details-modal {
  .modal-dialog-centered {
    width: 100% !important;
    max-width: 800px !important;
    @include max-mq(992) {
      max-width: 500px !important;
    }
    @include max-mq(576) {
      margin: auto !important;
      // margin: 10px auto !important;
      max-width: 90% !important;
    }
  }
}

.assets-loder-content {
  height: calc(100vh - 205px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
