.countryVert {
  display: flex;
  flex-direction: column;
}
.sell_input {
  width: 100% !important;
  height: 45px !important;
  background: #f8fbff;
  border: 0.5px solid #0466c8 !important;
  border-radius: 11px !important;
  font-size: 18px;
  line-height: 23px;
  text-transform: capitalize;
  color: rgba(3, 7, 30, 0.5);
  padding: 6px 12px 6px 48px !important;
  &:focus {
    box-shadow: none !important;
  }
  .flag-dropdown {
    border-radius: 11px !important;
  }
}
.flag-dropdown {
  border: 0.5px solid #0466c8 !important;
  border-radius: 11px 0 0 11px !important;
}
.react-tel-input .selected-flag {
  outline: none;
  position: relative;
  width: 38px;
  height: 100%;
  padding: 0 0 0 8px;
  border-radius: 11px 0 0 11px !important;
}
.client-detail-modal{
  .modal-dialog-centered{
    .modal-content{
      .modal-body{
        .flag-dropdown {
          border: 1px solid #b1b7c1 !important;
        }
      }
    }
  }
}
.sell_input:disabled + div{
  border: none !important;
  }