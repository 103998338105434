@mixin min-width($min-width) {
  @media only screen and (min-width: #{$min-width + "px"}) {
    @content;
  }
}
@mixin max-width($max-width) {
  @media only screen and (max-width: #{$max-width + "px"}) {
    @content;
  }
}
@mixin btw-sz($min-width, $max-width) {
  @media only screen and (min-width: #{$min-width + "px"}) and (max-width: #{$max-width + "px"}) {
    @content;
  }
}
$bg-primary: #002733;
$primary: #002733;
.navbar_unlist_logo {
  @include max-width(1499) {
    width: 135px;
  }
}
.navbar_brand_logo_img {
  @include btw-sz(1200, 1299) {
    width: 150px;
  }
}
.alert {
  padding: 10px 0;
  .phone {
    gap: 15px;
  }
  .logo-icon {
    display: none;
    @include max-width(1000) {
      display: none !important;
    }
    // @include max-width(-width)
    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 15px;
    }
  }
}
.view-alert {
  gap: 45px;
  @include max-width(599) {
    flex-direction: column;
    gap: 15px;
  }
}
.navbar-brand {
  img {
    @include btw-sz(550, 1225) {
      // max-width: 100px;
      max-width: 130px;
    }
  }
}
.navbar-toggler:focus {
  box-shadow: none;
}
.alert-dark {
  background: #002733;
  color: white;
  margin-bottom: 0;
}
.navbar-collapse {
  z-index: 999;
}
.navbar-brand {
  img {
    @include max-width(549) {
      width: 140px;
    }
  }
}
.container.bg-white {
  @include max-width(992) {
    padding: 15px;
  }
}
.link_red {
  color: white;
  text-decoration: none;
  &:hover {
    color: white;
    text-decoration: none;
  }
}
nav.navbar {
  height: 80px;
  background-color: #ffffff !important;
  box-shadow: 0px 4px 23px rgba(0, 39, 51, 0.16);
  .nav-drop {
    position: relative;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    text-transform: capitalize;
    color: #002733;
    background: white;
    text-decoration: none;
    background: transparent;
    padding: 8px 15px;

    a {
      text-decoration: none;
      color: inherit;
    }
    &:hover {
      color: #0466c8;
      background-color: #d9e0e7;
    }
  }
  .nav-link {
    position: relative;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    text-transform: capitalize;
    color: #002733;
    background: white;
    @include max-width(549) {
      font-size: 16px;
    }
    @include btw-sz(990, 1005) {
      font-size: 16px !important;
    }
    a {
      text-decoration: none;
      color: inherit;
    }
    &:hover {
      color: #0466c8;
    }
    // &::before {
    //   content: "";
    //   width: 0%;
    //   height: 3px;
    //   background-color: #0466c8;
    //   position: absolute;
    //   left: 0;
    //   bottom: 0;
    //   transition: width 0.2s ease-in-out;
    // }
    // &:hover::before {
    //   width: 100%;
    // }
  }
}
.nav-link.active {
  &::before {
    content: "";
    width: 100%;
    height: 3px;
    background-color: #0466c8;
    position: absolute;
    left: 0;
    bottom: 0;
    transition: width 0.2s ease-in-out;
  }
}
.btn-content {
  display: flex;
  gap: 15px;
  @include max-width(549) {
    justify-content: space-between;
  }

  button {
    padding: 5px 20px;
    border: none;
    outline: none;
    background-color: white;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    text-transform: capitalize;
    color: #0466c8;
    border-radius: 20px;
    &:hover {
      box-shadow: 0px 10px 34px rgba(0, 0, 0, 0.13);
    }
  }
}

.h-class {
  ul li {
    padding: 0px;
    border-left: 0px;
  }
}
.h-header {
  z-index: 99;
}
.register {
  background: #0466c8 !important;
  color: white !important;
}

.color_code {
  color: #002733 !important;
  text-decoration: none;
}
.txt_modal {
  color: #bcbcbc;
  line-height: 19px;
  font-size: 14px;
  text-align: justify;
}
.mobile_drop_query {
  display: none;

  @include max-width(1299) {
    display: block;
  }
}
.desktop_drop_query {
  display: none;
  @include min-width(1300) {
    display: block;
  }
}
.dropdown-item {
  @include max-width(549) {
    font-size: 16px !important;
  }
}
.profile_click_login {
  max-height: 150px;
  margin: 15px 0;
  border-radius: 50%;
}
