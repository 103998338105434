@mixin min-width($min-width) {
  @media only screen and (min-width: #{$min-width + "px"}) {
    @content;
  }
}
@mixin max-width($max-width) {
  @media only screen and (max-width: #{$max-width + "px"}) {
    @content;
  }
}
@mixin btw-sz($min-width, $max-width) {
  @media only screen and (min-width: #{$min-width + "px"}) and (max-width: #{$max-width + "px"}) {
    @content;
  }
}
.about-page {
  .bred-crumb {
    height: 100px;
    background: #002733;
    padding: 10px 0;
  }
  .bread-title {
    margin-bottom: 10px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 27px;
    line-height: 45px;
    text-transform: capitalize;
    color: #ffffff;
    position: relative;
    padding: 0px;
    @include max-width(549) {
      font-size: 20px;
    }
    @include btw-sz(1150, 1549) {
      font-size: 27px;
    }
    &::before {
      content: "";
      width: 50px;
      bottom: 0;
      left: 0px;
      height: 5px;
      background-color: white;
      position: absolute;
      @include max-width(549) {
        height: 3px;
      }
    }
  }
  .bread-content {
    // padding: 0 15px;
    .breads {
      color: white;
    }
  }
  .content {
    margin: 15px 0;
    .img-gallery {
      position: relative;
      padding: 50px 130px;
      @include btw-sz(1001, 1199) {
        padding: 50px 80px;
      }
      @include btw-sz(550, 749) {
        padding: 30px;
      }
      @include max-width(549) {
        padding: 30px 0px;
      }
      @include btw-sz(750, 1000) {
        padding: 50px;
      }
      .view-all {
        position: absolute;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
        text-transform: capitalize;
        border-radius: 8px;
        background-color: #0466c8;
        color: #ffffff;
        padding: 11px 15px;
        right: 45px;
        bottom: 30px;
        outline: none;
        border: none;
        @include max-width(549) {
          right: 30px;
          padding: 5px 15px;
          font-size: 14px;
        }
      }
      .img-grid {
        display: grid;
        grid-template-columns: repeat(5, auto);
        grid-auto-rows: 100px;
        gap: 15px;
        @include max-width(749) {
          grid-template-columns: repeat(3, minmax(50px, 135px));
          grid-auto-rows: 60px;
        }
        .img-content {
          width: 100%;
          height: 100%;
          border-radius: 11px;
          overflow: hidden;
          &:nth-child(1) {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 3;
            @include max-width(749) {
              grid-column-start: 1;
              grid-column-end: 2;
              grid-row-start: 1;
              grid-row-end: 3;
            }
          }
          &:nth-child(2) {
            grid-column-start: 2;
            grid-column-end: 5;
            grid-row-start: 1;
            grid-row-end: 3;
            @include max-width(749) {
              grid-column-start: 2;
              grid-column-end: 5;
              grid-row-start: 1;
              grid-row-end: 3;
            }
          }
          &:nth-child(3) {
            grid-column-start: 5;
            grid-column-end: 7;
            grid-row-start: 1;
            grid-row-end: 4;
            @include max-width(749) {
              grid-column-start: 1;
              grid-column-end: 3;
              grid-row-start: 3;
              grid-row-end: 5;
            }
          }
          &:nth-child(4) {
            grid-column-start: 1;
            grid-column-end: 4;
            grid-row-start: 3;
            grid-row-end: 5;
            @include max-width(749) {
              grid-column-start: 3;
              grid-column-end: 5;
              grid-row-start: 3;
              grid-row-end: 5;
            }
          }
          &:nth-child(5) {
            grid-column-start: 4;
            grid-column-end: 5;
            grid-row-start: 3;
            grid-row-end: 5;
            @include max-width(749) {
              grid-column-start: 1;
              grid-column-end: 2;
              grid-row-start: 5;
              grid-row-end: 7;
            }
          }
          &:nth-child(6) {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 5;
            grid-row-end: 8;
            @include max-width(749) {
              grid-column-start: 2;
              grid-column-end: 5;
              grid-row-start: 5;
              grid-row-end: 7;
            }
          }
          &:nth-child(7) {
            grid-column-start: 2;
            grid-column-end: 5;
            grid-row-start: 5;
            grid-row-end: 8;
            @include max-width(749) {
              grid-column-start: 1;
              grid-column-end: 3;
              grid-row-start: 7;
              grid-row-end: 9;
            }
          }
          &:nth-child(8) {
            grid-column-start: 5;
            grid-column-end: 7;
            grid-row-start: 4;
            grid-row-end: 8;
            @include max-width(749) {
              grid-column-start: 3;
              grid-column-end: 5;
              grid-row-start: 7;
              grid-row-end: 9;
            }
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}
.linkdn_img {
  @include min-width(549) {
    width: 35px;
    height: 35px;
  }
}
.about-content-txt {
  margin: 30px 0;
  margin-top: 60px;
  @include max-width(549) {
    margin-top: 45px;
  }
  @include btw-sz(550, 1199) {
    margin-top: 30px;
  }
  .title {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 27px;
    line-height: 45px;
    text-transform: capitalize;

    color: #002733;
    position: relative;
    margin-bottom: 15px;
    padding: 15px 0;
    @include max-width(549) {
      padding: 0;
      font-size: 20px;
    }
    @include btw-sz(550, 1199) {
      font-size: 23px;
    }
    // @include btw-sz(550, 1149) {
    //   font-size: 26px;
    // }
    &::before {
      bottom: 0;
      left: 0;
      background-color: #0466c8;
      width: 60px;
      height: 3px;
      position: absolute;
      content: "";
    }
    .view-content {
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 150%;
      /* or 36px */
      // width: 80%;

      text-transform: capitalize;

      color: #002733;
    }
  }
  p.content-txt {
    width: 80%;
    font-size: 18px;
    text-align: justify;
    @include max-width(749) {
      width: 100%;
    }
    @include btw-sz(1150, 1549) {
      font-size: 18px;
    }
    @include max-width(549) {
      font-size: 14px;
    }
    @include btw-sz(550, 1149) {
      font-size: 16px;
      line-height: 23px;
      width: 100%;
    }
  }
}
.value {
  margin-top: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @include max-width(549) {
    margin-top: 50px;
  }
  @include btw-sz(550, 749) {
    margin-top: 50px;
  }
  .title {
    // text-align: center;
    /* H1 */
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 45px;
    text-transform: capitalize;
    margin-bottom: 15px;
    color: #002733;
    display: flex;
    justify-content: center;
    @include max-width(549) {
      font-size: 24px;
    }
    @include btw-sz(1150, 1549) {
      font-size: 32px;
    }
    @include btw-sz(550, 1149) {
      font-size: 26px;
    }
    span {
      position: relative;
      padding: 15px 0;
      &::before {
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        margin: auto;
        background-color: #0466c8;
        width: 60px;
        height: 3px;
        position: absolute;
        content: "";
      }
    }
    // border-bottom: 5px solid #0466c8;
  }
  .value-content {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 150%;
    /* or 36px */
    max-width: 725px;
    text-align: center;
    // text-transform: capitalize;

    color: #6c757d;
    @include max-width(549) {
      font-size: 14px;
      text-align: justify;
    }
    @include btw-sz(1150, 1549) {
      font-size: 18px;
    }
    @include btw-sz(550, 1149) {
      font-size: 16px;
    }
  }
  .value-entern-content {
    overflow: hidden;
    margin: 15px 0;
    position: relative;
    height: 500px;
    .circle {
      position: absolute;
      border-radius: 50%;
      width: 600px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 600px;
      //   background-color: gray;
      z-index: -1;

      .view-icons {
        position: relative;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        // background-color: red;
        .icon-circle {
          background: #ffffff;
          box-shadow: 0px 14px 40px rgba(0, 120, 239, 0.25);
          border-radius: 100px;
          padding: 5px;
          height: 92px;
          width: 92px;
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          &:nth-child(1) {
            top: 60px;
            left: 87px;
          }
          &:nth-child(2) {
            bottom: 60px;
            left: 87px;
          }
          &:nth-child(3) {
            top: 50%;
            transform: translateY(-50%);
            left: 0;
          }
          &:nth-child(4) {
            top: 60px;
            right: 87px;
          }
          &:nth-child(5) {
            bottom: 60px;
            right: 87px;
          }
          &:nth-child(6) {
            top: 50%;
            transform: translateY(-50%);
            right: 0;
          }
        }
      }
    }
    .inner-content {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      .entern-content {
        width: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 15px;

        .title-entern {
          display: flex;
          justify-content: center;
          font-family: Source Sans Pro;
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 30px;
          text-transform: capitalize;
          span {
            border-bottom: 2px solid #0466c8;
          }

          color: #0466c8;
        }
        .logo {
          margin: auto;
          //   left: 50%;
          //   transform: translateX(-50%);
          width: min-content;
          padding: 15px;
          background-color: #0466c8;
          border-radius: 50%;
        }
        .text-view-content {
          padding: 15px;
        }
      }
    }
  }
}
.founder_detail_view {
  margin: auto;
  width: min-content;
}
.details {
  margin-top: 100px;
  @include max-width(549) {
    padding-top: 30px;
    margin-top: 20px;
  }
  // @include btw-sz(550, 1199) {
  //   margin-top: 60px !important;
  // }
  .detail_col {
    @include max-width(1199) {
      margin-top: 15px;
      text-align: center;
    }

    img {
      width: 280px;
      height: 320px;
      object-fit: cover;
      object-position: top;
      border-radius: 20px;
      @include max-width(549) {
        height: 200px;
        width: 150px;
      }
      @include btw-sz(1150, 1549) {
        width: 240px;
        height: 284px;
      }
      @include btw-sz(550, 1149) {
        width: 215px;
        height: 245px;
        object-fit: cover;
        object-position: top;
        border-radius: 20px;
      }
    }
    .founder {
      margin-top: 15px !important;
      display: flex;
      flex-direction: column;
      // gap: 10px;
      gap: 5px;
      .title {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: 0.26em;
        text-transform: capitalize;

        color: #002733;
        @include max-width(549) {
          font-size: 18px;
        }
        @include btw-sz(1150, 1549) {
          font-size: 22px;
        }
      }
      .name {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 45px;
        text-transform: capitalize;

        color: #002733;
        @include max-width(549) {
          font-size: 18px;
        }
        @include btw-sz(560, 1199) {
          font-size: 26px;
        }
        @include btw-sz(1150, 1549) {
          font-size: 30px;
        }
      }
      .icons {
        img {
          // height: 50px;
          // width: 50px;
          height: 32px;
          width: 32px;
          @include max-width(549) {
            height: 32px;
            width: 32px;
          }
          @include btw-sz(550, 1149) {
            height: 32px;
            width: 32px;
          }
        }
      }
    }
  }
}
.about_col {
  width: 100%;
}
.founder-content {
  margin-top: 150px;
  text-align: justify;
  // text-indent: 70px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  /* or 36px */

  text-align: justify;
  text-transform: capitalize;

  color: #002733;
  @include max-width(549) {
    margin-top: 30px;
    font-size: 14px;
  }
  @include btw-sz(550, 1199) {
    margin-top: 30px;
    font-size: 16px;
  }
  @include btw-sz(1150, 1549) {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    text-align: justify;
    text-transform: capitalize;
    color: #002733;
  }
  p {
    margin-top: 15px;
  }
}
.news-section {
  margin-top: 80px;
  margin-bottom: 70px;
  .title {
    position: relative;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 45px;
    text-transform: capitalize;
    text-align: center;
    color: #002733;
    margin: 75px;
    padding: 15px;
    @include max-width(549) {
      font-size: 24px;
      margin: 30px 0;
    }
    @include btw-sz(1150, 1549) {
      font-size: 32px;
    }
    span {
      padding-bottom: 30px;
      &::before {
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        background-color: #0466c8;
        width: 60px;
        height: 3px;
        position: absolute;
        content: "";
      }
    }
  }
  // div.partner {
  //   display: flex;
  //   gap: 15px;
  // }
}

.partner {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 75px;
  margin-bottom: 30px;
  img.partn_img {
    width: 180px;
  }
  @include btw-sz(550, 1199) {
    flex-wrap: wrap;
    img.partn_img {
      width: 180px;
      // width: 100px;
    }
  }
  @include max-width(549) {
    flex-direction: column;
    gap: 10px;
    img {
      height: 70px;
      width: 100px;
    }
  }
}

// new code for the circle implementation
@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap");
#testimonial-area {
  @include max-width(349) {
    transform: scale(0.78);
  }
  .section-heading h2 {
    font-size: 48px;
    line-height: 58px;
  }
}

.testi-wrap {
  position: relative;
  height: 650px;
  width: 725px;
  border-radius: 50%; // background-color: gray;
  @include max-width(400) {
    width: 235px;
    height: 300px;
  }
  @include btw-sz(401, 500) {
    height: 325px;
    // width: 325px;
    width: 300px;
  }
  @include btw-sz(501, 600) {
    height: 400px;
    // width: 400px;
    width: 370px;
  }
  @include btw-sz(601, 1000) {
    height: 450px;
    width: 450px;
  }
  // margin-top: -80px;
}

.client-single {
  margin-top: 20px;
  text-align: center;
  position: absolute;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  @include max-width(600) {
    margin-top: 0;
  }
}

.client-info,
.client-comment {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.client-single {
  &.inactive {
    .client-comment,
    .client-info {
      display: none;
    }
    .client-comment,
    .client-info {
      opacity: 0;
      visibility: hidden;
    }
    // .center_circle_txt {
    //   margin-top: 5px;
    // }
    .client-img {
      margin-bottom: 5px;
      // border-radius: 0%;
      img {
        // border-radius: 0%;
        width: 100px;
        height: 100px;
        object-fit: contain;
        @include min-width(1200) {
          width: 150px;
          height: 150px;
        }
        @include max-width(599) {
          width: 65px;
          height: 65px;
          object-fit: contain;
        }
      }
    }
  }
  &.position-1 {
    -webkit-transform: scale(0.65);
    transform: scale(0.65);
  }
  &.position-2 {
    left: -15px;
    top: 0px;
    @include max-width(449) {
      left: -30px;
    }
    @include btw-sz(450, 499) {
      left: -45px;
    }
    @include btw-sz(500, 600) {
      left: 15px;
    }
  }
  &.position-3 {
    left: -115px;
    top: 50%;

    -webkit-transform: scale(0.4) !important;
    transform: translateY(-50%) scale(0.55) !important;
    @include max-width(600) {
      left: -60px;
    }
    @include btw-sz(300, 450) {
      // left: -35px;
      // left: -60px;
      left: -50px;
    }
    @include btw-sz(600, 750) {
      left: -75px;
    }
  }
  &.position-4 {
    left: 5px;
    bottom: 0px;

    @include max-width(449) {
      // left: -15px;
      left: -25px;
    }
    @include btw-sz(450, 499) {
      left: -45px;
    }
  }
  &.position-5 {
    top: 0px;
    right: -15px;
    @include max-width(449) {
      right: -30px;
    }
    @include btw-sz(450, 499) {
      right: -45px;
    }
    @include btw-sz(500, 600) {
      right: 15px;
    }
  }
  &.position-6 {
    top: 50%;
    transform: translateY(-50%) scale(0.55) !important;
    right: -115px;
    @include max-width(600) {
      right: -60px;
    }
    @include btw-sz(300, 450) {
      // right: -35px;
      // right: -60px;
      right: -50px;
    }
    @include btw-sz(600, 750) {
      right: -75px;
    }
  }
  &.position-7 {
    bottom: 0px;
    right: 5px;
    @include max-width(449) {
      // right: -15px;
      right: -25px;
    }
    @include btw-sz(450, 499) {
      right: -45px;
    }
  }
  &.active {
    top: 10%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 10;
    width: 70%;
    .client-comment,
    .client-info {
      -webkit-transition-delay: 0.6s;
      transition-delay: 0.6s;
    }
    .client-img {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        box-shadow: 0px !important;
      }
    }
  }
  &:not(.active) {
    -webkit-transform: scale(0.55);
    transform: scale(0.55);
    z-index: 99;
  }
  &.active .client-img {
    width: 160px;
    height: 160px;
    margin: 0 auto 24px;
    position: relative;

    // padding: 30px;
    img {
      width: 75px;
      height: 75px;
      object-fit: contain;
      border-radius: 0%;
      box-shadow: none !important;
      @include min-width(1200) {
        width: 100px;
        height: 100px;
      }
    }
    @include max-width(600) {
      width: 100px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
    }
    @include btw-sz(600, 1000) {
      width: 110px;
      height: 110px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
    }
    &:before {
      border-radius: 100%;
      content: "";
      box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
        rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
      // background-image: -webkit-gradient(
      //   linear,
      //   left top,
      //   left bottom,
      //   from(rgb(157, 91, 254)),
      //   to(rgb(56, 144, 254))
      // );
      // background-image: linear-gradient(
      //   180deg,
      //   rgb(157, 91, 254) 0%,
      //   rgb(56, 144, 254) 100%
      // );
      padding: 5px;
      width: 160px;
      height: 160px;
      top: -4px;
      left: 0px;
      position: absolute;
      z-index: -1;
      @include max-width(600) {
        width: 100px;
        height: 100px;
        top: 0;
      }
      @include btw-sz(600, 1000) {
        width: 110px;
        height: 110px;
        top: 0;
      }
    }
  }
  .client-img img {
    width: 150px;
    border-radius: 50%;
    cursor: pointer;
    @include max-width(1200) {
      // border: 8px solid #d1e9ff;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      padding: 8px;
    }
    @include min-width(1200) {
      width: 150px;
      border-radius: 50%;
      /* border: 8px solid #d1e9ff; */
      cursor: pointer;
      box-shadow: 0px 14px 40px rgb(0 120 239 / 25%);
      padding: 12px;
    }
    @include max-width(600) {
      width: 75px;
    }
    @include btw-sz(601, 1000) {
      width: 100px;
    }
  }
  &.active .client-img img {
    max-width: 160px;
    margin: 0 auto 24px;
    border: 0;
    @include max-width(600) {
      width: 75px;
      margin: 0 auto 0px;
    }
    @include btw-sz(600, 1000) {
      width: 100px;
      margin: 0 auto 0px;
    }
  }
}

.client-comment {
  padding: 0 30px;
  height: min-content;
  margin-top: 15px;
  margin-bottom: 15px;
  @include max-width(1000) {
    padding: 0;
  }
  @include max-width(749) {
    margin-top: 15px !important;
  }

  h3 {
    font-size: 18px;
    line-height: 27px;
    color: #505b6d;
    margin-top: 60px;

    @include max-width(600) {
      font-size: 14px;
      line-height: 20px;
    }
    @include max-width(449) {
      font-size: 12px;
      line-height: 16px;
    }
    @include btw-sz(600, 1000) {
      font-size: 16px;
      line-height: 22px;
    }
  }
  span i {
    font-size: 60px;
    color: #0084ff;
    margin: 40px 0 24px;
    display: inline-block;
    @include max-width(600) {
      margin: 0;
    }
  }
}

.client-info {
  h3 {
    color: #000;
    font-weight: 600;
    margin-bottom: 4px;
  }
  p {
    color: #0084ff;
    text-transform: uppercase;
  }
}

.center_circle_txt {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  text-decoration: none;
  color: #0466c8 !important;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  text-transform: capitalize;
  color: #0466c8;
  border-bottom: 1px solid #0466c8;
  @include max-width(449) {
    font-size: 18px;
  }
  &:active {
    color: #0466c8;
  }
  &:visited {
    color: #0466c8;
  }
}
.about_row {
  display: flex;
  gap: 80px;
  @include max-width(1199) {
    flex-direction: column;
    gap: 40px;
  }
  @include max-width(549) {
    gap: 35px;
  }
  .short_paragraph {
    display: flex;
    gap: 50px;

    @include max-width(1149) {
      gap: 30px;
    }
    @include max-width(750) {
      flex-direction: column;
      gap: 30px;
    }
  }
}
