@mixin min-width($min-width) {
    @media only screen and (min-width: #{$min-width + "px"}) {
        @content;
    }
}
@mixin max-width($max-width) {
    @media only screen and (max-width: #{$max-width + "px"}) {
        @content;
    }
}
@mixin btw-sz($min-width, $max-width) {
    @media only screen and (min-width: #{$min-width + "px"}) and (max-width: #{$max-width + "px"}) {
        @content;
    }
}

.partner_container {
    display: flex;
    justify-content: space-between;
    // border: 1px solid red;
    margin: 30px auto;
    @include max-width(991) {
        display: block;
    }
    .partner_left {
        // margin: 0px auto;
        width: 50%;
        padding: 15px;
        @include max-width(991) {
            width: 100%;
        }
        .partner_title {
            font-weight: 600;
            font-size: 26px;
            line-height: 45px;
            text-transform: capitalize;
            color: #002733;
            text-align: center;
        }
        .partner_hr1 {
            height: 8px;
            color: #0466c8;
            opacity: 1;
            margin-top: 0px;
            width: 100px;
            margin: 0px auto;
        }
        .pp1 {
            // display: flex;
            // align-items: center;
            // gap: 15px;
            .pp1_label {
                // width: 200px;
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                text-transform: capitalize;
                color: #6c757d;
            }
            .select_company {
                // background: #f8fbff;
                width: 100%;
                border: 0.5px solid #0466c8;
                border-radius: 11px;
                margin: 10px 0px;
                height: 38px;
                padding-left: 10px;
                &:focus {
                    box-shadow: none;
                }
            }
            input.select_company {
                font-size: 14px;
                &:focus-visible {
                    outline: #0466c8;
                }
            }
        }
        .mob_drop {
            background-color: rgba(0, 0, 0, 0.05);
            .input-group {
                display: flex;
                align-items: center;
            }
            img {
                height: 16px;
                margin-bottom: 5px;
                margin-right: 5px;
            }
            .mobile_code {
                font-size: 15px;
                line-height: 25px;
                color: rgb(64, 77, 96);
                font-weight: 500;
                padding: 3px;
            }
        }
        .country_drop {
            max-height: 187px;
            overflow-y: auto;
            width: 100%;
            li {
                padding: 5px 15px;
            }
            a {
                padding: 0px;
                &:active {
                    background-color: rgba(0, 0, 0, 0.05);
                    color: #4f5d73;
                }
            }
            img {
                height: 16px;
                margin-bottom: 5px;
                padding-right: 10px;
            }
            .mobile_code {
                font-size: 15px;
                line-height: 25px;
                color: rgb(64, 77, 96);
                font-weight: 500;
                padding: 3px;
            }
            .count_name {
                padding-right: 15px;
            }
        }
        .Mob_pp1 {
            border: 0.5px solid #0466c8;
            border-radius: 11px;
            margin-top: 10px;
            .mobile_input {
                border: none;
                outline: none;
                margin-right: 5px;
                &:focus {
                    box-shadow: none;
                }
            }
        }
    }

    .submit_btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 110px;
        height: 38px;
        color: #fff;
        background: #0466c8;
        border-radius: 5px;
        font-size: 18px;
        font-weight: 500;
        outline: none;
        border: none;
        margin: 0px auto;
        &:disabled {
            background: #3f93e7;
        }
    }
    .partner_right {
        width: 50%;
        color: aliceblue;
        padding: 15px;
        @include max-width(991) {
            width: 100%;
        }
        img{
            height: 390px;
            width: 100%;
            object-fit: contain;
        }
    }
    //   .partner_right {
    //     background-color: #004767;
    //     width: 50%;
    //     color: aliceblue;
    //     padding: 15px;
    //     @include max-width(991) {
    //       width: 100%;
    //     }
    //     .pr_title {
    //       font-weight: 500;
    //       font-size: 24px;
    //       line-height: 45px;
    //       text-transform: capitalize;
    //       color: #fff;
    //       text-align: center;
    //     }
    //     .pr_slide {
    //       font-size: 16px !important;
    //       height: auto;
    //       box-shadow: 0 4px 5px rgb(210 240 251);
    //       transition: all 0.5s;
    //       background-color: rgb(255, 255, 255);
    //       text-align: left;
    //       border-radius: 3px;
    //       padding: 25px;
    //       margin-bottom: 15px;
    //       color: rgb(64, 77, 96);
    //       .pr1_title {
    //         position: absolute;
    //         bottom: 10px;
    //       }
    //     }
    //   }
}
p.pr1_title {
    position: relative;
    &::after {
        content: "AAA";
        display: block;
        position: absolute;
        top: -42px;
        // height: 15px;
        // width: 15px;

        border-right-color: #fff;
        border-width: 18px;
        border-style: solid;
        border-top-width: 2px;
        border-top-color: rgba(0, 0, 0, 0);
        border-bottom-color: rgba(0, 0, 0, 0);
        border-left-color: rgba(0, 0, 0, 0);
    }
}

.pr_right_sliderr {
    height: 100%;
}
.pr_test_sliderr {
    height: 90%;
    padding-bottom: 50px;
}

.send_otp2 {
    margin-top: 10px;
    gap: 10px;
    input {
        border: 0.5px solid #0466c8;
        border-radius: 11px;
        width: 100%;
        height: 38px;
        &:focus-visible {
            outline: #0466c8;
        }
    }
    .myBtnn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 110px;
        height: 38px;
        color: #fff;
        background: #0466c8;
        border-radius: 5px;
        font-size: 18px;
        font-weight: 500;
    }
    .verify_otp {
        gap: 10px;
        margin-top: 10px;
        display: flex;
        margin-bottom: 15px;
    }
}
