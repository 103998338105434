// ----Media query mixin-----------//
@mixin min-mq($min-screen-size) {
  @media (min-width: #{$min-screen-size +"px"}) {
    @content;
  }
}

@mixin max-mq($max-screen-size) {
  @media (max-width: #{$max-screen-size +"px"}) {
    @content;
  }
}

// ----------------------------For Orientation-----------------------//
@mixin landscape-orientation($max-screen-size, $orientation) {
  @media (max-width: #{$max-screen-size +"px"}) and (orientation: $orientation) {
    @content;
  }
}

@mixin btw-mq($min-screen-size, $max-screen-size, $pixel-ratio, $orientation) {
  @media only screen and (min-width: #{$min-screen-size +"px"}) and (max-width: #{$max-screen-size +"px"}) and (-webkit-min-device-pixel-ratio: $pixel-ratio) and (orientation: $orientation) {
    @content;
  }
}
@mixin btw-sz($min-screen-size, $max-screen-size) {
  @media only screen and (min-width: #{$min-screen-size +"px"}) and (max-width: #{$max-screen-size +"px"}) {
    @content;
  }
}

.userEditProfile-right-box {
  .title {
    display: flex;
    justify-content: space-between;
    @include max-mq(470) {
      display: block;
    }
    @include max-mq(768) {
      padding-bottom: 0px;
      padding-top: 20px;
    }
    .first {
      .r-b-title {
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
        text-transform: capitalize;
        color: #002733;
        @include max-mq(768) {
          font-size: 20px;
          line-height: 20px;
        }
        .ProfileBack {
          display: inline;
          align-items: center;
          margin-top: 20px;
          color: #002733;
          font-weight: 700;
          text-decoration: underline;
          cursor: pointer;
          img {
            height: 13px;
            margin-right: 10px;
          }
        }
      }
      .hr1 {
        width: 46px;
        height: 0px;
        opacity: 1;
        margin: 5px;
        margin-left: 30px;
        border-radius: 5px;
        border: 2px solid #0466c8;
      }
    }
    .search {
      width: 300px;
      height: 40px;
      background: #ffffff;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 12px;
      font-size: 24px;
      line-height: 30px;
      color: rgba(3, 7, 30, 0.5);
      display: flex;
      justify-content: flex-start;
      align-items: center;

      @include max-mq(768) {
        width: 200px;
      }

      @include max-mq(470) {
        margin-top: 20px;
        width: 100%;
      }
      .icon {
        margin: 10px;
        width: 25px !important;
      }
      input {
        border: none;
      }
      ::placeholder {
        font-size: 20px;
        line-height: 30px;
        color: rgba(3, 7, 30, 0.5);
      }
      input[type="text"] {
        border: none;
        outline: none;
        width: 100%;
        margin-right: 5px;
        font-size: 24px;
        line-height: 30px;
        color: rgba(3, 7, 30, 0.5);
        @include max-mq(768) {
          font-size: 18px;
        }
      }
    }
  }

  .profile-form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    margin-top: 15px;
    @include max-mq(768) {
      display: block;
      width: 100%;
      // grid-template-columns: repeat(1, 1fr);
    }
    .img-thumbnail {
      max-height: 130px;
      margin-top: 10px;
    }
    .dd1 {
      .react-tel-input .form-control {
        height: 38px !important;
        margin: 10px 0 !important;
      }
      .dd1_label {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        text-transform: capitalize;
        color: #6c757d;
      }
      label {
        @include max-mq(550) {
          font-size: 14px;
        }
      }
      .attach_label {
        margin-top: 10px;
      }
      .testt_fileUpload {
        position: relative;
        span {
          position: absolute;
          display: flex;
          align-items: center;
          height: 100%;
          margin-left: 10px;
          font-size: 1rem;
          font-weight: 400;
          color: rgba(44, 56, 74, 0.95);
        }
        input[type="file"]::file-selector-button {
          opacity: 0;
          height: 38px;
          width: 0px;
          margin: 0px;
          padding: 0px;
        }
      }
      .upload_notes {
        margin-bottom: 10px;
      }
      .select_company {
        width: 100%;
        border: 0.5px solid #0466c8;
        border-radius: 11px;
        margin: 10px 0px;
        height: 38px;
        padding-left: 10px;
        &:focus {
          box-shadow: none;
        }
        @include max-mq(550) {
          margin: 5px 0px;
        }
      }
      input.select_company {
        font-size: 14px;
        &:focus-visible {
          outline: #0466c8;
        }
      }
      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
    .imageView {
      position: relative;
      label {
        display: block;
        margin-bottom: 10px;
      }
      img {
        object-fit: cover;
        height: 250px;
        width: 100%;
        border-radius: 8px;
        margin-bottom: 10px;
        @include max-mq(550) {
          height: 200px;
        }
      }
      input {
        height: 250px;
        width: 100%;
        position: absolute;
        border: 1px solid red;
        left: 0;
        opacity: 0;
      }
    }
  }

  .submitt {
    width: 115px;
    margin: 20px auto;
    height: 38px;
    background: #0466c8;
    border-radius: 6px;
    @include max-mq(550) {
      margin: 15px auto;
    }
    button {
      color: #fff;
      outline: none;
      border: none;
      background: transparent;
      width: 100%;
      height: 100%;
    }
  }
}

.card_upload {
  grid-column-start: 1;
  grid-column-end: 3;
  padding: 15px;
  background-color: white;
  margin: 15px 0;
  @include max-mq(768) {
    padding: 10px;
  }
}
.grid_file_upload {
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  gap: 15px;
  @include max-mq(768) {
    gap: 5px;
    display: block;
  }
  input {
    @include max-mq(768) {
      font-size: 14px;
    }
  }
  input[type="file"]::file-selector-button {
    @include max-mq(768) {
      padding: 2px 5px;
      margin: 0px 5px;
      font-size: 10px;
    }
  }
  .label_txt {
    width: 100%;
    margin: 15px;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    @include max-mq(768) {
      margin: 5px;
    }
    @include max-mq(550) {
      font-size: 13px;
    }
  }
  .file_select-button {
    width: 100%;
    max-width: 215px;
    height: 45px;
    background: #0466c8;
    color: white;
    font-size: 16px;
    font-weight: 500;
    outline: none;
    border: none;
    border-radius: 11px;
  }
  .msg {
    font-size: 12px;
  }
  .msg.important {
    color: #0466c8;
  }
  .msg.danger {
    color: red;
  }
  .msg.warning {
    color: #dc3545;
  }
  .msg.success {
    color: #28a745;
  }
}
.doc_label {
  font-size: 20px;
  font-weight: 600;
}
// .profile_file {
//   opacity: 0;
//   display: none;
// }
