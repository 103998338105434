@mixin min-width($min-width) {
  @media only screen and (min-width: #{$min-width + "px"}) {
    @content;
  }
}
@mixin max-width($max-width) {
  @media only screen and (max-width: #{$max-width + "px"}) {
    @content;
  }
}
@mixin btw-sz($min-width, $max-width) {
  @media only screen and (min-width: #{$min-width + "px"}) and (max-width: #{$max-width + "px"}) {
    @content;
  }
}

.myspacing{
  @include max-width(750){
    padding: 20px 0px !important;
  }
}
// .bred-crumb {
//   height: 150px;
//   background: #002733;
//   padding: 10px 0;
// }
// .bread-title {
//   margin-bottom: 10px;
//   font-family: Source Sans Pro;
//   font-style: normal;
//   font-weight: 600;
//   font-size: 36px;
//   line-height: 45px;
//   text-transform: capitalize;
//   color: #ffffff;
//   position: relative;
//   padding: 15px 0;
//   &::before {
//       content: "";
//       width: 50px;
//       bottom: 0;
//       left: 0;
//       height: 5px;
//       background-color: white;
//       position: absolute;
//   }
// }
// .bread-content {
//   .breads {
//       color: white;
//   }
// }

.glossary-banner {
  position: relative;
  width: 100%;
  height: 100%;

  .glossary-banner-img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  .glossary-banner-text {
    height: 100%;
    text-transform: uppercase;
    color: aliceblue;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px;
    text-align: center;
    position: absolute;
    top: 0px;
  }
  .glossary-search {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-transform: uppercase;
    color: aliceblue;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid rgba(44, 45, 50, 0.2);
    img {
      margin: 10px;
      height: 15px;
      cursor: pointer;
    }
    input {
      border: none;
    }
    ::placeholder {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #99a6b9;
    }
    input[type="text"] {
      border: none;
      outline: none;
      width: 100%;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #99a6b9;
      background: transparent;
      padding: 10px;
      padding-right: 0px;
      border-radius: 3px;
    }
  }
}

// ----------------     Accodian     ---------------------------

.my-section {
  padding: 100px 135px !important;
}

.faq-acc {
  .accordion-item {
    border: none;
  }

  .container {
    max-width: -webkit-fill-available;
  }

  .accordion-button {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.02em;
    color: #2c2d32;
    box-shadow: none;
    border: none;
    padding: 22px 0px !important;
    border-bottom: 2px solid rgba(44, 45, 50, 0.2);
  }
  .accordion-body {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #777777;
    padding-top: 22px;
  }
  .accordion-button:not(.collapsed) {
    border-bottom: 2px solid rgba(44, 45, 50, 0.2);
    color: #002733;
    background-color: #fff;
  }

  .accordion-button:focus {
    z-index: 3;
    border-color: none;
    outline: 0;
    box-shadow: none;
  }

  .accordion-button:not(.collapsed)::after {
    background-image: url("../../../../assets/svg/minus.svg");
    transform: none;
    position: relative;
    top: 8px;
  }

  .accordion-button::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url("../../../../assets/svg/plus.svg");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform 0.2s ease-in-out;
    @include max-width(549) {
      background-size: 15px;
    }
  }

  button.accordion-button.collapsed {
    border-color: rgba(44, 45, 50, 0.2) !important;
  }
}

// -------------------------------------------------------

@media screen and (max-width: 900px) {
  .my-section {
    padding: 30px 15px !important;
  }
  .faq-acc {
    .accordion-button {
      font-size: 14px !important;
      line-height: 22px;
      padding-bottom: 10px !important;
    }
    .accordion-body {
      font-size: 14px;
      line-height: 22px;
      padding-top: 10px;
    }
  }
}
