@mixin min-width($min-width) {
  @media only screen and (min-width: #{$min-width + "px"}) {
    @content;
  }
}
@mixin max-width($max-width) {
  @media only screen and (max-width: #{$max-width + "px"}) {
    @content;
  }
}
@mixin btw-sz($min-width, $max-width) {
  @media only screen and (min-width: #{$min-width + "px"}) and (max-width: #{$max-width + "px"}) {
    @content;
  }
}

.my_custom_container {
  // max-width: 1620px !important;
  // max-width: 1500px !important;
  max-width: 1500px !important;
  @include btw-sz(1550, 1649) {
    max-width: 1400px !important;
  }
  @include btw-sz(1450, 1549) {
    max-width: 1300px !important;
  }
  @include btw-sz(1350, 1449) {
    max-width: 1200px !important;
  }
  @include btw-sz(1200, 1299) {
    max-width: 1100px !important;
  }
  @include btw-sz(1300, 1549) {
    max-width: 1250px !important;
  }
  @include btw-sz(1000, 1199) {
    // max-width: 950px !important;
    max-width: 1020px !important;
  }
  @include btw-sz(750, 999) {
    padding: 0 60px !important;
  }
  @include btw-sz(550, 749) {
    padding: 0 30px !important;
  }
}
.spacing_container {
  padding: 30px 0;
  padding-top: 0;
}

.slider_container {
  max-width: 1850px !important;
  padding: 0px !important;
}

.main-row {
  background-color: #f4f5f7;
  @include max-width(1199) {
    margin: 0;
  }
}
.slider_img {
  height: 150px;
  width: 150px;
  object-fit: contain;
}
.slider-view-box {
  margin: 30px 0;
  position: relative;
  @include max-width(1199) {
    order: 1;
  }
  &:after {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    height: calc(100% - 30px);
    width: 50px;
    content: "";
    background: linear-gradient(to right, #f4f5f7 20%, rgba(0, 39, 51, 0) 80%);
    pointer-events: none;
    margin-top: 30px;
    // background: linear-gradient(
    //   to right,
    //   rgba(0, 39, 51, 1) 20%,
    //   rgba(0, 39, 51, 0) 80%
    // );
    // background: linear-gradient(to right, #f4f5f7 20%, #f4f5f7 80%);
    // pointer-events: none;
    @include max-width(1199) {
      padding-right: 15px;
      display: none;
    }
  }
}
.about-content-box {
  margin: 30px 0;
  .about-content {
    @include btw-sz(1200, 1400) {
      padding: 30px;
    }
  }
  @include max-width(549) {
    margin: 30px 0;
  }
}
.slider_box {
  padding-bottom: 30px;
  padding-top: 0px;
  .slider-row {
    height: 150px;
    margin: 20px 0;
    .slide-content-box {
      position: relative;
    }
    .label {
      padding: 2.5px 15px;
      top: 10px;
      right: 10px;
      position: absolute;
      // background: rgba(0, 128, 0, 0.1);
      border-radius: 19px;
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 15px;
      /* identical to box height */

      text-transform: capitalize;

      // color: #008000;
    }
    .border_box {
      padding: 10px;
      cursor: pointer;
    }
    .slide-content-box {
      height: 150px;
      width: 200px;
      // max-width: 200px !important;
      background: #ffffff;
      /* Secondary */

      border: 3px solid #e5e5e5;
      box-sizing: border-box;
      box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05);
      border-radius: 11px;
      padding: 15px;
      text-align: center;
      // margin-right: 30px;
      margin-right: auto;
      // @include btw-sz(1150, 1549) {
      //   height: 135px;
      //   width: 135px;
      // }
      img.slider_img {
        margin-bottom: 10px;

        max-width: 135px;
        max-height: 60px;
        object-fit: contain;
        margin-top: 20px;
        height: 60px !important;
        // @include btw-sz(1149, 1549) {
        //   max-width: 120px;
        //   margin-top: 20px;
        // }
      }
      .desc-content {
        .grid-content {
          // display: grid;
          // grid-template-columns: 50% 50%;
          // justify-content: space-around;
          // margin-top: 10px;
          // gap: 5px;
          display: flex;
          gap: 15px;
          margin-top: 5px;
        }
        .revert-grid {
          flex-direction: row-reverse;
        }
        .price,
        .sector {
          font-family: Source Sans Pro;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 23px;
          text-align: left;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          /* identical to box height */
          width: 100%;
          text-transform: capitalize;

          color: #b1b3c2;
          position: relative;
          &::before {
            content: "";
            height: 1px;
            width: 20px;
            background-color: #b1b3c2;
            position: absolute;
            left: 0;
            bottom: 0;
          }
        }
        .price-content,
        .sector-content {
          text-align: right;
          width: 100%;

          font-family: Source Sans Pro;
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 25px;
          text-transform: capitalize;
          color: #002733;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-right: 5px;
        }
        .ltr-ellipse {
          direction: ltr;
        }
      }
    }
  }
}
.our-platform {
  margin: 15px 0 !important ;
}
.th-view {
  .home-sticky-table {
    position: sticky;
    left: 0;
  }
}
table th {
  @include max-width(549) {
    font-size: 14px;
    min-width: 150px;
    // margin: 5px 0;
  }
  @include btw-sz(550, 1149) {
    font-size: 16px;
  }
}
tbody.table-body-bg .table-cell {
  @include max-width(549) {
    font-size: 12px;
  }
  @include btw-sz(550, 1199) {
    font-size: 14px;
  }
}
// .main-content {
// background-color: #002733;
// }
.perfo-title {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 45px;
  text-transform: capitalize;
  padding: 15px;
  color: #002733;
  margin-top: 72px;
  // margin-top: 150px;
  margin-bottom: 30px;
  text-align: center;
  position: relative;
  @include btw-sz(750, 1199) {
    margin-top: 50px;
  }
  @include max-width(549) {
    margin-top: 15px;
    // padding: 5px;
    font-size: 20px;
    padding: 0px 5px;
  }
  @include btw-sz(1150, 1549) {
    font-size: 30px;
  }
  @include btw-sz(550, 1149) {
    font-size: 26px;
    margin-top: 30px;
  }
  &::before {
    content: "";
    width: 80px;
    height: 3px;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    background-color: #0466c8;
    border-radius: 5px;
  }
}
.left-block {
  display: flex;
  justify-content: center;
  align-items: center;
  @include max-width(1199) {
    order: 2;
    padding-bottom: 30px;
  }
}
.left-content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 45px;
  height: 100%;
  position: relative;

  @include max-width(549) {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    gap: 15px;
    line-height: 30px;
    text-transform: capitalize;
    align-items: center;
    /* Base color white */
    color: #ffffff;
  }
  @include btw-sz(550, 1199) {
    align-items: center;
    gap: 20px;
  }
  @include btw-sz(1200, 1300) {
    padding: 15px;
  }
  @include btw-sz(1150, 1449) {
    margin-left: 35px;
    // max-width: 450px;
    // width: 80%;
    // font-size: 32px;
    // line-height: 52px;
  }
  @include btw-sz(1450, 1549) {
    max-width: 431px;
    margin-left: 45px;
    width: 80%;
  }
  @include min-width(1550) {
    // min-width: 520px;
    margin-left: 45px;
  }

  .main-title {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 78px;
    text-transform: capitalize;
    text-align: left;
    // color: #ffffff;
    color: #002733;
    max-width: 600px;
    @include btw-sz(550, 1199) {
      font-size: 32px;
      line-height: 46px;
      padding: 15px;
      text-align: center;
    }

    @include max-width(549) {
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
      text-transform: capitalize;
      margin: 20px;
      /* Base color white */

      // color: #ffffff;
    }
    @include btw-sz(1150, 1449) {
      font-size: 32px;
      line-height: 52px;
    }

    @include btw-sz(1450, 1549) {
      font-size: 32px;
      line-height: 52px;
      // margin-left: 45px;
    }

    .prime-txt {
      color: #0466c8;
      font-weight: 600;
    }
  }
  .buttons-content {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 22px;
    max-width: 600px;
    @include max-width(549) {
      flex-direction: column;
      font-size: 14px;
      width: 100%;
    }
    @include btw-sz(1150, 1549) {
      gap: 15px;
    }
    button {
      outline: none;
      border: none;
      padding: 15px 45px;
      @include max-width(549) {
        padding: 5px 30px;
        font-size: 14px;
      }
      @include btw-sz(1150, 1549) {
        // padding: 10px 35px;
        padding: 5px 35px;
      }
      @include btw-sz(550, 1149) {
        padding: 5px 35px;
      }
    }
    .btn-view {
      background: #f4f5f7;
      border-radius: 6px;
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      text-transform: capitalize;
      // color: #002733;
      border: 3px solid #002733;
      color: white;
      .buy {
        text-decoration: none;
        color: #002733;
      }
      // &:hover {
      //   border: none;
      //   background: #002733;
      //   .buy {
      //     text-decoration: none;
      //     color: #ffffff;
      //   }
      // }
      @include max-width(549) {
        padding: 8px 50px;
        font-size: 14px;
        width: 100%;
      }
      @include btw-sz(1150, 1549) {
        font-size: 18px;
      }
      @include btw-sz(550, 1149) {
        font-size: 16px;
      }
      @include btw-sz(1200, 1300) {
        padding: 5px 20px;
      }
    }
    button {
      &:hover {
        box-shadow: rgb(0 0 0 / 20%) 0px 12px 28px 0px,
          rgb(0 0 0 / 10%) 0px 2px 4px 0px,
          rgb(255 255 255 / 5%) 0px 0px 0px 1px inset;
      }
    }
    .btn-view-outline {
      border: 3px solid #002733;

      box-sizing: border-box;
      border-radius: 6px;
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      text-transform: capitalize;
      background: #002733;
      color: #ffffff;
      .know_link.sell {
        text-decoration: none;
        color: white;
      }
      // &:hover {
      //   background: #f4f5f7;
      //   border: 3px solid #002733;
      //   .know_link.sell {
      //     text-decoration: none;
      //     color: #002733;
      //   }
      // }
      @include max-width(549) {
        padding: 8px 50px;
        font-size: 14px;
        width: 100%;
      }
      @include btw-sz(1150, 1549) {
        font-size: 18px;
      }
      @include btw-sz(550, 1149) {
        font-size: 16px;
      }
    }
  }
}

// .know_link.sell {
//   text-decoration: none;
//   color: #ffffff;
//   &:hover {
//     color: #002733;
//   }
// }

.about-content {
  @include max-width(549) {
    padding: 15px !important;
  }
  .left_about_content {
    width: 90%;
    @include max-width(549) {
      width: 100%;
      margin-bottom: 45px;
    }
  }
  .title {
    position: relative;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 45px;
    text-transform: capitalize;
    color: #002733;
    padding: 15px 0;
    margin-top: 160px;
    @include btw-sz(550, 749) {
      margin-top: 50px;
      font-size: 22px;
    }
    @include max-width(549) {
      font-size: 20px;
      margin-top: 0px;
    }
    @include btw-sz(750, 1199) {
      margin-top: 84px;
    }
    @include btw-sz(1150, 1549) {
      font-size: 24px;
    }
    @include btw-sz(550, 1149) {
      margin-top: 35px;
      font-size: 22px;
    }

    // border-bottom: 5px solid #0466c8;
    &::before {
      position: absolute;
      content: "";
      height: 5px;
      width: 100px;
      background-color: #0466c8;
      bottom: 0;
      border-radius: 5px;
    }
  }
  .small-desc {
    margin: 20px 0;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    // text-transform: capitalize;

    color: #6c757d;
    @include btw-sz(1150, 1549) {
      font-size: 18px;
    }
    @include max-width(549) {
      font-size: 14px;
      line-height: 22px;
    }
    @include btw-sz(550, 1149) {
      font-size: 16px;
      line-height: 27px;
    }
  }
  .content {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 150%;
    /* or 36px */

    text-align: justify;
    // text-transform: capitalize;

    color: #adb5bd;
    @include btw-sz(1150, 1549) {
      font-size: 18px;
    }
    @include max-width(549) {
      font-size: 14px;
    }
    @include btw-sz(550, 1149) {
      font-size: 16px;
    }
  }
  .btn-know {
    background: #0466c8;
    border-radius: 8px;
    outline: none;
    border: none;
    padding: 7px;
    margin-top: 25px;
    color: white;
    height: 60px;
    font-size: 22px;
    height: 50px;
    width: 208px;

    @include max-width(549) {
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
      width: 125px;
    }
    @include btw-sz(550, 749) {
      padding: 8px;
      /* height: 54px; */
      width: 130px;
      height: 44px;
      line-height: 14px;
    }
    @include btw-sz(1150, 1549) {
      // padding: 10px;
      color: white;
      height: 42px;
      width: 134px;
      margin: 15px 0;
      font-size: 16px;
    }
    @include btw-sz(550, 1149) {
      padding: 8px;
      /* height: 54px; */
      width: 130px;
      height: 44px;
      line-height: 14px;
    }
    .know_link {
      text-decoration: none;
      color: white;
      @include max-width(549) {
        font-size: 14px;
      }
      @include btw-sz(550, 1199) {
        font-size: 16px;
      }
    }
  }
}
.cards-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  @include btw-sz(550, 749) {
    gap: 18px;
  }
  @include max-width(549) {
    flex-direction: column;
  }
  .col-card {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &:nth-child(1) {
      margin-top: 200px;
      @include max-width(549) {
        margin-top: 15px;
      }
    }
  }
  .cards-view {
    background: #ffffff;
    box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 20px;
    width: 100%;
    min-width: 276px;
    // width: 278px;
    height: 200px;
    &:hover {
      background: #0466c8;
      .card-icon {
        svg,
        path {
          fill: white;
          // stroke: white;
        }
      }
      .stroke_border {
        path {
          stroke: white;
        }
      }
      .card-number,
      .card-txt {
        color: white;
      }
    }
    @include btw-sz(550, 650) {
      min-width: 250px;
    }

    // .card-icon {
    // }
    .card-number {
      padding: 10px 0;
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: 600;
      font-size: 27px;
      line-height: 45px;
      text-transform: capitalize;

      color: #002733;
    }
    .card-txt {
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 10px;
      text-transform: capitalize;

      color: #495057;
    }
  }
}
.why-block-content {
  @include max-width(549) {
    padding: 15px;
  }
}

.why-content {
  .why-block-content {
    width: 100%;
    margin-left: 0px;
  }
  .why-block {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    text-transform: capitalize;
    // padding: 0px;
    margin-top: 150px;
    color: #0466c8;
    position: relative;
    padding: 15px;
    // margin-bottom: 30px;
    @include max-width(549) {
      margin-top: 70px;
      font-size: 20px;
    }
    @include btw-sz(550, 1199) {
      margin-top: 70px;
      font-size: 22px;
    }
    &::before {
      position: absolute;
      content: "";
      height: 5px;
      width: 100px;
      background-color: #0466c8;
      bottom: 0;
      border-radius: 5px;
    }
  }
  .wh-title {
    margin: 20px 0;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    // text-transform: capitalize;
    color: #6c757d;
    // @include btw-sz(1199, 1799) {
    //   font-size: 32px;
    // }
    // @include btw-sz(750, 1000) {
    //   font-size: 28px !important;
    //   line-height: 37px;
    // }
    @include max-width(549) {
      font-size: 16px;
      margin: 5px 0;
      line-height: 25px;
    }
    @include btw-sz(1150, 1549) {
      font-size: 18px;
    }
    @include btw-sz(550, 1149) {
      font-size: 16px !important;
      line-height: 24px;

      margin: 10px 0;
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: 600;
      text-transform: capitalize;
      color: #6c757d;
    }
  }
  .txt-content {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 400;
    // font-size: 24px;
    font-size: 22px;
    line-height: 150%;
    margin: 5px 0;
    /* or 36px */

    text-align: justify;
    text-transform: none;

    color: #adb5bd;
    @include btw-sz(1150, 1549) {
      font-size: 18px;
    }
    @include max-width(549) {
      font-size: 16px;
      margin: 5px 0;
    }
    @include btw-sz(550, 1149) {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
.grid-table-view {
  display: grid;
  grid-template-columns: 50px auto;
  margin: 0 15px;
  text-align: left;
  justify-content: start;
  align-items: center;
  gap: 15px;
  color: unset;
  text-decoration: none;
  &:hover {
    color: unset;
  }
  .table-img {
    width: 45px;
    height: 45px;
    object-fit: contain;
    border-radius: 50%;
  }
}
.why-cards {
  width: 100%;
  margin-left: 0px;
  .card-content {
    margin: 30px 0;
    height: 100%;

    @include max-width(549) {
      margin: 15px 15px;
    }
    .custom-why-top {
      margin-top: 7px;
    }
    .card-view {
      padding: 15px;
      box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.11);
      border-radius: 17px;
      height: 100%;
      // max-height: 410px;
      max-height: 360px;
      .card-icon {
        margin-bottom: 15px;
        svg {
          height: 85px;
          &:hover {
            fill: #fff;
            path {
              fill: white;
            }
          }
        }
      }
      .card-header-txt {
        margin-bottom: 20px;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 30px;
        text-align: justify;
        text-transform: capitalize;

        color: #002733;
        @include btw-sz(1150, 1549) {
          font-size: 18px;
        }
        @include btw-sz(550, 1149) {
          font-size: 16px;
          line-height: 22px;
        }
        @include max-width(549) {
          font-size: 14x;
        }
        // @include btw-sz(550, 1149) {
        //   font-size: 22px;
        // }
      }
      .card-txt {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 23px;
        text-align: justify;
        text-transform: capitalize;
        color: #8c959a;
        @include btw-sz(1150, 1549) {
          font-size: 14px;
        }
        @include max-width(549) {
          font-size: 14px;
        }
        @include min-width(1550) {
          font-size: 14px;
        }
        @include btw-sz(550, 1149) {
          font-size: 14px;
          line-height: 22px;
        }
      }
      .card-icon.svghover {
        svg {
          width: 65px;
        }
      }
      &:hover {
        background: #0466c8;
        .card-icon.svghover {
          svg {
            fill: #fff !important;
            path {
              fill: white !important;
            }
          }
        }
        .card-header-txt {
          color: #fff;
        }
        .card-txt {
          color: #fff;
        }
      }
    }
  }
}
.txt_date {
  font-size: 14px;
}
.table_custom_responsive {
  overflow: auto;
  ::-webkit-scrollbar {
    background: #0466c8 !important;
  }
}
.performance-table {
  border-radius: 22px 22px 0px 0px;
  overflow: hidden;
  .perf-head {
    padding: 30px 0 !important;
    background: #0466c8;
    color: white;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    // font-size: 24px;
    font-size: 20px;
    line-height: 30px;
    text-transform: capitalize;
    color: #ffffff !important;
    @include btw-sz(1150, 1549) {
      font-size: 17px;
    }
    th {
      color: white !important;
      padding: 20px 15px !important;
      min-width: 205px;

      @include max-width(549) {
        padding: 10px 15px !important;
        font-size: 14px;
      }
      @include btw-sz(550, 1199) {
        padding: 10px 15px !important;
        font-size: 16px;
      }
    }
  }
  .table-body-bg {
    background: #f8fbff;
  }
  .table-cell {
    padding: 15px 0 !important;
  }
}
.pre_ipo {
  padding-left: 45px;
  color: #002733;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 36px */
  text-transform: capitalize;

  color: #002733;

  @include btw-sz(1150, 1549) {
    font-size: 14px;
  }
  @include max-width(549) {
    padding-left: 15px;
    font-size: 12px;
  }
  @include btw-sz(550, 1149) {
    padding-left: 15px;
    font-size: 12px;
  }
}
.tstm-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  @include max-width(549) {
    gap: 10px;
  }
  .title {
    position: relative;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 45px;
    text-transform: capitalize;
    color: #002733;
    padding: 15px;
    @include max-width(549) {
      font-size: 20px;
      padding-bottom: 15px;
    }
    @include btw-sz(549, 1150) {
      font-size: 26px;
    }
    @include btw-sz(1150, 1549) {
      font-size: 32px;
    }
    &::before {
      position: absolute;
      content: "";
      height: 5px;
      width: 82px;
      // left: 70px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #0466c8;
      bottom: 0;
      border-radius: 5px;
    }
  }
}
.testimonial {
  // margin-bottom: 100px;
  margin-bottom: 41px;
  margin-top: 100px;
  width: 100%;
  margin-left: 0;
  @include btw-sz(550, 1149) {
    margin-top: 50px;
  }
  @include max-width(549) {
    margin-top: 50px;
    margin-bottom: 34px;
  }
}
.tstm-content-box {
  padding: 20px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  gap: 5px;
  position: relative;
  // height: 231px;
  // height: 360px;
  width: 400px;

  // background: #002733;
  .tstm-quotes {
    color: rgba(0, 0, 0, 0.75);
    font-family: Century;
    font-style: normal;
    font-weight: normal;
    font-size: 70px;
    line-height: 25px;
    // color: rgba(255, 255, 255, 0.31);
  }
  .tstm-comments {
    // color: #ffffff;
    font-style: italic;
    font-family: Open Sans;
    text-align: justify;
    letter-spacing: -0.02em;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.75);

    @include max-width(549) {
      font-size: 14px;
    }
    @include btw-sz(1150, 1549) {
      font-size: 16px;
    }
    @include btw-sz(550, 1149) {
      font-size: 14px;
    }
    // line-height: 33px;
  }
}
.center-tstm-box {
  cursor: grabbing;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 380px;
  border-radius: 10px;
  background: rgba(177, 179, 194, 0.35);
}
.my_custom_slide {
  .vis_custom {
    transform: scale(0.9);
    @include max-width(549) {
      width: 100%;
      margin-top: 15px;
    }
  }
}
.what_client {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;

  color: #6c757d;
  @include max-width(549) {
    font-size: 16px;
  }
  @include btw-sz(550, 1149) {
    font-size: 18px;
  }
  @include btw-sz(1150, 1549) {
    font-size: 20px;
  }
}
.profile-img {
  width: 45px;
  height: 45px;
  object-fit: cover;
  border-radius: 50%;
  // border: 2px solid #002733;
}
.profile-title {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  /* identical to box height */

  letter-spacing: 0.1em;

  color: #002733;
  @include btw-sz(1150, 1549) {
    font-size: 18px;
  }
  @include btw-sz(550, 1149) {
    font-size: 16px;
  }
  @include max-width(549) {
    font-size: 14px;
    // margin-bottom: 5px;
  }
}
.profile-desc {
  font-family: Open Sans;
  font-style: italic;
  font-weight: normal;
  font-size: 20px;
  line-height: 33px;
  margin-bottom: 10px;
  /* identical to box height */
  color: #b1b3c2;
  @include max-width(549) {
    margin-bottom: 5px;
    font-size: 14px;
  }
  @include btw-sz(1150, 1549) {
    font-size: 14px;
  }
}
.profile-icon {
  width: 28px;
  height: 50px;
  svg {
    path,
    circle,
    rect {
      fill: rgba(177, 179, 194, 0.35);
    }
  }
}
.profile-block {
  cursor: grabbing;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding-bottom: 30px;
  @include max-width(549) {
    gap: 5px;
  }
}
.shdow-gradient {
  margin-top: 20px;
  position: relative;
  &:before {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    z-index: 99;
    height: 100%;
    // width: 150px;
    width: 50%;
    content: "";
    background: linear-gradient(
      to right,
      rgb(255, 255, 255),
      rgba(0, 39, 51, 0) 80%
    );
    pointer-events: none;
    @include max-width(749) {
      left: -1px;
      width: 50px;
      display: none;
    }
    @include btw-sz(550, 1199) {
      left: -1px;
      width: 30%;
    }
  }
  &:after {
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    height: 100%;
    // width: 150px;
    width: 50%;
    content: "";
    background: linear-gradient(
      to left,
      rgb(255, 255, 255),
      rgba(0, 39, 51, 0) 80%
    );
    pointer-events: none;
    @include btw-sz(550, 1199) {
      right: -2px;
      width: 30%;
    }
    @include max-width(749) {
      right: -1px;
      width: 50px;
      display: none;
    }
  }
}
.card-labal-1 {
  background: rgba(0, 128, 0, 0.1);
  color: #008000;
}
.card-labal-2 {
  background: rgba(208, 0, 0, 0.1);
  color: #d00000;
}
.card-labal-3 {
  background: rgba(255, 189, 0, 0.1);
  color: #b78700;
}

.home-nav {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
  position: relative;
  // border: none;
  .nav-link.active {
    &::before {
      content: "";
      width: 100%;
      height: 3px;
      background-color: transparent !important;
      position: absolute;
      left: 0;
      bottom: 0;
      transition: width 0.2s ease-in-out;
    }
  }
}

// .home-nav .nav-link.active, .nav-tabs .nav-item.show .nav-link{
//   border: none;
// }
.my_flex_grow {
  color: #000 !important;
  background-color: #ffffff;
  cursor: pointer;
  a {
    color: #000;
    font-size: 20px;
    &:hover {
      color: black;
    }
  }
  @include max-width(549) {
    flex-grow: 1;
    text-align: center;
    a {
      font-size: 16px;
    }
  }
  @include btw-sz(550, 1199) {
    a {
      font-size: 18px;
    }
  }
  .active {
    background-color: #002733 !important;
    color: #ffffff !important;
  }
  // @include max-width(549) {
  // }
}
table.performance-table {
  display: unset;
  border-collapse: collapse;
  font-family: helvetica;
  caption-side: top;
  text-transform: capitalize;
  tr > :first-child {
    position: -webkit-sticky;
    position: sticky;
    background: inherit;
    left: 0;
    background: #f8fbff;
    // border-right: 1px solid #bcbcbc;
  }
}

th.home-sticky-table {
  position: -webkit-sticky;
  position: sticky;
  // top: 0;
  z-index: 2;
  background: #0466c8 !important;
}

.th-view,
tfoot th:first-child {
  left: 0;
  z-index: 3;
}

tbody {
  overflow: scroll;
  // height: 200px;
}

/* MAKE LEFT COLUMN FIXEZ */
