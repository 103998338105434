// .sidebar-content {
//   margin-left: 300px;
//   .content {
//     height: 100vh;
//     padding: 0 15px;
//     background: #f9f9f9;
//     border: 1px solid #ececec;
//     box-sizing: border-box;
//     border-radius: 38px 0px 0px 38px;
//   }
// }

.sidebar-content {
  width: calc(100% - 300px);
  margin-left: auto;
  .content {
    height: 100vh;
    // padding: 0 15px;
    background: #fff;
  }
}


@media only screen and (max-width: 1100px) and (min-width: 200px)  {
  .sidebar-content{
    width: calc(100% - 0px);
  }
}
