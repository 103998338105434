.cp_dashboard {
  //   display: flex;
  gap: 15px;
  position: relative;
  .cp_sidebar {
    width: 100%;
    max-width: 300px;
    background: #f7f7f7;
    height: 100vh;
    position: fixed;
  }
  .cp_content-view {
    margin-left: 300px;
    // padding: 15px;
  }
}

// *** main sidebar of cp ***
.main_sidebar {
  .sidebar_header {
    font-size: 34px;
    text-align: center;
    padding: 15px;
    font-weight: 700;
  }
  .sidebar_content {
    .sidebar_item {
      //   padding: 15px;
      font-size: 18px;
      font-weight: 600;
      text-decoration: none;
      color: #374253;
      &:hover {
        background: #e5e5e5;
      }
      .sidebar_link {
        width: 300px !important;
        padding: 15px;
        text-decoration: none;
        color: #374253;
      }
      .sidebar_link.active {
        border-left: 5px solid #374253;
      }
    }
  }
}



@media only screen and (max-width: 1100px) and (min-width: 200px)  {
  .cp_sidebar{
    max-width: 0px !important;
  }
  .cp_content-view{
    margin-left: 0px !important;
  }
}

