// ----Media query mixin-----------//
@mixin min-mq($min-screen-size) {
  @media (min-width: #{$min-screen-size +"px"}) {
    @content;
  }
}

@mixin max-mq($max-screen-size) {
  @media (max-width: #{$max-screen-size +"px"}) {
    @content;
  }
}

// ----------------------------For Orientation-----------------------//
@mixin landscape-orientation($max-screen-size, $orientation) {
  @media (max-width: #{$max-screen-size +"px"}) and (orientation: $orientation) {
    @content;
  }
}

@mixin btw-mq($min-screen-size, $max-screen-size, $pixel-ratio, $orientation) {
  @media only screen and (min-width: #{$min-screen-size +"px"}) and (max-width: #{$max-screen-size +"px"}) and (-webkit-min-device-pixel-ratio: $pixel-ratio) and (orientation: $orientation) {
    @content;
  }
}
@mixin btw-sz($min-screen-size, $max-screen-size) {
  @media only screen and (min-width: #{$min-screen-size +"px"}) and (max-width: #{$max-screen-size +"px"}) {
    @content;
  }
}

.qty_cart {
  background: #0466c8;
  color: white;
  font-weight: 500;
  border-radius: 100px;
  padding: 5px;
  min-width: 15px;
  height: 15px;
  font-size: 12px;
  position: absolute;
  right: -12px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -5px;
  @include max-mq(550) {
    top: 0px;
    right: -9px;
    font-size: 10px;
  }
  // border: none !important;
}

.dheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  height: 80px;
  .hmenu {
    display: flex;
    align-items: center;
    gap: 20px;
    border-left: 2px solid #bcc2d0;
    margin-left: -20px;
    @include max-mq(1100) {
      border: none;
      margin: 0px;
    }
    @include max-mq(550) {
      gap: 5px;
      width: 100%;
    }
    .dhLeft {
      width: 250px;
      height: 55px;
      background: transparent;
      border-radius: 15px;
      display: flex;
      align-items: center;
      .btn-group {
        width: 100%;
        .btn {
          @include max-mq(550) {
            padding: 5px;
          }
        }
      }
      @include max-mq(550) {
        width: 100%;
      }
      // @include max-mq(360) {
      //   width: 130px;
      // }
      img {
        margin: 10px;
        margin-left: 30px;
        height: 15px;
        cursor: pointer;
        @include max-mq(768) {
          margin-left: 0px;
        }
      }
      input {
        border: none;
        // &:focus {
        //   background: #e2effe !important;
        // }
      }
      ::placeholder {
        // font-size: 24px;
        // line-height: 30px;
        // text-transform: capitalize;
        // color: #adb5bd;

        font-weight: 400;
        font-size: 20px;
        line-height: 21px;
        color: #99a6b9;
        @include max-mq(550) {
          font-size: 12px;
        }
      }
      input[type="text"] {
        border: none;
        outline: none;
        width: 100%;
        margin-right: 5px;
        // font-size: 24px;
        // line-height: 30px;
        // color: rgba(3, 7, 30, 0.5);
        font-weight: 400;
        height: 30px;
        font-size: 20px !important;
        line-height: 25px;
        color: #99a6b9;
        background: transparent;
        @include max-mq(550) {
          margin-top: 5px;
          font-size: 12px !important;
        }
        &::placeholder {
          font-size: 18px;
        }
      }
    }
  }
  .dRight {
    display: flex;
    align-items: center;
    .counter {
      position: relative;
      span {
        position: absolute;
        right: 0px;
        top: -3px;
        background: #f64e60;
        border-radius: 50%;
        color: #fff;
        height: 18px;
        width: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        border: 1px solid #fff;
      }
      .dricon {
        margin: 0px 10px;
        height: 22px;
        cursor: pointer;
      }
    }
    .drProfile {
      border-radius: 50%;
      background: #fff;
      height: 52px;
      width: 52px;
      @include max-mq(1300) {
        height: 42px;
        width: 42px;
      }
      // margin: 0px 10px;
      margin: 0px 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2.5px solid #0466c8;
      img {
        height: 42px;
        width: 42px;
        @include max-mq(1300) {
          height: 35px;
          width: 35px;
        }
        object-fit: cover;
        border-radius: 50%;
      }
    }
    .decoration-2 {
      margin-right: 10px;
      @include max-mq(550) {
        margin-right: 5px;
      }
    }
    .drpname {
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      text-align: right;
      letter-spacing: 0.01em;
      color: #2d405a;
      position: relative;
      //   @include max-mq(1300) {
      //     font-size: 16px;
      //   }
      // margin-right: 10px;
      // margin: 10px;
      img {
        height: 23px;
        @include max-mq(550) {
          height: 15px;
        }
      }
    }
    // .mobNone{
    //     margin-left: 10px;
    // }
    .checkoutBtn {
      width: 90px;
      height: 30px;

      border: 1px solid #0466c8;
      box-sizing: border-box;
      border-radius: 5px;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      text-transform: capitalize;
      color: #0466c8;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      @include max-mq(1300) {
        font-size: 15px;
      }
      @include max-mq(768) {
        margin-right: 0px;
      }
    }
    .profile-dropdown {
      display: inline-block;
      position: relative;
      margin: auto;
      font-weight: bold;
      font-size: 1.3rem;
      border-radius: 3px;
      cursor: pointer;

      .prImgg {
        height: 8px;
      }

      & > label {
        position: relative;
        display: block;
        text-decoration: none;
        color: #333;
        box-sizing: border-box;
        float: right;
        border-radius: 0 3px 3px 0;
      }

      input[type="checkbox"] {
        display: none;
        &:checked {
          & ~ ul {
            display: block;
            animation: pulse 0.5s;
          }

          & ~ label {
            background: orange;

            // i {
            //     color: #f2f2f2;
            // }
          }
        }
      }

      ul {
        display: none;
        list-style: none;
        padding: 0;
        margin: 0;
        position: absolute;
        top: 100%;
        right: 0px;
        border-radius: 3px;
        background: #ffffff;
        box-shadow: 0px 8px 15px rgba(208, 208, 208, 0.25);

        li {
          display: flex;
          align-items: center;
          gap: 10px;
          padding: 7.5px 10px;
          text-decoration: none;
          color: darken(#f2f2f2, 50%);
          font-size: 1rem;
          width: 100%;

          img {
            height: 15px;
          }

          &:hover {
            background: darken(#f2f2f2, 5%);
          }
        }
      }
    }

    .arrow {
      cursor: pointer;
      height: 8px;
    }
  }
}

.offcanvas-start {
  width: 300px;
}

.mob-sidebar-view {
  width: 300px;
  height: 100vh;
  position: fixed;
  background-color: #fff;
  .logo {
    margin: 25px;
    img {
      width: 240px;
      height: 36px;
      object-fit: cover;
      cursor: pointer;
    }
  }
  .sidebar-nav-view {
    .sidebar-nav {
      display: flex;
      justify-content: center;
      margin: 0px auto;
      padding: 10px 0px;
      width: 80%;
    }
    .sidebar-item {
      padding: 8px 15px;
      color: #002733;
      font-size: 18px;
      line-height: 23px;
      font-weight: 400;
      text-transform: capitalize;
      text-decoration: none;

      img {
        margin-right: 15px;
      }
    }
    .active {
      background: rgba(4, 102, 200, 0.08);
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      color: #0466c8;
      padding-bottom: 12px;
      // svg path {
      //   stroke: #0466c8;
      // }
      // svg circle {
      //   stroke: #0466c8;
      // }
      svg path {
        fill: #0466c8;
      }
      .testsvg {
        path {
          stroke: #0466c8;
          fill: none;
        }
        circle {
          stroke: #0466c8;
        }
      }
      &:focus {
        border-left: 11px solid #0466c8;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
      &:active {
        border-left: 11px solid #0466c8;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
      &.active {
        border-left: 11px solid #0466c8;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
    }
  }

  .sidebar-social {
    position: fixed;
    bottom: 40px;
    left: 60px;
    display: flex;
    gap: 15px;
    img {
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 3000px) and (min-width: 1101px) {
  .menu1 {
    display: none;
  }
}

@media (max-width: 768px) {
  .container {
    max-width: 100%;
  }

  .mobNone {
    display: none !important;
  }

  // .hmenu {
  //     display: flex;
  //     justify-content: space-between;
  //     width: 100%;
  //     .dhLeft {
  //         width: auto !important;
  //         margin-right: -10px;
  //         // input[type=text]{
  //         //     display: none;
  //         // }
  //         // img{
  //         //     margin-right: 0px !important;
  //         // }
  //     }
  // }
}

.mypersonalDropDown {
  button {
    background-color: transparent;
    outline: none;
    width: 10px;
    border: none;
    &:focus {
      background-color: transparent;
      outline: none;
      width: 10px;
      border: none;
      box-shadow: none;
    }
    &:hover {
      background-color: transparent;
    }
  }
  button.btn.btn-secondary.dropdown-toggle {
    background-color: transparent;
    box-shadow: none;
    margin-top: 5px;
    width: min-content !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    @include max-mq(550) {
      padding-right: 4px;
    }
  }
  li {
    border-left: 0px;
    padding: 0px;
  }
  .dropdown-item:active {
    background-color: transparent;
  }
  // .dropdown-menu{

  // }
  .dropdown-toggle::after {
    border-top: 8px solid;
    border-right: 8px solid transparent;
    border-bottom: 0;
    border-left: 8px solid transparent;
    @include max-mq(1300) {
      border-top: 6px solid;
      border-right: 6px solid transparent;
      border-bottom: 0;
      border-left: 6px solid transparent;
    }
  }
}

.decoration {
  text-decoration: none;
  margin-right: 20px;
}

.dash_header_drop {
  min-width: 500px;
  @include max-mq(549) {
    min-width: 300px;
  }
  li {
    padding: 0px;
    margin-left: 0;
    border-left: 0;
    a {
      &:active {
        background: #e2effe;
        color: black;
      }
    }
  }
}

.dhLeft {
  button.btn.btn-transperant.dropdown-toggle {
    &::after {
      display: none;
    }
  }
  .dash_header_drop {
    max-height: 178px;
    border: none;
    box-shadow: rgb(0 0 0 / 20%) 0px 12px 28px 0px,
      rgb(0 0 0 / 10%) 0px 2px 4px 0px,
      rgb(255 255 255 / 5%) 0px 0px 0px 1px inset;
    overflow-y: auto;
    .dropdown-item {
      padding: 7px 15px;

      .drop_search_item {
        display: grid;
        grid-template-columns: 50px calc(100% - 70px);
        align-items: center;
        justify-content: flex-start;
        gap: 15px;
        .img_box {
          img {
            height: 40px;
            width: 50px;
            object-fit: contain;
            padding: 0;
            margin: 0 !important;
          }
        }
        .text_search {
          width: 100%;
          font-size: 16px;
          font-weight: 600;
          text-transform: capitalize;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.uniqueNumberrrr {
  font-size: 12px;
}
