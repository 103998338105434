@mixin min-width($min-width) {
  @media only screen and (min-width: #{$min-width + "px"}) {
    @content;
  }
}
@mixin max-width($max-width) {
  @media only screen and (max-width: #{$max-width + "px"}) {
    @content;
  }
}
@mixin btw-sz($min-width, $max-width) {
  @media only screen and (min-width: #{$min-width + "px"}) and (max-width: #{$max-width + "px"}) {
    @content;
  }
}

.one_side_view {
  @include min-width(1200) {
    margin-right: 70px;
  }
}

.pdf_link {
  border: none;
  outline: none;
  background: transparent;
}
.compony-details {
  .scrollspy {
    scroll-behavior: smooth !important;
  }
  .bred-crumb {
    height: 100px;
    background: #002733;
    padding: 10px 0;
  }
  .bread-title {
    margin-bottom: 10px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 27px;
    line-height: 45px;
    text-transform: capitalize;
    color: #ffffff;
    position: relative;
    padding: 0px;
    @include max-width(549) {
      font-size: 20px;
    }
    @include btw-sz(1150, 1549) {
      font-size: 27px;
    }
    &::before {
      content: "";
      width: 50px;
      bottom: 0;
      left: 0px;
      height: 5px;
      background-color: white;
      position: absolute;
      @include max-width(549) {
        height: 3px;
      }
    }
  }
  .bread-content {
    // padding: 0 15px;
    .breads {
      color: white;
    }
  }
  .top-content {
    padding: 30px 0 0;
    .short-det {
      display: flex;
      align-items: flex-start;
      gap: 15px;
      margin: 10px 0;
      // margin: 40px 0;
      @include max-width(549) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      .img-logo {
        background: #ffffff;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
        border-radius: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 216px;
        height: 216px;
        min-width: 216px;
        @include max-width(549) {
          height: 200px;
          width: 200px;
        }
        @include btw-sz(1150, 1549) {
          width: 190px;
          height: 190px;
          min-width: 190px;
        }
        img {
          height: auto;
          width: 100%;
          padding: 15px;
        }
      }
      .imfo {
        @include max-width(549) {
          width: 100%;
        }
        .label {
          z-index: 99;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0px 14px;
          border-radius: 19px;
          font-family: "Source Sans Pro";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 30px;
          text-transform: capitalize;
          width: min-content;
          white-space: nowrap;

          @include max-width(549) {
            font-size: 14px;
            padding: 4px 15px;
            line-height: 20px;
          }
          @include btw-sz(1150, 1549) {
            font-size: 14px;
          }
        }
        .label.available {
          color: #008000;
          background: rgba(0, 128, 0, 0.1);
        }
        .label.out-of-stock {
          color: #d00000;
          background: rgba(208, 0, 0, 0.1);
        }
        .label.coming {
          color: #b78700;
          background: rgba(255, 189, 0, 0.1);
        }
        .compony {
          margin: 15px 0;
          font-family: "Source Sans Pro";
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 45px;
          text-transform: capitalize;

          color: #002733;
          @include max-width(550) {
            font-size: 20px;
            margin: 7px 0;
            line-height: 24px;
          }
          @include btw-sz(550, 1149) {
            font-size: 22px;
          }
          @include btw-sz(1150, 1549) {
            font-size: 24px;
          }
        }
        .number {
          font-family: "Source Sans Pro";
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 30px;
          text-transform: capitalize;
          margin-top: 40px;
          margin-bottom: 0px;
          @include max-width(549) {
            font-size: 18px;
            margin: 7px 0;
          }
          @include btw-sz(1150, 1549) {
            font-size: 18px;
          }
        }
        .sector {
          font-family: "Source Sans Pro";
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 30px;
          text-transform: capitalize;
          @include max-width(549) {
            font-size: 18px;
            // margin: 7px 0;
          }
          @include btw-sz(1150, 1549) {
            font-size: 18px !important;
          }
        }
      }
    }
    .share-col {
      margin: 15px 0;
      display: grid;
      grid-template-columns: 200px auto;
      gap: 30px;
      @include max-width(549) {
        grid-template-columns: repeat(1, auto);
        gap: 15px;
        margin: 7px 0;
      }
      .grid-share {
        .lable-share {
          font-family: "Source Sans Pro";
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 30px;
          text-transform: capitalize;
          color: #b1b3c2;
          position: relative;
          padding: 15px 0;
          margin: 5px 0;
          @include max-width(549) {
            font-size: 16px;
            padding: 7px 0;
            margin: 0px;
          }
          @include btw-sz(1150, 1549) {
            font-size: 18px;
          }
          &::before {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            background-color: #0466c8;
            height: 3px;
            width: 80px;
          }
        }
        .price {
          @include max-width(549) {
            font-size: 18px;
            margin: 7px 0;
          }
          font-family: "Source Sans Pro";
          font-style: normal;
          font-weight: 600;
          font-size: 23px;
          line-height: 45px;
          text-transform: capitalize;

          color: #002733;
          @include btw-sz(1150, 1549) {
            font-size: 23px;
          }
        }
      }
    }
    .custom-left {
      @include max-width(549) {
        margin-bottom: 15px !important;
      }
    }
    .button-share-content {
      display: flex;
      gap: 30px;
      flex-wrap: wrap;
      align-items: center;
      @include max-width(549) {
        justify-content: center;
      }
      .btn-view {
        outline: none;
        border: none;
        padding: 5px 40px;
        font-family: "Source Sans Pro";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
        text-transform: capitalize;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        display: flex;
        @include max-width(549) {
          width: 100%;
        }
        @include max-width(1199) {
          padding: 5px 40px;
          font-size: 18px;
        }
        @include btw-sz(1150, 1549) {
          font-size: 18px;
          padding: 5px 40px;
        }
      }
      .btn-invest {
        background: #0466c8;
        color: white;
        white-space: nowrap;
        border: 3px solid #0466c8;
      }
      .btn-outlined-invest {
        border: 3px solid #0466c8;
        color: #0466c8;
        background: #ffffff;
        white-space: nowrap;
      }

      .btn-view-disable {
        opacity: 0.5;
      }
    }
  }
  .sticky-tabs {
    display: flex;
    gap: 15px;
  }
}
.model_input_box_view {
  // display: flex;
  // gap: 15px;
  .input_div {
    width: 100%;
    padding: 15px;

    .model_input {
      width: 100%;
      font-size: 18px;
      height: 45px;
      background: #f8fbff;
      border: 0.5px solid #0466c8;
      border-radius: 11px;
      line-height: 23px;
      text-transform: capitalize;
      color: rgba(3, 7, 30, 0.5);
      padding: 10px;
      @include max-width(549) {
        font-size: 14px;
        height: 30px;
      }
    }
  }
}
.modal_header_bg {
  background: #002733;
}
.modal_submit_btn {
  background: #002733 !important;
  outline: none;
  border: none;
  color: white;
  min-width: 170px;
}
.btn_line_chart {
  display: flex;
  justify-content: flex-end;
  @include max-width(549) {
    margin-top: 70px;
  }
}
.chart_btn_custom {
  background: white !important;
  border: 2px solid #b1b3c2;
  color: #b1b3c2 !important;
  padding: 5px 0;
  width: 80px !important;
  height: 30px !important;
  font-size: 14px !important;
  line-height: 15px;
  // padding: 5px 20px !important;
}
.chart_btn_custom.active {
  background: rgb(4, 102, 200) !important;
  border: none !important;
  color: white !important;
  width: 80px !important;
  height: 30px !important;
  font-size: 14px !important;
}

.scrollspy-view {
  flex-direction: column;
  display: flex;
  width: 100%;
  gap: 15px;
  position: relative;
  .side-bar-content {
    max-width: 300px;
    top: 0px;
    position: sticky;
    a {
      padding: 15px;
      text-decoration: none;
      display: flex;
      flex-direction: column;
    }
    @include max-width(1199) {
      display: none;
    }
  }
  .side-content {
    // height: 300px;
    overflow-y: auto;
  }
}
.App-content {
  font-family: sans-serif;
  display: flex;
  gap: 15px;
  margin-top: 30px;

  .appScrollContent {
    padding: 0 15px;
    width: 100%;
    flex-basis: 100%;
    padding-bottom: 15px;
    ul {
      margin-left: 40px;
    }
  }
}
.scrollspy {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  margin: 10px;
  position: sticky;
  top: 0;
  min-width: 350px;
  height: min-content;
  margin: 0;
  padding: 0;
  padding: 30px;
  a {
    color: black;
    text-decoration: none;
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    text-transform: capitalize;

    color: #002733;
    @include btw-sz(1150, 1549) {
      font-size: 18px;
    }
  }
  @include max-width(1199) {
    display: none;
  }
}
.content_scroll_pv {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  text-align: justify;
  text-transform: capitalize;
  overflow-x: hidden;
  color: #6c757d;

  ul {
    margin-left: 0;
    padding-left: 0;
  }

  .table {
    overflow-x: auto;
  }
  table {
    width: 100%;
    thead {
      background: #0466c8;
      th {
        color: white;
        padding: 7px 15px;
      }
    }
    thead + tbody {
      tr {
        &:nth-child(1) {
          background: transparent;
          color: black;
        }
      }
    }
    tbody {
      tr {
        &:nth-child(1) {
          background: #0466c8;
          color: white;
        }
      }
      td {
        padding: 7px 15px;
        // padding: 15px;
      }
    }
  }
}
.title-view {
  margin: 30px 0;
  padding: 15px;
  border-left: 5px solid #0466c8;
  border-radius: 12px;
  display: flex;
  justify-content: start;
  align-items: center;
  background: rgba(4, 102, 200, 0.08);
  h4 {
    margin-bottom: 0;
    /* H2 */

    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    text-transform: capitalize;

    color: #002733;
  }
}
// ul {
//   list-style: none;
//   li {
//     padding: 10px;
//     // padding: 10px 15px;
//     border-left: 10px solid transparent;
//   }
// }
ul.scrollspy {
  list-style: none;
  li.isCurrent {
    border-left: 10px solid #0466c8;
  }
  li {
    padding: 10px;
    // padding: 10px 15px;
    border-left: 10px solid transparent;
  }
}
.isCurrent {
  font-weight: bold;
  padding: 5px 15px;

  border-left: 10px solid #0466c8;
  border-radius: 12px;
  background: rgba(4, 102, 200, 0.08);
  a {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    text-transform: capitalize;

    color: #0466c8;
  }
}
.accordian {
  border: none;
}
.custom-acco-item {
  border: 0px !important;
  // box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}
// .custom-acco-body {
// background: #f8fbff;
// }
.custom-acco-header {
  &:focus {
    outline: none !important;
    border: none !important;
  }
  button {
    &::after {
      background-image: url("../../../assets/svg/plus.svg");
    }
    &:not(.collapsed) {
      background: rgba(4, 102, 200, 0.1) !important;
      border-radius: 10px !important;
      &::after {
        background-image: url("../../../assets/svg/minus.svg");
        top: 50%;
      }
    }
    text-decoration: none;
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    text-transform: capitalize;
    color: #002733 !important;
    margin: 5px 0;
    @include max-width(549) {
      font-size: 18px;
      line-height: 20px;
      padding: 15px !important;
    }
    &:focus {
      outline: none !important;
      border: none !important;
      box-shadow: none;
    }
  }
}
.main_title {
  padding: 10px 15px;
  border-radius: 12px;
  background: rgba(4, 102, 200, 0.08);
  margin-bottom: 15px;
  border-left: 5px solid #0466c8;
  font-size: 18px;
  @include btw-sz(1150, 1549) {
    font-size: 16px;
  }
  @include max-width(1199) {
    font-size: 14px;
  }
}
section {
  margin-bottom: 30px;
}
.pdf-view {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 30px;
  @include max-width(1199) {
    grid-template-columns: repeat(1, 100%);
    gap: 15px;
  }
  .pdf-content {
    padding: 15px;
    // background: rgba(4, 102, 200, 0.1);
    border-radius: 10px;
    margin: 15px 0;
    display: grid;
    grid-template-columns: 30px auto;
    gap: 15px;
    // justify-content: center;
    align-items: center;
    @include max-width(549) {
      margin: 0;
    }
    .icon-view {
      img {
        height: 30px;
      }
    }
  }
  .name {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    /* identical to box height */
    text-transform: capitalize;

    color: #0466c8 !important;
    @include max-width(549) {
      font-size: 16px;
      line-height: 20px;
      // margin: 7px 0;
    }
    a {
      text-decoration: none;
      text-transform: capitalize;
    }
  }
}
.explor_row {
  background: #f4f5f7;
  // background: #f8fbff;
  @include min-width(1200) {
    margin-top: 60px !important;
  }
}
.explore-other-stock {
  .title {
    position: relative;
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 45px;
    text-transform: capitalize;

    color: #191b28;
    padding: 15px 0;
    padding-top: 30px !important;
    @include max-width(549) {
      font-size: 18px;
    }
    @include btw-sz(550, 1199) {
      font-size: 23px;
    }
    @include btw-sz(1150, 1549) {
      font-size: 23px;
    }
    &::before {
      position: absolute;
      content: "";
      width: 70px;
      height: 5px;
      left: 0;
      bottom: 0;
      background: #0466c8;
    }
  }
  @include min-width(1200) {
    // margin: 60px;
  }
  .other-stocks {
    margin: 60px 0;
    margin-top: 15px;
    display: grid;
    // grid-template-columns: repeat(5, auto);
    // grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    // grid-template-columns: auto auto auto auto auto;
    grid-template-columns: repeat(5, 1fr);
    gap: 30px;
    @include max-width(550) {
      grid-template-columns: repeat(1, minmax(150px, 1fr));
    }
    @include btw-sz(550, 750) {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      gap: 20px;
    }
    @include btw-sz(750, 1050) {
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
    }
    @include btw-sz(1050, 1300) {
      grid-template-columns: repeat(4, 1fr);
      gap: 30px;
    }
    .other-stock-item {
      // @include btw-sz(550, 749) {
      //   &:nth-last-child(3) {
      //     display: none;
      //   }
      // }\

      @include btw-sz(724, 1049) {
        &:nth-last-child(2) {
          display: none;
        }
      }
      @include btw-sz(1050, 1300) {
        &:nth-last-child(2) {
          display: none;
        }
        &:nth-last-child(3) {
          display: none;
        }
      }

      padding: 15px;
      background: #ffffff;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
      border-radius: 11px;
      position: relative;
      min-width: 200px;
      min-height: 200px;
      .label {
        z-index: 99;
        font-family: "Source Sans Pro";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        border-radius: 19px;
        /* identical to box height */
        width: 90px;
        height: 20px;
        text-transform: capitalize;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 15px;
        top: 15px;
      }
      .label.available {
        color: #008000;
        background: rgba(0, 128, 0, 0.1);
      }
      .label.out-of-stock {
        color: #d00000;
        background: rgba(208, 0, 0, 0.1);
      }
      .label.coming {
        color: #b78700;
        background: rgba(255, 189, 0, 0.1);
      }
      .label-more {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        /* identical to box height */

        text-align: justify;

        color: #0466c8;
      }
      .img-content {
        margin-top: 50px !important;
        width: 100%;
        // height: 50px;
        margin: 15px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 100%;
          object-fit: contain;
          height: 45px;
        }
      }
      .content {
        .price-content {
          display: grid;
          grid-template-columns: 50px auto;
          // justify-content: center;
          // align-items: center;
          gap: 15px;
          .txt {
            font-family: "Source Sans Pro";
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 23px;
            /* identical to box height */

            text-transform: capitalize;
            position: relative;
            color: #b1b3c2;
            padding: 7px 0;
            // width: 100%;
            width: 50px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            @include max-width(549) {
              font-size: 14px;
            }
            @include btw-sz(550, 1149) {
              font-size: 16px;
            }
            &::before {
              position: absolute;
              content: "";
              width: 14px;
              height: 2px;
              background: #b1b3c2;
              bottom: 0;
              left: 0;
            }
          }
          .number {
            width: 100%;
            font-family: "Source Sans Pro";
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 25px;
            text-transform: capitalize;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #002733;
            padding: 5px 0;
            @include max-width(549) {
              font-size: 16px;
            }
            @include btw-sz(550, 1149) {
              font-size: 16px;
            }
          }
          .number.price {
            font-size: 20px;
            @include max-width(549) {
              font-size: 16px;
            }
            @include btw-sz(550, 1149) {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}
.icon-list-single {
  margin: 30px 0 80px;
  float: right;
  display: flex;
  gap: 5px;
  @include max-width(549) {
    margin: 30px 0 0px;
  }
  .wishlist {
    @include max-width(549) {
      display: none;
    }
  }
  .icon {
    width: 30px !important;
    height: 30px !important;
  }
  .share {
    padding: 0 15px;
    position: relative;
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-align: justify;
    text-transform: capitalize;

    color: #002733;
    &::before {
      content: "";
      position: absolute;
      width: 5px;
      height: 100%;
      left: 0;
      background-color: #0466c8;
    }
  }
}
.wishlist.outside {
  margin-right: 15px;
  margin-top: 30px;
  float: right;
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  @include min-width(550) {
    display: none;
  }
}
.wishlist {
  float: left;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 8px 10px;
  border: none;
  outline: none;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */
  white-space: nowrap;
  text-align: justify;
  text-transform: capitalize;
  background: #0466c8;
  border-radius: 6px;
  /* Base color white */

  color: #ffffff;
}
.my_custom_row {
  width: 100%;
  margin: 0;
}
.buy_indian {
  text-decoration: none;
}
.many_more {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 30px;
}
.chart_btn_custom {
  &:hover {
    background: white !important;
    border: 2px solid #b1b3c2;
    color: #b1b3c2 !important;
  }
}
.chart_btn_custom.active {
  &:hover {
    background-color: rgb(4, 102, 200) !important;
    color: white !important;
  }
}
.accordian-button {
  padding: 5px 10px;
}
.accordian-body {
  padding: 5px 10px;
}
