// ----Media query mixin-----------//
@mixin min-mq($min-screen-size) {
  @media (min-width: #{$min-screen-size +"px"}) {
    @content;
  }
}

@mixin max-mq($max-screen-size) {
  @media (max-width: #{$max-screen-size +"px"}) {
    @content;
  }
}

// ----------------------------For Orientation-----------------------//
@mixin landscape-orientation($max-screen-size, $orientation) {
  @media (max-width: #{$max-screen-size +"px"}) and (orientation: $orientation) {
    @content;
  }
}

@mixin btw-mq($min-screen-size, $max-screen-size, $pixel-ratio, $orientation) {
  @media only screen and (min-width: #{$min-screen-size +"px"}) and (max-width: #{$max-screen-size +"px"}) and (-webkit-min-device-pixel-ratio: $pixel-ratio) and (orientation: $orientation) {
    @content;
  }
}
@mixin btw-sz($min-screen-size, $max-screen-size) {
  @media only screen and (min-width: #{$min-screen-size +"px"}) and (max-width: #{$max-screen-size +"px"}) {
    @content;
  }
}

.support-right-box {
  padding: 30px;
  background: #ffffff;
  box-shadow: 0px 8px 15px rgb(208 208 208 / 25%);
  border-radius: 20px;
  @include max-mq(600){
    padding: 15px;
  }

  .title {
    display: flex;
    justify-content: space-between;
    @include max-mq(470) {
      display: block;
    }
    @include max-mq(768) {
      padding-bottom: 0px;
    }
    .first {
      .r-b-title {
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
        text-transform: capitalize;
        color: #002733;
        @include max-mq(768) {
          font-size: 20px;
        line-height: 20px;
        }
        // @include max-mq(600) {
        //   font-size: 22px;
        //   line-height: 26px;
        // }
      }
      .hr1 {
        width: 46px;
        height: 0px;
        opacity: 1;
        margin: 5px;
        margin-left: 0px;
        border-radius: 5px;
        border: 2px solid #0466c8;
      }
    }
    .search {
      width: 300px;
      height: 40px;
      background: #ffffff;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 12px;
      font-size: 24px;
      line-height: 30px;
      color: rgba(3, 7, 30, 0.5);
      display: flex;
      justify-content: flex-start;
      align-items: center;

      @include max-mq(768) {
        width: 200px;
      }

      @include max-mq(470) {
        margin-top: 20px;
        width: 100%;
      }
      .icon {
        margin: 10px;
        width: 25px !important;
      }
      input {
        border: none;
      }
      ::placeholder {
        font-size: 20px;
        line-height: 30px;
        color: rgba(3, 7, 30, 0.5);
      }
      input[type="text"] {
        border: none;
        outline: none;
        width: 100%;
        margin-right: 5px;
        font-size: 24px;
        line-height: 30px;
        color: rgba(3, 7, 30, 0.5);
        @include max-mq(768) {
          font-size: 18px;
        }
      }
    }
  }
  .support-data {
    display: grid;
    margin-top: 15px;
    input {
      width: 100%;
      height: 45px;
      background: #f8fbff;
      border: 1px solid #bcbcbc52;
      // border: 0.5px solid #0466c8;
      // box-shadow: 0px 8px 15px rgba(208, 208, 208, 0.25);
      border-radius: 11px;
      font-size: 16px;
      line-height: 23px;
      text-transform: capitalize;
      color: rgba(3, 7, 30, 0.5);
      padding: 10px;
      &:focus {
        outline: none;
        // outline: 0.5px solid #0466c8;
      }
      @include max-mq(768) {
        width: 100%;
      }
    }
    textarea {
      margin-top: 15px;
      width: 100%;
      background: #f8fbff;
      border: 1px solid #bcbcbc52;
      // border: 0.5px solid #0466c8;
      // box-shadow: 0px 8px 15px rgba(208, 208, 208, 0.25);
      border-radius: 11px;
      font-size: 16px;
      line-height: 23px;
      text-transform: capitalize;
      color: rgba(3, 7, 30, 0.5);
      padding: 10px;
      &:focus {
        outline: none;
        // outline: 0.5px solid #0466c8;
      }
      @include max-mq(768) {
        width: 100%;
      }
    }
  }
  .saveBtn {
    width: 50%;
    margin-top: 15px;
    @include max-mq(768) {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    button {
      font-weight: 600;
      line-height: 30px;
      text-transform: capitalize;
      color: #ffffff;
      background: #0466c8;
      border-radius: 6px;
      border: 1px solid #0466c8;
      font-size: 18px;
      padding: 5px 40px;
    }
  }
}
